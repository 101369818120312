import React from 'react'
import ReactLoader from 'react-loader-spinner';

class Loader extends React.Component{
 render(){
  return(
    <React.Fragment>
     <div style={{position:'relative',top:'40%',left:'45%',display:'inline-block'}}> 
        <ReactLoader 
        type="ThreeDots"
        color="#1a1a1a"
        height={80}
        width={80}
        />
     </div> 
    </React.Fragment>
  )
 }
}

export default Loader