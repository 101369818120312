import axios from 'axios';
import React from 'react';
import TronWeb from "tronweb";
import {TronConfig,UrlString} from '../../config/apiRoutes';
import Utils from '../../config/util'
import Loader from '../SharedModule/Loader';
import Web3 from 'web3';
import {TrxResponseMessage} from '../../config/TrxMessage';
import Notify from '../SharedModule/Notify'

class PriorityRequest extends React.Component{
  constructor(props){
   super(props)
   this.state={
     formData:{
      name:"",
      category:"",
      blockchain:"Tron",
      // headline:"",
      //short_description:"",
      website_link:"",
      telegram_url:"",
      contract_id :"",
      email:"",
      logo:"",
      verify_register:"",
      termsandCondi:false,
     },
     contract_key:"",
     uniqueValid:'',
     trxAmountModel:{
      visible:false,
      style:{
        display:"none"
      }
     },
     notify:{},
     requestAuditModel:{
      visible:false,
      style:{
        display:"none"
      }
     },
     errors:{},
     balance:0,
     userAddress:'',
     msg:'',
     success:false,
     loading:false,
     amount_unit256:10,
     trxamount:10,
     requestBtn:false,
     approveBtn:false,
     submitBtn:false,
     sumA:Math.floor(Math.random() * 10),
     sumB:Math.floor(Math.random() * 10)
    }
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleTrxamount = this.handleTrxamount.bind(this);
  }
  
  async componentDidMount() {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("balance");
    await new Promise(resolve => {
      const tronWebState = {
        installed: !!window.tronWeb,
        loggedIn: window.tronWeb && window.tronWeb.ready
      };
      if (tronWebState.installed) {
        this.setState({
          tronWeb: tronWebState
        });

        return resolve();
      }

      let tries = 0;

      const timer = setInterval(() => {
        if (2e4 <= tries) {
          window.tronWeb = new TronWeb(
            TronConfig.fullHost,
            TronConfig.privateKey,
            TronConfig.fee_limit,
            TronConfig.callValue
          );
          this.setState({
            tronWeb: tronWebState
          });
          clearInterval(timer);
          return resolve();
        }
        

        tronWebState.installed = !!window.tronWeb;
        tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
        
        if (!tronWebState.installed) {
          return tries++;
        }
       
        this.setState({
          tronWeb: tronWebState
        },()=>{
           localStorage.setItem('loggedIn',tronWebState.loggedIn);
        });

        return resolve();
      },100);
    });
     
       // Set default address (foundation address) used for contract calls
       // Directly overwrites the address object if TronLink disabled the
       // function call
       if(!this.state.tronWeb.loggedIn) {  
        window.tronWeb.defaultAddress = {
          hex: window.tronWeb.address.toHex(TronConfig.FOUNDATION_ADDRESS),
          base58: TronConfig.FOUNDATION_ADDRESS
        };
        
        window.tronWeb.on('addressChanged', () => {
          if(this.state.tronWeb.loggedIn)
              return;

          this.setState({
              tronWeb: {
                  installed: true,
                  loggedIn: true
              }
          },()=>{
            console.log('login');
          });
      });
    }

    await Utils.setTronWeb(window.tronWeb);
    this.fetchBalance();
    this.isSubscription();
  }

  
  async isSubscription() {
    const trondata = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS)
    const walletAdd=trondata.tronWeb.defaultAddress.base58;
    console.log(walletAdd);
    try{
      this.setState({
         ...this.state,
         userAddress:walletAdd
        })
    }catch(error){
      //console.error(error)  
    }
  }

  async handleTrxamount(){ 
    if(!this.state.tronWeb.loggedIn){
      this.setState({
        ...this.state,
        loading:false,
        submitBtn:false,
        notify:{
          visible:true,
          type:'warning',
          msg : 'You are not logged In'
        }
      });
      return false;
    }
   
    await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS);
    if(this.state.trxamount){
      this.setState({
        approveBtn:true,
      })
      this.depositeApi();
    }
  }

  async depositeApi(){
    let callvalue = 1e10;
    const trondata  = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS);
    const account = trondata.tronWeb.defaultAddress.base58;
    console.log(trondata);
    try{
      const transaction = await trondata.approve(account,callvalue).send({
        callValue:callvalue
      });
      const status = await TrxResponseMessage(transaction)
      if(status.txStatus==='SUCCESS'){
        this.setState({
          // approveBtn:false,
          // trxAmountModel:{
          //   visible:false,
          //   style:{
          //     display:"none"
          //   }
          // },
          notify:{},
          requestAuditModel:{
            visible:true,
            style:{
              display:"block",
              backgroundColor:'#000000cf'
            }
          }
        })
      }else if(status.txStatus==='REVERT'){
        let msg = '';
        if(status.revertMessage==='insufficient balance'){
          msg = `You dont have enough Audit tokens to request the audits. You can get more audit tokens by contact admin on ${window.location.origin}/about`
        }else if(status.revertMessage==="r audit"){
          msg = 'Contract is already submitted for audit'
        }
        else{
          msg = "There is some issue please try again later"
        }
        
        this.setState({
          msg: msg,
          submitBtn:false,
          notify:{},
          approveBtn:false,
          formData:{
            name:"",
            category:"",
            blockchain:"Tron",
            website_link:"",
            telegram_url:"",
            contract_id :"",
            email:"",
            logo:"",
            verify_register:"",
            termsandCondi:false
           }
        },()=>{
          console.log(this.state)
        })
      }
    }catch(e){
      this.setState({
        ...this.state,
        approveBtn:false,
        submitBtn:false,
        loading:false,
        notify:{
          visible:true,
          type:'warning',
          msg:e
        }
      })
      console.error(e)
    } 
  }

  handleAmountUnit = async e=>{ 
    e.preventDefault();
    if(this.state.amount_unit256 < 500)return false
    if(!this.state.tronWeb.loggedIn){
      this.setState({
        ...this.state,
        loading:false,
        submitBtn:false,
        notify:{
          visible:true,
          type:'warning',
          msg : 'You are not logged In'
        }
      });
      return false;
    }
    await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS);
    if(this.state.amount_unit256){
      this.setState({
        notify:{},
        requestBtn:true
      })
      this.requestAuditApi();
    }
  }
  
  async requestAuditApi(){
    let amount_unit256 = this.state.amount_unit256*1000000 ;
    let contractAddress_address = this.state.formData.contract_id ;
    //console.log(contractAddress_address);
    const trondata  = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS);
    const address = trondata.tronWeb.defaultAddress.base58;
    try{
      const transaction = await trondata.requestAudit(amount_unit256,contractAddress_address).send({
         feeLimit:300000000,
         callValue:amount_unit256.toString(),
        _contractAddress:contractAddress_address
      });
        //console.log(transaction);
            if(transaction){
        console.log(transaction)
        const status = await TrxResponseMessage(transaction)
        console.log(status)
          if(status.txStatus==='SUCCESS'){
            const trondata = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS)
            const x = await trondata.getContractIdByAddress(contractAddress_address).call()
            const id = window.tronWeb.toBigNumber(x._hex)
            console.log(id.c[0],x)
            this.setState({
              contract_key: id.c[0],
              requested_by:address,
              requestBtn:false,
              requestAuditModel:{
                visible:false,
                style:{
                  display:"none"
                }
              }
            },()=>{
              if(transaction){
                this.formSubmit();
              }
            })
          }else if(status.txStatus==='REVERT' || status.txStatus==='OUT_OF_ENERGY'){
            let msg = '';
            if(status.revertMessage==='insufficient balance'){
              msg = `You dont have enough Audit tokens to request the audits. You can get more audit tokens by contact admin on`
            }else if(status.revertMessage==="r audit"){
              msg = 'Contract is already submitted for audit'
            }else if(status.txStatus==='OUT_OF_ENERGY' && status.revertMessage===""){
              msg = 'You are requested to borrow some energy to make this transaction'  
            }else{
              msg = "There is some issue please try again later"
            }

            this.setState({
              msg: msg,
              requestBtn:false,
              approveBtn:false,
              requestAuditModel:{
                visible:false,
                style:{
                  display:"none"
                }
              },
              formData:{
                name:"",
                category:"",
                blockchain:"Tron",
                website_link:"",
                telegram_url:"",
                contract_id :"",
                email:"",
                logo:"",
                verify_register:"",
                termsandCondi:false
               }
            },()=>{
              console.log(this.state)
            })
          }
        
      }

    }catch(e){
      //console.error(e);
      let err = e.toString()
      let invalid = /(i|I)nvalid address/g
      let status = invalid.test(err)
      console.log(status);
      if(status){
        this.setState({
          ...this.state,
          loading:false,
          submitBtn:false,
          notify:{
            visible:true,
            type:'warning',
            msg : 'Contract id is invalid'
          },
          requestBtn:false,
          requestAuditModel:{
            visible:false,
            style:{
              display:"none"
            }
          }
        })
      }else{
        this.setState({
          ...this.state,
          loading:false,
          notify:{
            visible:true,
            type:'warning',
            msg : e
          },
          requestBtn:false,
          requestAuditModel:{
            visible:false,
            style:{
              display:"none"
            }
          }
        })
      }
    } 
  }
  
  async fetchBalance(){
    const trondata = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS)
    console.log(trondata)
    try{
     const walletAdd = trondata.tronWeb.defaultAddress.base58;
   
     const balance = await window.tronWeb.trx.getBalance();
     
     const balanceInTRX = window.tronWeb.fromSun(balance); 
     console.log(balanceInTRX);
     this.setState({
       ...this.state,
       balance:balanceInTRX,
       userAddress:walletAdd,
       //msg: balanceInTRX <=100 ? 'You dont have enough trx balance':'' 
     },()=>{
       console.log(this.state.balance)
     })
    }catch(e){
      console.error(e);
    }
  }
  
  trxAmountModelClose=e=>{
    this.setState({
      trxAmountModel:{
        visible:false,
        style:{
          display:"none"
        }
      }
    })
  }

  requestAuditModelClose=e=>{
    this.setState({
      notify:{},
      submitBtn:false,
      requestAuditModel:{
        visible:false,
        style:{
          display:"none"
        }
      }
    })
  }

  AmountApproval(){
    return(
      <React.Fragment>
        <div className="modal-dialog">
          <div className="modal-content">
          <div className="modal-header">
              <h4 className="modal-title">Trx Amount Approve</h4>
              <button type="button" className="close" data-dismiss="modal" onClick={this.trxAmountModelClose}>&times;</button>
            </div>
            <div className="modal-body">
            <input
              placeholder="Trx Amount"
              name="trxamount"
              className="form-control"
              style={{ width: "100%" }}
              value={this.state.balance} readOnly
            />
            </div>
            <div className="modal-footer" style={{display:'block'}}>
            <button
              className="btn btn-primary"
              type="submit"
              style={{float:'left'}} onClick={this.handleTrxamount}
              disabled={this.state.approveBtn}
            >
              {this.state.approveBtn?'Please wait ...':'Submit'}
            </button>
            </div>
          </div>
        </div>
      </React.Fragment>  
    )
  }


  RequestAudit(){
      return(
          <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
               <h4 className="modal-title">Request Audit</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={this.requestAuditModelClose}>&times;</button>
              </div>
              <div className="modal-body">
              <input
                type="number"
                min="500"
                placeholder="Enter amount"
                name="amount_unit256"
                className="form-control"
                style={{ width: "100%" }}
                onChange={this.handleChange}
              />
               <span style={{color:'red',fontSize:'10px',margin:'10px'}}>*Minimum amount should be 500</span>
              </div>
              <div className="modal-footer" style={{display:'block'}}>
              <button
                className="btn btn-primary"
                type="submit"
                style={{float:'left'}} onClick={this.handleAmountUnit}
                disabled={this.state.requestBtn}
              >
                {this.state.requestBtn?'Please wait ...':'Submit'}
              </button>
              </div>
            </div>
          </div>
      )
    }

  handleChange=e=>{
    const target = e.target;
    console.log(target)
    const value = target.type === 'checkbox' ? target.checked : target.value
    if(e.target.name==="amount_unit256"){
      this.setState({
        notify:{},
        msg:'',
        amount_unit256:e.target.value
      })
    }else{
      this.setState({
          formData:{
            ...this.state.formData,
            [e.target.name]:value
          },
          notify:{},
          msg:''
        })
     } 
   }

  
   handleFileInput=e=>{
    const file = e.target.files[0];
    
    const ext = file.name.split('.')[1]
     if(ext==='jpg'||ext==='JPG'||ext==='jpeg'||ext==='png'||ext==='PNG'||ext==='JPEG'){
       this.setState({
         formData:{
           ...this.state.formData,
           logo:file
         }
        })
     }else{
       this.setState({
         errors:{
           logo:'Only files following extensions are allowed : jpg jpeg png'
         }
       })
     }
   }
  
  async handleValidation(){
    if(this.state.formData.name.length===0){
      this.setState({
        errors:{
          name:'Name is required'
        }
      })
      return false;
    };
   
    if(this.state.formData.category.length===0){
      this.setState({
        errors:{
          category:'Please select an category'
        }
      })
      return false;
    };

    if(this.state.formData.category.length===0){
      this.setState({
        errors:{
          category:'Please select an category'
        }
      })
      return false;
    };

    let url_pattern = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if(this.state.formData.website_link.length===0){
      
      this.setState({
        errors:{
          website_link:'Website Url is requied'
        }
      })
      return false;
    }
    if(url_pattern.test(this.state.formData.website_link)===false){
       this.setState({
        errors:{
          website_link:'Enter valid website url: for example https://defiaudits.io/'
        }
      })
      return false;
    };

    if(this.state.formData.contract_id.length===0){
      this.setState({
        errors:{
          contract_id:'Contract Id is required'
        }
      })
      return false;
    };

    if(this.state.formData.blockchain==='Tron'){
      try{ 
      await window.tronWeb.contract().at(this.state.formData.contract_id);
      }catch(e){
        if(e.toString()==="Invalid contract address provided"){
          console.log(this.state.formData.contract_id,e)
          this.setState({
          errors:{
            contract_id:'Enter Valid Contract Id'
          }
        })
        return false;
        }
      } 
    }else if(this.state.formData.blockchain==='Ethereum'|| this.state.formData.blockchain==='Binance'){
     if(!(Web3.utils.isAddress(this.state.formData.contract_id))){
      this.setState({
        errors:{
          contract_id:'Enter Valid Contract Id'
        }
      })
      return false;
     }
    }

    let email_pattern = /([^.@\s]+)(.[^.@\s]+)*@([^.@\s]+.)+([^.@\s]+)/g;
    if(this.state.formData.email.length===0){
      
      this.setState({
        errors:{
          email:'Email is requied'
        }
      })
      return false;
    }
    if(email_pattern.test(this.state.formData.email)===false){
       this.setState({
        errors:{
          email:'Enter Valid Email Address'
        }
      })
      return false;
    };

    if(this.state.formData.verify_register.length===0){
      this.setState({
        errors:{
          verify_register:'Please verify human verification'
        }
      })
      return false;
    };
    console.log(typeof this.state.formData.verify_register,typeof this.state.sumA+this.state.sumB)
    if(parseInt(this.state.formData.verify_register) !== this.state.sumA+this.state.sumB){
      this.setState({
        errors:{
          verify_register:'Human verification failed'
        }
      })
      return false;
    };

    if(!this.state.formData.termsandCondi){
      this.setState({
        errors:{
          termsandCondi:'Please check this box if you want to proceed'
        }
      })
      return false;
    };

    this.setState({
      errors:{}
    })
  }

  async handleOnSubmit(e){
    e.preventDefault();
    if(this.state.userAddress.length===0){
      this.setState({
        msg:'Please connect your wallet'
      })
      return false
    }
    //if(this.state.balance < 100 )return false
    if(!this.state.tronWeb.loggedIn){
      this.setState({
        ...this.state,
        loading:false,
         msg : 'Please Connect with tron wallet'
      });
      return false;
    }
     let valid = await this.handleValidation();
     if(valid===false)return false; 
      this.setState({
        notify:{},
        success:'',
        msg:'',
        submitBtn:true
      }) 
     let checkValid = await this.UniqueValidationCheck();  // for checking contract id and name is unique or not
     console.log(checkValid);
     if(checkValid){
       const trondata  = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS);
       const account = trondata.tronWeb.defaultAddress.base58;
       console.log(account)
     try{
        const allowance = await trondata.allowance(account,account).call();
        const allowStatus = window.tronWeb.toBigNumber(allowance);
        console.log(allowStatus)
        if(allowStatus.c[0]===0){
           this.setState({
            balance:1e18, 
           },()=>{ this.handleTrxamount()  });

        }else if(allowStatus.c[0] > 0){
          this.setState({
            submitBtn:false,
            requestAuditModel:{
              visible:true,
              style:{
                display:"block",
                backgroundColor:'#000000cf'
              }
            }
          })
        }
     }catch(e){
      console.log(e)
      this.setState({
        submitBtn:false,
      })
     }   
    }else{
      this.setState({
        submitBtn:false,
      })
    }
  }



  formSubmit(){
    this.setState({
      ...this.state,
      loading:true
    })
    const formData  = new FormData();
    formData.append('name',this.state.formData.name)
    formData.append('category',this.state.formData.category)
    formData.append('blockchain',this.state.formData.blockchain)
    formData.append('rating','1')
    // formData.append('heading',this.state.formData.headline)
    //formData.append('short_description',this.state.formData.short_description)
    formData.append('email',this.state.formData.email)
    formData.append('website_link',this.state.formData.website_link)
    formData.append('telegram_url',this.state.formData.name)
    formData.append('contract_id',this.state.formData.contract_id)
    formData.append('is_request',1)
    formData.append('logo',this.state.formData.logo)
    formData.append('contract_key',this.state.contract_key)
    formData.append('audit_tokens',this.state.amount_unit256)
    formData.append('requested_by',this.state.requested_by)
    axios.post(UrlString.BASE_URL+'/api/open/requestAudit',formData)
    .then(res=>{
      if(res.status===200){
        console.log(res.data)
        if(res.data.status==='1'){
          this.setState({
            success:true,
            loading:false,
            submitBtn:false,
            msg:'',
            errors:{}
          },()=>{
           this.setState({
            formData:{
              name:"",
              category:"",
              blockchain:"Tron",
              website_link:"",
              telegram_url:"",
              contract_id :"",
              email:"",
              logo:"",
              verify_register:"",
              termsandCondi:false
             }
           })
          })
        }
      }
    })
    .catch(err=>{
     if(typeof err.response !== 'undefined'){ 
      console.log(err.response.data)
      let err_data = [];
      if(err.response.data.errors.hasOwnProperty('contract_id')){
        err_data.push(err.response.data.errors.contract_id[0])
       }

       if(err.response.data.errors.hasOwnProperty('website_link')){
        err_data.push(err.response.data.errors.website_link[0]);
       }
        if(err_data.length > 0){
            this.setState({
              ...this.state,
              loading:false,
              errors: {
                resErr : err_data
              }
            },()=>{
              console.log(this.state.errors)
            })
        } 
      }
     })
  }

  UniqueValidationCheck(){
    const formData  = new FormData();
    formData.append('website_link',this.state.formData.website_link)
    formData.append('contract_id',this.state.formData.contract_id)

   let x = axios.post(UrlString.BASE_URL+'/api/open/priorityRequestValidation',formData)
    .then(res=>{
      if(res.status===200){
        console.log(res.data)
        return true;
      }
    })
    .catch(err=>{
     if(typeof err.response !== 'undefined'){ 
      if(err.response.data.errors.hasOwnProperty('contract_id')){
          this.setState({
            errors:{
              contract_id:err.response.data.errors.contract_id[0]
            }
          })
      }
      
      if(err.response.data.errors.hasOwnProperty('website_link')){
        this.setState({
          errors:{
            website_link:err.response.data.errors.website_link[0]
          }
        })
      }

      return false;
     }
   })
   return x
 }

  render(){
    return(
     <React.Fragment>
       <div className={this.state.trxAmountModel.visible?"modal fade show":"modal fade"} id="myModal" style={this.state.trxAmountModel.style}>
         {this.AmountApproval()}
        </div>
        <div className={this.state.requestAuditModel.visible?"modal fade show":"modal fade"} id="myModal" style={this.state.requestAuditModel.style}>
         {this.RequestAudit()}
        </div>
       {!this.state.loading ?
       (
        <main>
        <div id="hero_register">
          <div className="container margin_120_95">			
            <div className="row">
              <div className="col-lg-6">
                <h1>Submit Audit Request!</h1>
                <p className="lead">We would be able to process your request within 3-5 working days. In some cases if your request is rejected we would refund your Audit tokens in 3-5 working days. Please note we are not professional audit company</p>
                <div className="box_feat_2">
                  <i className="pe-7s-back-2"></i>
                  <h3>We check Exit Scam code!</h3>
                  <p>We check to see if there is any scam code / exit code in the contract and we point them out so that you could know if the project is genuine or not.</p>
                </div>
                <div className="box_feat_2">
                  <i className="pe-7s-news-paper"></i>
                  <h3>Investor Friendly Audits</h3>
                  <p>Unlike doing audits for project owners we do audits for project users so they could know everything about project in readable manner and which is straight forward compared to other audit reports which are less friendly for non tech people.</p>
                </div>
                <div className="box_feat_2">
                  <i className="pe-7s-attention"></i>
                  <h3>Things we dont cover in Audit</h3>
                  <p>We do not include the deep level audits (Example: Checking dividend functions) and we assume obvious things in contract to be expected working. However we will try to test Deposit & Withdraw functions</p>
                </div>
              </div>
              
              <div className="col-lg-5 ml-auto">
                <div className="box_form">
                {this.state.msg.length > 0 && this.state.msg.length !==106 ? (<div className="alert alert-warning">{this.state.msg}</div>) :('')}    
                {this.state.msg.length===106?(<div className="alert alert-warning">{this.state.msg} <a href="/about">{window.location.origin}/about</a></div>):('')}
                {this.state.success ? (<div className="alert alert-success">Audit Sent Successfully</div>):('')}
                {this.state.errors.resErr ? (<div className="alert alert-danger">
                <ul>
                  {this.state.errors.resErr.map(err=>{
                    return <li style={{color:'red'}}>{err}</li>
                  })}</ul></div>)
                  :('')}
                  <div id="message-register"></div>
                  <form encType="multipart/form-data" id="requestForm" onSubmit={this.handleOnSubmit}>
                    <div className="row">
                      <div className="col-md-12 ">
                        <div className={this.state.errors.name ? "form-group has-error" : "form-group"}>
                          <input type="text" className="form-control" placeholder="Name" name="name" id="name_register" onChange={this.handleChange} value={this.state.formData.name}/>
                          {this.state.errors.name ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.name}</span>) :''}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className={this.state.errors.category ? "form-group has-error" : "form-group"}>
                            <select name="category" className="form-control" onChange={this.handleChange} value={this.state.formData.category}>
                              <option value="">--Select Category--</option>
                              <option value="High-Risk">High-Risk</option>
                              <option value="ROI Apps">ROI Apps</option>
                              <option value="Farming Apps">Farming Apps</option>
                              <option value="Other Apps">Other Apps</option>
                           </select>
                          {this.state.errors.category ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.category}</span>) :''}
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select name="blockchain" className="form-control" onChange={this.handleChange}>
                           <option value="Tron">Tron</option>
                           <option value="Ethereum">Ethereum</option>
                           <option value="Binance">Binance</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12">   
                        <div className={this.state.errors.website_link ? "form-group has-error" : "form-group"}>
                         <input type="text" required="" name="website_link" className="form-control" placeholder="Website URL" onChange={this.handleChange} value={this.state.formData.website_link}/>
                           {this.state.errors.website_link ? (<span style={{color:'red',marginLeft:'6px',fontSize:'13px',display:'flex'}}>{this.state.errors.website_link}</span>) :''}
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                            <input type="text" required="" value={this.state.formData.telegram_url} name="telegram_url" className="form-control" placeholder="Telegram Id" onChange={this.handleChange}/>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className={this.state.errors.contract_id ? "form-group has-error" : "form-group"}>
                           <input type="text" required="" value={this.state.formData.contract_id} name="contract_id" className="form-control" placeholder="Contract Id be like 0Xb08E657B0da89f2D7235d56f04A4ac3fEd597d2" onChange={this.handleChange}/>
                           {this.state.errors.contract_id ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.contract_id}</span>) :''}
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className={this.state.errors.email ? "form-group has-error" : "form-group"}>
                          <div className="form-group">
                            <input type="text" required="" value={this.state.formData.email} name="email" className="form-control" placeholder="Enter Email" onChange={this.handleChange}/>
                            {this.state.errors.email ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.email}</span>) :''}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className={this.state.errors.logo ? "form-group has-error" : "form-group"}>
                          <input type="file" id="logo" name="logo" className="form-control" onChange={this.handleFileInput}/>
                          {this.state.errors.logo ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.logo}</span>) :''}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className={this.state.errors.verify_register ? "form-group has-error" : "form-group"}>
                          <input type="text" id="verify_register" name="verify_register" value={this.state.formData.verify_register} className="form-control" placeholder={`Human verify: ${this.state.sumA} + ${this.state.sumB} = ?`} onChange={this.handleChange}/>
                          {this.state.errors.verify_register ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.verify_register}</span>) :''}
                        </div>
                      </div>
                    </div>

                    <React.Fragment>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="checkboxes add_bottom_30 add_top_15">
                                <label className="container_check">I accept <a href="/faqs#terms">terms and conditions and general policy</a>
                                  <input type="checkbox" name="termsandCondi" checked={this.state.formData.termsandCondi}  onChange={this.handleChange}/>
                                  <span className="checkmark"></span>
                                </label>
                                {this.state.errors.termsandCondi ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.termsandCondi}</span>) :''}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div><input type="submit" className="btn_1" value={this.state.submitBtn?"Please wait...":"Submit"} id="submit-register" disabled={this.state.submitBtn}/></div>
                        </React.Fragment>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      ) : (<Loader/>)}
      {this.state.notify.visible?(<Notify attr={this.state.notify}/>):('')}
     </React.Fragment>
    )
  }
}

export default PriorityRequest