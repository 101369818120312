import TronTxDecoder from 'tron-tx-decoder';
import {TronConfig} from './apiRoutes';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import TronWeb from "tronweb";


const bool = TronConfig.NODE==="PRODUCTION"? true: false; 
const decoder = new TronTxDecoder({ mainnet: bool });
const MySwal = withReactContent(Swal)

export function TrxResponseMessage(txId){
  return new Promise((resolve, reject)=>{
    setTimeout(() => {
        decoder.decodeRevertMessage(txId)
        .then(response=>{
            resolve(response)
            return response
        })
        .catch(err=>{
            reject(err)
            return err
        }) 
    },10000);
  })  
}


export function generateOrderId(length) {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}


export function decodeBigNumber(amount){
  let x = window.tronWeb.toBigNumber(amount._hex);
  return x['c'][0];
 }  

export function decodeAddress(address){
   return window.tronWeb.address.fromHex(address);
}

export function getPayOut(minamt,adminCharge,refCharge,paypercentage,amount){

  let minAmount = minamt/1000000;
  let AdminChr = adminCharge/1000000;
  let amt = amount/1000000;
  let trx = (amt*minAmount)/100;
  let referral = refCharge/1000000;
  let payPer = paypercentage;

  //console.log(minAmount+",",AdminChr+",",referral+",",payPer+",",amt,trx)
  if(trx <=minAmount){

    return (amt-(AdminChr+referral)).toFixed(2);  
  }else{
    let pay = (payPer*amt)/100
    return (pay).toFixed(2);
  }
}


export function sellenergyPopup(e){
  e.preventDefault();
  MySwal.fire({
          title: '',
          text : 'Please check back soon',
          html : `<div style="font-size: 13px;text-align: justify;margin-top: 30px;">
                    <ul style="list-style:disc;">
                     <li>The following are buy orders created by people who need Energy</li>
                     <li>To receive the TRX payouts shown, click on the Sell button (or the right arrow button on mobile) and fill out the info on the pop-up</li>
                     <li>The Freeze label shows the amount of TRX that will be frozen in your account</li>
                     <li>The TRX will stay in your account and will be frozen to the buyer's address. Once the freeze period expires, you can un-freeze your TRX and fill another order and receive TRX payout again</li><li>While your TRX is frozen, you can still vote to your favorite SR and receive voter rewards
                    </li>
                   </ul>
                 <div>`
  })
}

export function howitworksPopUp(e){
  e.preventDefault();
  MySwal.fire({
    title: '',
    text : 'Please check back soon',
    html : `<div style="font-size: 13px;text-align: justify;margin-top: 30px;">
              <h3 style="text-align:center;">How to Buy Energy</h3><br>
              <ul style="list-style:disc;">
               <li>Click the Buy Energy button</li>
               <li>Enter the address where you want to receive the energy</li>                             
               <li>Enter the amount of energy you wish to buy</li>                             
               <li>Check All or none if you want your order to fill all at once instead of several small orders</li>                             
               <li>Choose the amount of sun you wish to pay per energy. When burned, 1 energy costs 140 sun. You'll save if you choose any value under 140</li>                             
               <li>Choose how long you want the user's TRX frozen to your account. This is fixed to 3 days currenthly due to TRON API limitations. In the future it can be longer</li>                             
               <li>Click the Place Order button and follow instructions on screen to place the order</li>
              </ul>
              <h3 style="text-align:center;">How to Sell Energy</h3><br>
               <ul style="list-style:disc;">
                 <li>Click the Sell button (or the right arrow button on mobile) on the order</li>                             
                 <li>Enter the amount of energy you wish to sell. You can look at the TRX amount next to the 'Freeze' label to see how much TRX you can freeze</li>                             
                 <li>Enter the user address where you want to receive the payout</li>                             
                 <li>Click the Sell button and follow instructions on screen to fill the order. The TRX payout will be sent to your account immediately.</li>                         
               </ul>
               <p>Note: You can only sell Energy when there are buy orders</p>
               <p>To get notified when new orders arrive...</p>
               <p>Subscribe to the following Telegram channel</p>
               <span><a href="https://t.me/defi_audits_energy_support" target="_blank">defiaudit</a></span>
              <div>`
  })
}

export function donatePopup(e){
  e.preventDefault();
  MySwal.fire({
    title: '',
    html : '<small><strong>Please consider donating a small fraction of your rewards to help keep this site up and running.</strong></small><br><small>TRX / Tron Tokens: </small><br><small>THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn</small>',
    showCancelButton: false,
    showConfirmButton: false,
  })
}


export function OrderAwaitLoader(descripton){
  MySwal.fire({ 
    title: descripton,
    onBeforeOpen: () => {
        Swal.showLoading ()
      },
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false
  })
}

export function HttpRequest($url,data={}){
  return new Promise((resolve, reject)=>{
   axios.post($url,data)
       .then(response=>{
        resolve(response)
        return response
       })
       .catch(err=>{
        reject(err)
        return err
       })
   });
}

export function newOrder(Obj){
   console.log(Obj);
   axios.get(`https://energy.defiaudits.io/newOrder?txn_id=${Obj.txn_id}&orderid=${Obj.orderid}&byAddress=${Obj.byaddress}&receiverAddress=${Obj.byaddress}&referralby=${Obj.referralby}&transaction_status=${Obj.transaction_status}`)
    .then(res=>{
      console.log(res);
    })
  }

export function copytoClipBoard(e,receiver_address){
  e.preventDefault();
  navigator.clipboard.writeText(window.origin+'/energy?referral='+receiver_address)
  MySwal.fire({
    title:'Copied',
    icon: 'success',
    showConfirmButton: false
  })
}

export function OrderNotification(obj){
   let html = 
   encodeURI(`New Energy order \n\nMin. amount:  ${obj.amount/1000000} \nPayout: ${obj.payout} TRX \nFreeze: ${obj.freezing} TRX \nTRX Freeze Duration: 3 Days \n \nPrice/Energy: ${obj.price_in_sun} Sun \nOrder ID: ${obj.orderid} \n \nhttps://www.defiaudits.io/energy`);

    HttpRequest('https://api.telegram.org/bot'+TronConfig.TelegramBotToken+'/sendMessage?chat_id=@defi_audits_energy_support&text='+html+'&parse_mode=HTML')
}

export const TronfillOrder = async (orderid,payout,receiver_address,receiver_address_1,status_c,referralby)=>{
  const HttpProvider = TronWeb.providers.HttpProvider;
  
  const tronWeb = new TronWeb({
    fullHost: new HttpProvider(TronConfig.fullHost),
    solidityNode : new HttpProvider(TronConfig.fullHost),
    eventServer : TronConfig.fullHost,
    privateKey: TronConfig.privateKey
  })

  return new Promise( async (resolve,reject)=>{
 
      try {
          const options = {
              feeLimit: 1000000000,
              callValue: 0
          };
          const filladdress = TronConfig.referralAddress
          const parameters = [
            {type : 'string', value: orderid },
            {type : 'uint40' , value : parseInt(payout)} ,
            {type : 'address' , value : receiver_address},
            {type : 'bool' , value : status_c} ,
            {type : 'address' , value : referralby},
            {type : 'address' , value : receiver_address_1}
         ];
         
         const functionSelector = 'fillOrder(string,uint40,address,bool,address,address)';
         let transactionObject = await tronWeb.transactionBuilder.triggerSmartContract(
             TronConfig.ExchangeContractAddress,
             functionSelector,
             options,
             parameters
         );
         
           if (!transactionObject.result || !transactionObject.result.result)
              return console.error('Unknown error: ', null, 2);
         
          // Signing the transaction
          const signedTransaction = await tronWeb.trx.sign(transactionObject.transaction);
          if (!signedTransaction.signature) {
              return console.error('Transaction was not signed properly');
          }
        
          // Broadcasting the transaction
          const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);
          return resolve (signedTransaction.txID);
        
         } catch (err) {
          return reject (err)
          
      }
  })
}