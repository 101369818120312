import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import SvgComponent from '../SharedModule/SvgComponent';
import axios from 'axios';
import {UrlString} from '../../config/apiRoutes';

class Home extends React.Component{
  constructor(props){
	super(props)
	this.state = {
	data:'',
	search_home:'',
	search_btn:'all',
	}
	this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange=(e)=>{
	 let value = e.target.value
	this.setState({
	  [e.target.name] : value,
	  data:e.target.value.length===1? '' : this.state.data 
   },()=>{
	   console.log(value,value.length,this.state.data.length)
	   if(value.length >= 2){
		   console.log('in')
		axios.post(UrlString.BASE_URL+'/api/open/getAllReports?search_home='+this.state.search_home+'&req='+this.state.search_btn)
		.then(res=>{
			if(res.status===200){
			this.setState({
				data: res.data.reports,
			})
		   }
		})
	   }
   })
  }

  handleType=(blockchain)=>{
	  this.setState({
        search_btn : blockchain
	  })
  }

  handleSubmit(e) {
   e.preventDefault();
   if(this.state.search_home.length > 0){
	window.location.href=window.location.origin+'/listing?search='+this.state.search_home;
   }else{
	window.location.href=window.location.origin+'/listing';  
   }	
  }
  
  render(){
	const searchStyle=()=>{
		if(this.state.data!==""){
          return  {width:'100%',overflow:'auto',display:'block'}
		}else{
			return {width:'100%',overflow:'auto',display:'none'}	
		}
	}  
	let link = '';
    return(
     <React.Fragment>
       <main>
		<div className="hero_home version_1">
			<div className="content">
				<h3>Find an Audit Report!</h3>
				<p>
					A place to request & read audits for the community by the community.
				</p>
				<form onSubmit={this.handleSubmit} autoComplete="off">
					<div id="custom-search-input">
						<div className="input-group">
							<input type="text" name="search_home" className="search-query" placeholder="Ex. Contract Name, Contract Id ...." onChange={this.handleChange}/>
							<input type="submit" className="btn_search" value="Search"/>
							 <div className="dropdown-menu" id="_searchBox" style={searchStyle()}>
							  {this.state.data.length > 0 ? this.state.data.map((ele,key)=>{
							  link = encodeURI(ele.name);
							  link = link.replace(/%20/g, "-")
							  link = link.replace(/--/g, "-")
							   return <React.Fragment><a key={key} className="dropdown-item dropdown-text-none" href={`/view-report/${link}`}>
								     <small>Contract: </small>
									 <div className="d-flex">
										 <div className="truncate-ellipsis" style={{width:'auto',overflow:'hidden'}}>
											 <strong>{ele.name} {ele.contract_id.substr(0,5)}....{ele.contract_id.substr(ele.contract_id.length-5,5)}</strong>
										 </div><span className="ml-1 search-contract"></span>
							        </div></a> 
									{ele.is_scam===0? (<i className="fa fa-check" style={{color:'green',marginTop:'-25px',float:'right',marginRight:'5px'}} aria-hidden="true"></i>):(ele.is_scam===1?(<i className="fa fa-exclamation-triangle" style={{color:'red',marginTop:'-25px',float:'right',marginRight:'5px'}} aria-hidden="true"></i>):(<i className="fa fa-exclamation-triangle" style={{color:'orange',marginTop:'-25px',float:'right',marginRight:'5px'}} aria-hidden="true"></i>)) }
							        </React.Fragment>
							  })
	                          :(
								<a href={()=>false} className="dropdown-item dropdown-text-none">No Result Found <div className="d-flex"><div className="truncate-ellipsis" style={{width:'auto'}}><strong></strong></div><span className="ml-1 search-contract"></span></div></a>
                               )}
							 </div>
						</div>
						<ul>
							<li>
								<input type="radio" id="all" name="search_btn" value="all"   defaultChecked={this.state.search_btn==='all'?true:false} />
								<label htmlFor="all" onClick={()=>this.handleType('all')}>All</label>
							</li>
							<li>
								<input type="radio" id="doctor" name="search_btn" value="Tron"  defaultChecked={this.state.search_btn==='Tron'?true:false}/>
								<label htmlFor="doctor" onClick={()=>this.handleType('Tron')}>Tron</label>
							</li>
							<li>
								<input type="radio" id="clinic" name="search_btn" value="Ethereum"  defaultChecked={this.state.search_btn==='Ethereum'?true:false}/>
								<label htmlFor="clinic" onClick={()=>this.handleType('Ethereum')}>Ethereum</label>
							</li>
						</ul>
					</div>
				</form>
			</div>
		</div>
	
        <div className="container margin_120_95">
			<div className="main_title">
				<h2>What is <strong>DEFI Audit</strong> all about?</h2>
				<p>We are community driven organization which does audit for the investors like ourselves who seek contracts to invest which has no exit code or back doors.</p>
			</div>
			<div className="row add_bottom_30">
				<div className="col-lg-4">
					<div className="box_feat" id="icon_1">
						<span></span>
						<h3>Request an Audit</h3>
						<p>You can request normal audit & priority audits through this website.</p>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="box_feat" id="icon_2">
						<span></span>
						<h3>Search Audits</h3>
						<p>You can search the audit of smart contract before requesting an audit for the same.</p>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="box_feat" id="icon_3">
						<h3>Get Updates</h3>
						<p>Join our announcement channel to get regular updates on latest audits & updates.</p>
					</div>
				</div>
			</div>
	
			<p className="text-center"><Link to="/request" className="btn_1 medium">Request an Audit</Link></p>
		</div>


		<div id="app_section">
			<div className="container">
				<div className="row justify-content-around">
					<div className="col-md-5">
						<p><img src="assets/img/app_img.png" alt="" className="img-fluid" width="500" height="433"/></p>
					</div>
					<div className="col-md-6">
						<small>Community</small>
						<h3>Join <strong>DefiAudits Community</strong> Now!</h3>
						<p className="lead">Defi Community help you to avoid scam project. You can also discuss the defi projects between community members and educate yourself on how to be safe within DEFI SPACE. You can join the community by requesting admins for invite link</p>
						<div className="app_buttons wow" data-wow-offset="100">
                          <SvgComponent/>
							<a href="https://t.me/defiaudit" className="fadeIn" target="_blank" rel="noopener noreferrer"><img src="assets/img/telegram.svg" alt="" width="150" height="50" data-retina="true"/></a>
							{/* <!-- <a href="#0" className="fadeIn"><img src="img/google_play_app.png" alt="" width="150" height="50" data-retina="true"></a> --> */}
						</div>
					</div>
				</div>
			
			</div>
		
		</div>
		
	</main>

     </React.Fragment>
    )
  }
}

export default withRouter(Home)