import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {UrlString} from '../../config/apiRoutes';
import {TronConfig} from '../../config/apiRoutes';
import Loader from '../SharedModule/Loader';

class ViewReport extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data:{},
      status:'',
      trxBalance : 0
    }
  }
  
  componentDidMount(){
    let link = this.props.location.pathname
    link = link.replace('/view-report/','');
    link = link.replace(/-/g, " ")
    
   axios.post(UrlString.BASE_URL+'/api/open/getReportDetail',{'report_id':link})
   .then(res=>{
      if(res.status===200){
        this.setState({
          data : res.data.reportDetails
        },()=>{
          let url_pattern = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
          let id ='';
          if(url_pattern.test(this.state.data.contract_id)){
             id = this.state.data.contract_id.split('/')
          }
          let contract_id = '';
          console.log(id);
          if(id.length > 1 && Array.isArray(id)){
           contract_id = id[5];
          }else{
            contract_id = this.state.data.contract_id;
          }
          console.log(contract_id)
         if(this.state.data.blockchain === "Tron"){
          axios.get('https://apilist.tronscan.org/api/contract?contract='+contract_id)
          .then(res=>{
           
            if(res.status===200){
              this.setState({
                ...this.state,
               trxBalance:res.data.data[0].balance/1000000,
               transactionCount:res.data.data[0].trxCount
              })
            }
          })

        }else if(this.state.data.blockchain === "Ethereum"){
         
          axios.get('https://api.etherscan.io/api?module=account&action=balance&address='+contract_id+'&tag=latest&apikey='+TronConfig.EtheapiKey)
          .then(res=>{
            if(res.status===200){
             console.log(res);
              this.setState({
                ...this.state,
               trxBalance:res.data.result/1e18,
               transactionCount:0,
              })
            }
          })
        }else if(this.state.data.blockchain === "Matic/Polygon"){
          axios.get(`https://api.polygonscan.com/api?module=account&action=balance&address=0x83DAEf9c93fb64Ac2C698F37847b715686b10fb1&tag=latest&apikey=${TronConfig.PolyapiKey}`)
          .then(res=>{
            if(res.status===200){
             console.log(res);
              this.setState({
                ...this.state,
               trxBalance:res.data.result/1e18,
               transactionCount:0,
              })
            }
          })
        }else{
          axios.get('https://api.bscscan.com/api?module=account&action=balance&address='+contract_id+'&tag=latest&apikey='+TronConfig.EtheapiKey)
          .then(res=>{
            if(res.status===200){
             console.log(res);
              this.setState({
                ...this.state,
               trxBalance: res.data.result ? res.data.result/1e18 : 0,
               transactionCount:0,
              })
            }
          })
          // https://api.bscscan.com/api?module=account&action=balance&address=0x0c22c33eaefc961ed529a6af4654b6c2f51c12d3&tag=latest&apikey=VTNE4YPDDDAESWBVBN5KCI17WPF17R9N1I
        }


        })
      }
   }).catch(err=>{
     if(typeof err.response !== 'undefined'){ 
       if(err.response.hasOwnProperty('status')){
         this.setState({
           ...this.state,
           status:err.response.status
         })
        }
      }
    })
  }

  notFoundException=()=>{
    this.props.history.push('/404')
  }

  ratings(rate){
    var x = Number(rate)% 1===0 ? true : false
    let ele = [];
    let remStar = Math.round(5-rate);
    remStar = (x===false)?remStar-1:remStar
  
    const loop=()=>{
      for(var i=1;i<=rate;i++){
        ele.push(<i key={Math.random()} className="icon_star voted"></i>)
      }
      if(!x){
        ele.push(<i key={Math.random()} className="icon_star-half_alt voted"></i>)
      }
      
      for(var j=1;j<=remStar;j++){
        ele.push(<i key={Math.random()} className="icon_star"></i>)
      }
         return ele
    }
  
  
    return (
      <React.Fragment>
         <span className="rating">
           {loop()}
        </span>
      </React.Fragment>
    )
  } 

  render(){
    const screenshots = Object.keys(this.state.data).length > 0 ? this.state.data.screenshots.split(','):''
    return(
    <main>
     {Object.keys(this.state.data).length > 0 ?   
      (this.state.data.is_request===1 ? (
        <div id="error_page"><div className="container"><div className="row justify-content-center text-center"><div className="col-xl-7 col-lg-9"><h2>Under Audit<i className="icon_clock_alt"></i></h2><p>We're working on auditing thus contract, please check back later.</p></div></div></div></div>
      ) :
      this.state.data.is_scam !==3 ?  
     (<React.Fragment>
    <div id="breadcrumb">
      <div className="container">
        <ul>
          <li><a href="/">Home</a></li>
          <li>View Report</li>
        </ul>
      </div>
    </div>  
    <div className="container margin_60">
      <div className="row">
        <React.Fragment>
        <aside className="col-xl-3 col-lg-4" id="sidebar">
          <div className="box_profile">
            <figure>
              <img src={this.state.data.logo.length > 0 ? `${UrlString.BASE_URL}${this.state.data.logo}`:'/assets/images/defi-channel.jpg'} alt="" referrerPolicy="no-referrer" className="img-fluid"/>
            </figure>
            <small>{this.state.data.blockchain} App</small>
            <h6>{this.state.data.name}</h6>
            {this.ratings(this.state.data.rating)}
            <a href={()=>false} data-toggle="tooltip" data-placement="top" data-original-title="Badge Level" className="badge_list_1">
            {this.state.data.is_scam===2 || this.state.data.is_scam===3 || this.state.data.is_scam===4 ? 
              (<img src="/assets/img/badges/badge_1.svg" width="15" height="15" alt=""/>): (this.state.data.is_scam===1?(<img src="/assets/img/badges/badge_5.svg" width="15" height="15" alt=""/>):(<img src="/assets/img/badges/badge_6.svg" width="15" height="15" alt=""/>))
            }
            </a>
            
            <ul className="statistic">
              <li>{parseFloat(this.state.trxBalance).toFixed(4)} {this.state.data.blockchain === "Ethereum" ?"ETH":this.state.data.blockchain === "Tron" ? "TRX": this.state.data.blockchain === "Matic/Polygon" ? "Matic" : "BNB" } </li>
            </ul>
            <ul className="contacts">
              {this.state.data.blockchain==="Tron" ?
              (<li style={{wordBreak:'break-all'}}><h6>Contract Address</h6><a href={`https://tronscan.org/#/contract/${this.state.data.contract_id}`} target="_blank" rel="noopener noreferrer">{this.state.data.contract_id}</a></li>)
              : (this.state.data.blockchain==="Ethereum" ? 
                (<li style={{wordBreak:'break-all'}}><h6>Contract Address</h6><a href={`https://etherscan.io/address/${this.state.data.contract_id}#code`} target="_blank" rel="noopener noreferrer">{this.state.data.contract_id}</a></li>)
              : (this.state.data.blockchain==="Matic/Polygon"?
                 (<li style={{wordBreak:'break-all'}}><h6>Contract Address</h6><a href={`https://polygonscan.com/address/${this.state.data.contract_id}#code`} target="_blank" rel="noopener noreferrer">{this.state.data.contract_id}</a></li>)    
              :  (<li style={{wordBreak:'break-all'}}><h6>Contract Address</h6><a href={`https://bscscan.com/address/${this.state.data.contract_id}#code`} target="_blank" rel="noopener noreferrer">{this.state.data.contract_id}</a></li>) 
              ))}
            <li><h6>Website</h6><a href={this.state.data.website_link} target="_blank" rel="noopener noreferrer">{this.state.data.name}</a></li>
            <li><h6>Transaction Count</h6><p>{this.state.transactionCount}</p></li>
           
            </ul>
            <div className="text-center"><a href={this.state.data.website_link} className="btn_1 outline" target="_blank" rel="noopener noreferrer"><i className="icon-globe"></i> Visit Website</a></div>
          </div>
        </aside>
  
        
        <div className="col-xl-9 col-lg-8">

          <div className="tabs_styled_2">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="book-tab" data-toggle="tab" href="#book" role="tab" aria-controls="book">Audit Summary</a>
              </li>
            </ul>
  

            <div className="tab-content">
              
              <div className="tab-pane fade show active" id="book" role="tabpanel" aria-labelledby="book-tab">
              {this.state.data.is_scam===1 ? 
               (<div className="message">
                <p>This project has been reported as Exit Scam. Please be careful before investing</p>
              </div>) :(this.state.data.is_scam===4? (<div className="message"><p>This project has been reported as Heavy Caution. Please be careful before investing. This could be very risky. DYOR before investing</p></div>):(this.state.data.is_scam===2)?(
                <div className="message"><p>This project is reported as possible scam. We advise you to kindly stay away from this contract.</p></div>
              ):(''))
              }
                <div className="indent_title_in">
                  <i className="pe-7s-news-paper"></i>
                  <h3>Audit Report</h3>
                  <p>A brief summary of audit report. </p>
                </div>
                
                <div dangerouslySetInnerHTML={{ __html: this.state.data.audit_report }} />

                {this.state.data.screenshots.length> 0 ?
                (<React.Fragment>
                <div className="indent_title_in">
                  <i className="pe-7s-news-paper"></i>
                  <h3>Source Code</h3>
                  <p>This is images of source code audited by us. </p>
                </div>
                 {screenshots.map((ele,key)=>
                  <React.Fragment>
                  <div className="row">
                    <div className="col-12"> <img src={`${UrlString.BASE_URL}/${ele}`} referrerPolicy="no-referrer" style={{maxWidth:'-webkit-fill-available'}} alt=""/> </div>
                  </div>
                  <hr/>
                  </React.Fragment>
                )}
                </React.Fragment>):(<hr/>)} 
                <div className="indent_title_in">
                  <i className="pe-7s-info"></i>
                  <h3>Disclaimers</h3>
                  <p>Please read this carefully. We are not financial advisor and we are not professional audit firm.</p>
                </div>

                <div className="wrapper_indent">
                  <div dangerouslySetInnerHTML={{ __html: this.state.data.signature? this.state.data.signature:''  }} />
                </div>
                <hr/>
              </div>
            </div>
           </div> 
          </div></React.Fragment>
         </div>
        </div>   
         </React.Fragment>) : (
           <div id="error_page"><div className="container"><div className="row justify-content-center text-center"><div className="col-xl-7 col-lg-9"><h2>Unverified<i className="icon_error-triangle_alt"></i></h2><p>We're sorry, but This contract is not verified so we can not audit this contract.</p></div></div></div></div>
           )) : (this.state.status===404? this.notFoundException() :(<Loader/>))}
          </main>
    )
  }
}

export default withRouter(ViewReport)