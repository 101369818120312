import axios from 'axios';
import React from 'react';
import Loader from '../SharedModule/Loader';
import TronWeb from "tronweb";
import Utils from '../../config/util';
import Web3 from 'web3';
import {TronConfig,UrlString} from '../../config/apiRoutes';


class Request extends React.Component{
  constructor(props){
   super(props)
   this.state={
     formData:{
      name:"",
      category:"",
      blockchain:"Tron",
      headline:"",
      //short_description:"",
      website_link:"",
      telegram_url:"",
      contract_id :"",
      email:"",
      logo:"",
      verify_register:"",
      termsandCondi:false,
     },
     errors:{},
     notify:{},
     success:false,
     loading:false,
     sumA:Math.floor(Math.random() * 10),
     sumB:Math.floor(Math.random() * 10)
    }
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }

  async componentDidMount(){
    //Web3.utils.isAddress()
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("balance");
    await new Promise(resolve => {
      const tronWebState = {
        installed: !!window.tronWeb,
        loggedIn: window.tronWeb && window.tronWeb.ready
      };
      if (tronWebState.installed) {
        this.setState({
          tronWeb: tronWebState
        });

        return resolve();
      }

      let tries = 0;

      const timer = setInterval(() => {
        if (2e4 <= tries) {
          window.tronWeb = new TronWeb(
            TronConfig.fullHost,
            TronConfig.privateKey
          );
          this.setState({
            tronWeb: tronWebState
          });
          clearInterval(timer);
          return resolve();
        }
        

        tronWebState.installed = !!window.tronWeb;
        tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
        
        if (!tronWebState.installed) {
          return tries++;
        }
       
        this.setState({
          tronWeb: tronWebState
        },()=>{
           localStorage.setItem('loggedIn',tronWebState.loggedIn);
        });

        return resolve();
      },100);
    });
     
       // Set default address (foundation address) used for contract calls
       // Directly overwrites the address object if TronLink disabled the
       // function call
       if(!this.state.tronWeb.loggedIn) {  
        window.tronWeb.defaultAddress = {
          hex: window.tronWeb.address.toHex(TronConfig.FOUNDATION_ADDRESS),
          base58: TronConfig.FOUNDATION_ADDRESS
        };
        
        window.tronWeb.on('addressChanged', () => {
          if(this.state.tronWeb.loggedIn)
              return;

          this.setState({
              tronWeb: {
                  installed: true,
                  loggedIn: true
              }
          },()=>{
            console.log('login');
          });
      });
    }

    await Utils.setTronWeb(window.tronWeb);
  }

  handleChange=e=>{
   const target = e.target;
   const value = target.type === 'checkbox' ? target.checked : target.value
   this.setState({
    formData:{
      ...this.state.formData,
      [e.target.name]:value
    },
    success:false,
    errors:{},
    },()=>{
     console.log(this.state);
   })
  }
  
  handleFileInput=e=>{
   const file = e.target.files[0];
   
   const ext = file.name.split('.')[1]
    if(ext==='jpg'||ext==='JPG'||ext==='jpeg'||ext==='png'||ext==='PNG'||ext==='JPEG'){
      this.setState({
        formData:{
          ...this.state.formData,
          logo:file
        }
       })
    }else{
      this.setState({
        errors:{
          logo:'Only files following extensions are allowed : jpg jpeg png'
        }
      })
    }
  }
  
  async handleValidation(){
    if(this.state.formData.name.length===0){
      this.setState({
        errors:{
          name:'Name is required'
        }
      })
      return false;
    };
   
    if(this.state.formData.category.length===0){
      this.setState({
        errors:{
          category:'Please select an category'
        }
      })
      return false;
    };

    if(this.state.formData.category.length===0){
      this.setState({
        errors:{
          category:'Please select an category'
        }
      })
      return false;
    };

    let url_pattern = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if(this.state.formData.website_link.length===0){
      
      this.setState({
        errors:{
          website_link:'Website Url is requied'
        }
      })
      return false;
    }
    if(url_pattern.test(this.state.formData.website_link)===false){
       this.setState({
        errors:{
          website_link:'Enter Valid Website Url'
        }
      })
      return false;
    };

    if(this.state.formData.contract_id.length===0){
      this.setState({
        errors:{
          contract_id:'Contract Id is required'
        }
      })
      return false;
    };
    
    if(this.state.formData.blockchain==='Tron'){
      try{ 
         await window.tronWeb.contract().at(this.state.formData.contract_id);
      }catch(e){
        if(e.toString()==="Invalid contract address provided"){
          console.log(this.state.formData.contract_id,e)
          this.setState({
          errors:{
            contract_id:'Enter Valid Contract Id'
          }
        })
        return false;
        }
      } 
    }else if(this.state.formData.blockchain==='Ethereum'|| this.state.formData.blockchain==='Binance'){
     if(!(Web3.utils.isAddress(this.state.formData.contract_id))){
      this.setState({
        errors:{
          contract_id:'Enter Valid Contract Id'
        }
      })
      return false;
     }
    }
    

    let email_pattern = /([^.@\s]+)(.[^.@\s]+)*@([^.@\s]+.)+([^.@\s]+)/g;
    if(this.state.formData.email.length===0){
      
      this.setState({
        errors:{
          email:'Email is requied'
        }
      })
      return false;
    }
    if(email_pattern.test(this.state.formData.email)===false){
       this.setState({
        errors:{
          email:'Enter Valid Email Address'
        }
      })
      return false;
    };

    if(this.state.formData.verify_register.length===0){
      this.setState({
        errors:{
          verify_register:'Please verify human verification'
        }
      })
      return false;
    };
    console.log(this.state.formData.verify_register,this.state.sumA+this.state.sumB)
    if(parseInt(this.state.formData.verify_register)!==this.state.sumA+this.state.sumB){
      this.setState({
        errors:{
          verify_register:'Human verification failed'
        }
      })
      return false;
    };

    if(!this.state.formData.termsandCondi){
      this.setState({
        errors:{
          termsandCondi:'Please check this box if you want to proceed'
        }
      })
      return false;
    };

    this.setState({
      errors:{}
    })
  }

  async handleOnSubmit(e){
    e.preventDefault();
     let valid = await this.handleValidation();
    if(valid===false)return false; 
    this.setState({
      ...this.state,
      loading:true
    })
    const formData  = new FormData();
    formData.append('name',this.state.formData.name)
    formData.append('category',this.state.formData.category)
    formData.append('blockchain',this.state.formData.blockchain)
    formData.append('rating','1')
    //formData.append('heading',this.state.formData.headline)
    //formData.append('short_description',this.state.formData.short_description)
    formData.append('email',this.state.formData.email)
    formData.append('website_link',this.state.formData.website_link)
    formData.append('telegram_url',this.state.formData.name)
    formData.append('contract_id',this.state.formData.contract_id)
    formData.append('is_request',1)
    formData.append('logo',this.state.formData.logo)

    axios.post(UrlString.BASE_URL+'/api/open/requestAudit',formData)
    .then(res=>{
      if(res.status===200){
        console.log(res.data)
        if(res.data.status==='1'){
          this.setState({
            success:true,
            loading:false,
            errors:{},
            formData:{
              name:"",
              category:"",
              blockchain:"Tron",
              headline:"",
              website_link:"",
              telegram_url:"",
              contract_id :"",
              email:"",
              logo:"",
              verify_register:"",
              termsandCondi:false
             }
          })
        }else if(res.data.status==='0'){
          this.setState({
            loading:false,
            errors: {
              resErr : [res.data.message],
            },
            formData:{
              name:"",
              category:"",
              blockchain:"Tron",
              headline:"",
              website_link:"",
              telegram_url:"",
              contract_id :"",
              email:"",
              logo:"",
              verify_register:"",
              termsandCondi:false
             }
          })
        }
      }
    })
    .catch(err=>{
     if(typeof err.response !== 'undefined'){ 
      console.log(err.response.data)
      let err_data = [];
      if(err.response.data.errors.hasOwnProperty('contract_id')){
        err_data.push(err.response.data.errors.contract_id[0])
       }

       if(err.response.data.errors.hasOwnProperty('website_link')){
        err_data.push(err.response.data.errors.website_link[0]);
       }
        if(err_data.length > 0){
            this.setState({
              ...this.state,
              loading:false,
              errors: {
                resErr : err_data
              }
            },()=>{
              console.log(this.state.errors)
            })
        } 
      }
     })
  }

  render(){
    return(
     <React.Fragment>
       {!this.state.loading ?
       (
        <main>
            <div id="breadcrumb">
              <div className="container">
                <ul>
                  <li><a href="/">Home</a></li>
                  <li>Request</li>
                </ul>
              </div>
            </div>
          		<div className="container margin_60_35">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="box_general_3 write_review">
                      <h1>Submit Audit</h1>
                      {this.state.success ? (<div className="alert alert-success">Audit Sent Successfully</div>):('')}
                {this.state.errors.resErr ? (<div className="alert alert-warning">
                <ul style={{marginBottom:'0'}}>
                  {this.state.errors.resErr.map(err=>{
                    return <li>{err}</li>
                  })}</ul></div>)
                  :('')}
                  <div id="message-register"></div>
                  <form encType="multipart/form-data" id="requestForm" onSubmit={this.handleOnSubmit}>
                    <div className="row">
                      <div className="col-md-12 ">
                        <div className={this.state.errors.name ? "form-group has-error" : "form-group"}>
                          <input type="text" className="form-control" placeholder="Name" name="name" id="name_register" onChange={this.handleChange} value={this.state.formData.name}/>
                          {this.state.errors.name ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.name}</span>) :''}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className={this.state.errors.category ? "form-group has-error" : "form-group"}>
                            <select name="category" className="form-control" onChange={this.handleChange} value={this.state.formData.category}>
                              <option value="">--Select Category--</option>
                              <option value="High-Risk">High-Risk</option>
                              <option value="ROI Apps">ROI Apps</option>
                              <option value="Farming Apps">Farming Apps</option>
                              <option value="Other Apps">Other Apps</option>
                           </select>
                          {this.state.errors.category ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.category}</span>) :''}
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select name="blockchain" className="form-control" onChange={this.handleChange}>
                           <option value="Tron">Tron</option>
                           <option value="Ethereum">Ethereum</option>
                           <option value="Binance">Binance</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      {/* <div className="col-md-6">
                        <div className="form-group">
                        <input type="text" required="" name="headline" className="form-control" placeholder="Headline" onChange={this.handleChange}/>
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className={this.state.errors.website_link ? "form-group has-error" : "form-group"}>
                         <input type="text" value={this.state.formData.website_link} required="" name="website_link" className="form-control" placeholder="Website URL" onChange={this.handleChange}/>
                           {this.state.errors.website_link ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.website_link}</span>) :''}
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                            <input type="text" required="" name="telegram_url" className="form-control" placeholder="Telegram Id" onChange={this.handleChange}/>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className={this.state.errors.contract_id ? "form-group has-error" : "form-group"}>
                           <input type="text" required="" value={this.state.formData.contract_id} name="contract_id" className="form-control" placeholder="Contract Id be like 0Xb08E657B0da89f2D7235d56f04A4ac3fEd597d2" onChange={this.handleChange}/>
                           {this.state.errors.contract_id ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.contract_id}</span>) :''}
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className={this.state.errors.email ? "form-group has-error" : "form-group"}>
                          <div className="form-group">
                            <input type="text" required="" value={this.state.formData.email} name="email" className="form-control" placeholder="Enter Email" onChange={this.handleChange}/>
                            {this.state.errors.email ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.email}</span>) :''}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className={this.state.errors.logo ? "form-group has-error" : "form-group"}>
                          <input type="file" id="logo" name="logo" className="form-control" onChange={this.handleFileInput}/>
                          {this.state.errors.logo ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.logo}</span>) :''}
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className={this.state.errors.verify_register ? "form-group has-error" : "form-group"}>
                          <input type="text" id="verify_register" name="verify_register" value={this.state.formData.verify_register} className="form-control" placeholder={`Human verify: ${this.state.sumA} + ${this.state.sumB} = ?`} onChange={this.handleChange}/>
                          {this.state.errors.verify_register ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.verify_register}</span>) :''}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="checkboxes add_bottom_30 add_top_15">
                            <label className="container_check">I accept <a href="/faq#terms">terms and conditions and general policy</a>
                              <input type="checkbox" name="termsandCondi" checked={this.state.formData.termsandCondi} onChange={this.handleChange}/>
                              <span className="checkmark"></span>
                            </label>
                             {this.state.errors.termsandCondi ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.termsandCondi}</span>) :''}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div><input type="submit" className="btn_1" value="Submit" id="submit-register"/></div>
                    </form>
                    </div>
                  </div>
                </div>
              </div>
        </main>
       ) : (<Loader/>)}
     </React.Fragment>
    )
  }
}

export default Request