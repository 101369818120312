import React from 'react';

class EnergyBalance extends React.Component{
    render(){
        return(
          <React.Fragment>
            {this.props.data.accountBalance !== null ?  
               <div className="row">
            <div className="col-md-4">
                <a href="#0" className="balance"><span className="pe-7s-cash"></span></a>
                
                <div className="strip_list wow fadeIn animated text-left">
                    <strong>{this.props.data.receiver_address}</strong><br/><br/>
                    Available TRX: <strong> {Math.round(this.props.data.accountBalance)} TRX</strong><br/><br/>
                    Frozen TRX: <strong> {Math.round(this.props.data.frostTrx)} TRX</strong><br/><br/>
                    Total TRX: <strong>{Math.round(this.props.data.accountBalance)+Math.round(this.props.data.frostTrx)} TRX</strong>
                </div>
            </div>
            <div className="col-md-4">
                <a href="#0" className="balance"><span className="pe-7s-gleam"></span></a>
                <div className="strip_list wow fadeIn animated text-left">
                    Energy: <strong>{isNaN(this.props.data.accountEnergyRemain) ? 0 : this.props.data.accountEnergyRemain} / {this.props.data.accountEnergyLimit ? this.props.data.accountEnergyLimit : 0 } </strong><br/><br/>
                    Frozen: <strong>{this.props.data.totalFrostTrx} TRX</strong><br/><br/>
                    Sent: <strong>{this.props.data.energySent} TRX</strong><br/><br/>
                    Received: <strong>{this.props.data.energyReceive} TRX</strong>
                </div>
            </div>
            <div className="col-md-4">
                <a href="#0" className="balance"><span className="pe-7s-battery"></span></a>
                <div className="strip_list wow fadeIn animated text-left">
                    Bandwidth: <strong>{isNaN(this.props.data.accountBandwithRemain) ? 0 : this.props.data.accountBandwithRemain} / {isNaN(this.props.data.accountBandwithLimit) ? 0 : this.props.data.accountBandwithLimit} </strong><br/><br/>
                    Frozen: <strong>{this.props.data.totalFrostBandwidth} TRX</strong><br/><br/>
                    Sent: <strong>{this.props.data.bandwithSent} TRX</strong><br/><br/>
                    Received: <strong>{this.props.data.bandwithReceive} TRX</strong>
                </div>
            </div>
         </div> : (
              <div className="row">
                  <div className="col-md-12">
                    <div className="strip_list wow fadeIn animated text-left">
                    <strong>Loading ...</strong>
                    </div>
                  </div>
              </div>
         ) }
          </React.Fragment>           
        )  
    }
}

export default EnergyBalance