import React from 'react';
import axios from 'axios';
import {UrlString} from '../../config/apiRoutes';

class NotFound extends React.Component{
    constructor(props){
        super(props)
        this.state = {
         data:'',
         search_home:'',
         search_btn:'all',
        }
         this.handleSubmit = this.handleSubmit.bind(this);
        }
    
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.search_home.length > 0){
        window.location.href=window.location.origin+'/listing?search='+this.state.search_home;
        }else{
        window.location.href=window.location.origin+'/listing';  
        }	
    }

    componentDidUpdate(nextState,prevState){
     if(this.state.search_home.length===0 && prevState.search_home.length===1){
         console.log('hello')
     }
    }
    
    handleChange=(e)=>{
       let value = e.target.value
       if(value.length===0)return false 
       this.setState({
         [e.target.name] : value,
         data:e.target.value.length===1 || e.target.value.length===2 ? '' : this.state.data 
      },()=>{
          console.log(this.state.data)
          if(value.length > 2){
           axios.post(UrlString.BASE_URL+'/api/open/getAllReports?search_home='+this.state.search_home+'&req='+this.state.search_btn)
           .then(res=>{
               if(res.status===200){
               this.setState({
                   data: res.data.reports,
               })
              }
           })
          }
      })
      
     }
    
    render(){
        //console.log(this.state.data);
        const searchStyle=()=>{
            if(this.state.data!==""){
              return  {width:'100%',overflow:'auto',display:'block'}
            }else{
                return {width:'100%',overflow:'auto',display:'none'}	
            }
        }  
        let link = '';
        return(
        <main>
            <div id="error_page">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-7 col-lg-9">
                            <h2>404 <i className="icon_error-triangle_alt"></i></h2>
                            <p>We're sorry, but the page you were looking for doesn't exist.</p>
                            <form onSubmit={this.handleSearchSubmit} autoComplete="off">
                                <div className="search_bar_error">
                                <input type="text" name="search_home" className="form-control" placeholder="Ex. Contract Name, Contract Id ...." onChange={this.handleChange}/>
                                <input type="submit" className="btn_search" value="Search"/>
                                <div className="dropdown-menu" id="_searchBox" style={searchStyle()}>
                                {this.state.data.length > 0 ? this.state.data.map((ele,key)=>{
                                link = encodeURI(ele.name);
                                link = link.replace(/%20/g, "-")
                                link = link.replace(/--/g, "-")
                                return <React.Fragment><a key={key} className="dropdown-item dropdown-text-none" href={`/view-report/${link}`}>
								     <small>Contract: </small>
									 <div className="d-flex">
										 <div className="truncate-ellipsis" style={{width:'auto',overflow:'hidden'}}>
											 <strong>{ele.name} {ele.contract_id.substr(0,5)}....{ele.contract_id.substr(ele.contract_id.length-5,5)}</strong>
										 </div><span className="ml-1 search-contract"></span>
							        </div></a> 
									{ele.is_scam===0? (<i className="fa fa-check" style={{color:'green',marginTop:'-25px',float:'right',marginRight:'5px'}} aria-hidden="true"></i>):(<i className="fa fa-times" style={{color:'red',marginTop:'-15px',float:'right'}} aria-hidden="true"></i>) }
							        </React.Fragment>
							  })
                                :(
                                    <a href={()=>false} className="dropdown-item dropdown-text-none">No Result Found <div className="d-flex"><div className="truncate-ellipsis" style={{width:'auto'}}><strong></strong></div><span className="ml-1 search-contract"></span></div></a>
                                )}
                                </div>
                                </div>
                           </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        )
    }
}

export default NotFound