import React from 'react';


class About extends React.Component{
  render(){
    return(
     <React.Fragment>
        <main>
		<div id="breadcrumb">
			<div className="container">
				<ul>
					<li><a href="/">Home</a></li>
					<li>About</li>
				</ul>
			</div>
		</div>	
        <div className="bg_color_1">
			<div className="container margin_120_95">
				<div className="main_title">
					<h1>About Defi Audits</h1>
					<p>An independent smart contract audit initiative by community, for community.</p>
				</div>
				<div className="row justify-content-between">
					<div className="col-lg-6">
						<figure className="add_bottom_30">
							<img src="/assets/img/about.jpg" className="img-fluid" alt=""/>
						</figure>
					</div>
					<div className="col-lg-6">
                        <p>We are DeFiAudits - a team of developers who work voluntarily to help audit smart contracts and expose any exit scam code. At the moment we don't charge any fees for normal audits however we will charge Audit tokens for priority audit. We are not certified auditors, and we take no responsibility whatsoever for any of the audits. However we strive to find scam code in those audits.</p>
                        <p>We cannot predict "Pump-and-Dumps", nor any other nefarious action of a contract or project - we can only see what is written within a verified Smart Contract, and provide insight and focus to its functions, exposing anything we recognise to be potentially harmful. You should not rely on these audits as any form of advice or instruction of where, how, what or if to invest in or use any particular Smart Contract or Dapp - The service provided by DeFiAudits is intended to be used in a complimentary fashion to your own diligent research.</p>
                        <p>We will not be held responsible for any losses you incur as a result of using any smart contract audited or reviewed by us. Your investments are your own decision, and we do not provide any guarantees, safety, stability or otherwise honest operation of any smart contract, Dapp or project. We are not financial advisors, and you should not use anything provided by us as any means of financial advice or instruction.</p>
						<p><em>Defi Audits Team</em></p>
					</div>
				</div>
			</div>
		</div>
		
		
		<div className="container margin_120_95">
			<div className="main_title">
				<h2>Our founders & admins</h2>
				<p>Defi Audits could not have been existed if these people wouldn't have supported the initiative, we don't have enough words to thank them.</p>
			</div>
			<div id="staff" className="owl-carousel owl-theme">
      <div class="item">
					<a href="https://t.me/chanaye60">
						<div class="title">
							<h4>cHaN aYe CrYpTo<em>Admin</em></h4>
						</div><img src="/assets/img/chan.jpg" alt=""/>
					</a>
				</div>
        <div className="item">
					<a href="https://t.me/akdeves">
						<div className="title">
							<h4>A K<em>Founder</em></h4>
						</div><img src="/assets/img/ak.jpg" alt=""/>
					</a>
				</div>
				<div className="item">
					<a href="https://t.me/yoyobaseline">
						<div className="title">
							<h4>YoYo Baseline<em>Admin & Co founder</em></h4>
						</div><img src="/assets/img/yoyo.jpg" alt=""/>
					</a>
				</div>
				<div className="item">
					<a href="https://t.me/crypto_trapper">
						<div className="title">
							<h4>Crypto Trapper<em>Admin</em></h4>
						</div><img src="/assets/img/ct.jpg" alt=""/>
					</a>
				</div>

				<div className="item">
					<a href="https://t.me/cryptoinvestors_enthusiastic">
						<div className="title">
							<h4>Crypto Enthusiastic<em>Admin & Co Auditor</em></h4>
						</div><img src="/assets/img/yoyo.jpg" alt=""/>
					</a>
				</div>

				<div className="item">
					<a href="https://t.me/Zobir01">
						<div className="title">
							<h4>Zobir Amini<em>Admin</em></h4>
						</div><img src="/assets/img/z.jpg" alt=""/>
					</a>
				</div>
				
				<div className="item">
					<a href="https://t.me/MrFunction">
						<div className="title">
							<h4>Mr Function<em>Admin & Co Auditor</em></h4>
						</div><img src="/assets/img/mrf.jpg" alt=""/>
					</a>
				</div>
				<div class="item">
                    <a href="https://t.me/@Mulle72">
                        <div class="title">
                            <h4>K M<em>Admin</em></h4>
                        </div><img src="/assets/img/km.jpg" alt=""/>
                    </a>
                </div>

                <div class="item">
                    <a href="https://t.me/ibirawlife">
                        <div class="title">
                            <h4>ibirawlife<em>Admin</em></h4>
                        </div><img src="/assets/img/ibi.jpg" alt=""/>
                    </a>
                </div>
			</div>
		</div>
		
	
		
		<div className="container">
			<div className="main_title">
				<h2>Why Defi Audits</h2>
				<p>Defi Audits are not contract owner focus we are community focused so we would look out for scam code and we would not entertain any scam code in any of the project.</p>
			</div>
			<div className="row">
				<div className="col-lg-4 col-md-6">
					<a className="box_feat_about" href="#0">
						<i className="pe-7s-id"></i>
						<h3>100+ Audits</h3>
						<p>We have successfully completed 100+ audits so far and busted many scammers in the process of doing so. We intend to keep the numbers rolling</p>
					</a>
				</div>
				<div className="col-lg-4 col-md-6">
					<a className="box_feat_about" href="#0">
						<i className="pe-7s-users"></i>
						<h3>Community Support</h3>
						<p>We have premium community where we avoid scammers and people who randomly post links. This community is very disciplined community. </p>
					</a>
				</div>
				<div className="col-lg-4 col-md-6">
					<a className="box_feat_about" href="#0">
						<i className="pe-7s-tools"></i>
						<h3>Technical Support & Discussion</h3>
						<p>In case of anything related to smart contract or blockchain space we as technical people and admins try to educate.</p>
					</a>
				</div>
				<div className="col-lg-4 col-md-6">
					<a className="box_feat_about" href="#0">
						<i className="pe-7s-news-paper"></i>
						<h3>Latest Tech News & Events</h3>
						<p>We tend to keep our community members aware of new things happening in crypto space. So we share latest tech news and events. </p>
					</a>
				</div>
				<div className="col-lg-4 col-md-6">
					<a className="box_feat_about" href="#0">
						<i className="pe-7s-lock"></i>
						<h3>Security Education Announcements</h3>
						<p>From time to time we post security awareness announcement on how to develop habits to safeguard yourself from all hacks.</p>
					</a>
				</div>
				<div className="col-lg-4 col-md-6">
					<a className="box_feat_about" href="#0">
						<i className="pe-7s-light"></i>
						<h3>Innovations</h3>
						<p>We always see problems as opportunity and come up with innovative solution as far as its technical possible for us. </p>
					</a>
				</div>
			</div>
			
		</div>
		
        </main>
     </React.Fragment>
    )
  }
}

export default About