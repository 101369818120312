import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {UrlString} from '../../config/apiRoutes';
import ReactPaginate from 'react-paginate';

class Listing extends React.Component{ 
  
    constructor(props){
      super(props)
        this.state={
          data:{},
          search_text:'notset',
          blockchain:'all',
      sortBy:'latest',
      dataCount:'',
          pagination:{},
      pageNumber:0
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.myRef = React.createRef();
   }
   
  componentDidMount(){
  if(this.props.location.search){   
  const param = new URLSearchParams(this.props.location.search);
  for (let [key,p] of param) {
  if(key==='search'){
    this.setState({
      search_text : p.length > 0 ? p : "notset"
    },()=>{
      this.apiCall(); 
    })
    }
  } 
   }else{
  this.apiCall();
   } 
  } 
  
  apiCall=()=>{
    axios.post(UrlString.BASE_URL+'/api/open/getAllReports',{
     search_text:this.state.search_text,
     type:this.state.blockchain,
   sortBy:this.state.sortBy,
  })
    .then(res=>{
      if(res.status===200){
        this.setState({
          data: res.data.reports.data,
      pagination:res.data,
      dataCount:res.data.reports.total
        },()=>{
      //console.log(this.state)
    })
      }
    })
  }

  handleType=(blockchain)=>{
    this.setState({
    blockchain : blockchain,
    pageNumber:0
    },()=>{
        this.apiCall();
    })
  }

  handlePagination(pageNum){
  //e.preventDefault();
  this.setState({
  ...this.state,
  pageNumber:pageNum-1
   //loading:true
  },()=>{
   axios.post(UrlString.BASE_URL+'/api/open/getAllReports?page='+pageNum+'&search_text='+this.state.search_text+'&type='+this.state.blockchain+'&sortBy='+this.state.sortBy)
   .then(res=>{
     if(res.status===200){
     this.setState({
       data: res.data.reports.data,
       pagination:res.data,
       //loading:false
     },()=>{
      //console.log(this.state);
     })
     }
   })
  })
  }

  handleSubmit(e){
  e.preventDefault();  
  //console.log(e.target);
  var formData = new FormData(e.target);
  let sortBy = '';
  for (var value of formData.entries()) {
     console.log(value);
    sortBy = value[1];
  } 
  
  if(sortBy==="No Exit Scam"){
    sortBy = "no_exit_scam";
  }else if(sortBy==="Top rated"){
        sortBy = "top_rated";
  }else if(sortBy==="Name"){
       sortBy = "name"
  }else if(sortBy==="Blockchain"){
    sortBy = "blockchain"
  }
     this.setState({
     sortBy:sortBy,
     pageNumber:0
   },()=>{
    this.apiCall();
   })
  }

  handleSearchSubmit(e){
  e.preventDefault();
  if(this.myRef.current.value.length){
      window.location.href=window.location.origin+'/listing?search='+this.myRef.current.value;
    }
  } 

  filter(){
      return(
        <React.Fragment>
            <div className="container">
        <ul className="clearfix">
          <li>
            <h6>Type</h6>
            <div className="switch-field">
              <input type="radio" id="all" name="blockchain"  value="all"/>
               <label htmlFor="all" onClick={()=>this.handleType('all')} id={this.state.blockchain==='all'?"checkbox_active":"Inactive"}>All</label>
                            
              <input type="radio" id="tron" name="blockchain" value="Tron"/>
              <label htmlFor="Tron" onClick={()=>this.handleType('Tron')} id={this.state.blockchain==='Tron'?"checkbox_active":"Inactive"}>Tron</label>
              
              <input type="radio" id="ethereum" name="blockchain"  value="Ethereum"/>
              <label htmlFor="Ethereum" onClick={()=>this.handleType('Ethereum')} id={this.state.blockchain==='Ethereum'?"checkbox_active":"Inactive"}>Ethereum</label>

              <input type="radio" id="ethereum" name="binance"  value="Binance"/>
              <label htmlFor="Ethereum" onClick={()=>this.handleType('Binance')} id={this.state.blockchain==='Binance'?"checkbox_active":"Inactive"}>Binance</label>

              <input type="radio" id="matic" name="matic"  value="Matic/Polygon"/>
              <label htmlFor="Ethereum" onClick={()=>this.handleType('Matic/Polygon')} id={this.state.blockchain==='Matic/Polygon'?"checkbox_active":"Inactive"}>Matic/Polygon</label>
            </div>
          </li>
          <li>
            <h6>Sort by</h6>
                          <form onSubmit={this.handleSubmit}> 
                <select name="order" className="selectbox">
                            <option value="latest">Latest</option>
              <option value="no_exit_scam">No Exit Scam</option>
                            <option value="top_rated">Top rated</option>
                            <option value="name">Name</option>
                            {/* <option value="blockchain">Blockchain</option> */}
              </select>
              
              <select id="orderBy" name="orderBy" hidden>
                            <option value="No Exit Scam">No Exit Scam</option>
                            <option value="Top rated">Top rated</option>
                            <option value="Name">Name</option>
                            {/* <option value="Blockchain">Blockchain</option> */}
              </select>
              <button type="submit" id="myorderForm" hidden>submit</button>
             </form>
          </li>
        </ul>
      </div>
        </React.Fragment>  
      )
  }
  
  searchBar(){
  return (
    <React.Fragment>
       <div className="row">
          <div className="col-md-6">
          {Object.keys(this.state.pagination).length > 0  ?  
            (<h4><strong>Showing {this.state.pagination.reports.from}</strong> of {this.state.pagination.reports.to} results</h4>)
            :
            (<h4><strong>Showing 0</strong> of 0 results</h4>)
          }   
          </div>
          <div className="col-md-6">
            <div className="search_bar_list">
             <form onSubmit={this.handleSearchSubmit}>
              <input type="text" className="form-control" ref={this.myRef} placeholder="Ex. Contract Name, Contract Id..."/>
              <input type="submit" value="Search"/>
            </form> 
            </div>
          </div>
        </div>
    </React.Fragment>
  )
}

ratings(rate){
  var x = Number(rate)% 1===0 ? true : false
  let ele = [];
  let remStar = Math.round(5-rate);
  remStar = (x===false)?remStar-1:remStar

  const loop=()=>{
    for(var i=1;i<=rate;i++){
      ele.push(<i key={Math.random()} className="icon_star voted"></i>)
    }
    if(!x){
      ele.push(<i  key={Math.random()} className="icon_star-half_alt voted"></i>)
    }
    
    for(var j=1;j<=remStar;j++){
      ele.push(<i key={Math.random()} className="icon_star"></i>)
    }
    
       return ele
  }


  return (
    <React.Fragment>
       <span className="rating">
         {loop()}
      </span>
    </React.Fragment>
  )
  }

  render(){

  let link = '';  
    return(
      <main>
    <div id="results">
      <div className="container">
        {this.searchBar()}
      </div>
    </div>
    
        <div className="filters_listing">
     {this.filter()}  
    </div>
    
    <div className="container margin_60_35">
      <div className="row">
        <div className="col-lg-12">

            {this.state.data.length > 0 ? this.state.data.map((ele,key)=> 
          {
          link = encodeURI(ele.name);
          link = link.replace(/%20/g, "-")
          link = link.replace(/--/g, "-")
          return <div className="strip_list wow fadeIn" key={key}>
            <a href={()=>false} className={ele.is_scam===0?"tick_bt":ele.is_scam===1?"untick_bt":ele.is_scam===4|| ele.is_scam===2 || ele.is_scam===3 ?"untick_bt_heavy":"untick_bt_unverified"}> </a>
            <figure className="res_img">
              <a href={`/view-report/${link}`}><img src={ele.logo.length > 0 ? `${UrlString.BASE_URL}${ele.logo}`:'/assets/images/defi-channel.jpg'} referrerPolicy="no-referrer" alt=""/></a>
            </figure>
            <div className="res_img_act">
              <a href={`/view-report/${link}`}><img src={ele.logo.length > 0 ? `${UrlString.BASE_URL}${ele.logo}`:'/assets/images/defi-channel.jpg'} referrerPolicy="no-referrer" alt=""/></a>
            </div>
          <small>{ele.category}</small>
          <h3><a href={`/view-report/`+link}>{ele.name}</a></h3>
          <p>{ele.report_synposis !==null ? ele.report_synposis.length > 147 ? `${ele.report_synposis.substring(0, 147)}...` : ele.report_synposis:''}</p>
            {this.ratings(ele.rating)}
            <a href={()=>false} data-toggle="tooltip" data-placement="top" data-original-title="Badge Level" className="badge_list_1">
            {ele.is_scam===3 || ele.is_scam===2 || ele.is_scam===4 ? 
            (<img src="/assets/img/badges/badge_1.svg" width="15" height="15" alt=""/>): (ele.is_scam===1?(<img src="/assets/img/badges/badge_5.svg" width="15" height="15" alt=""/>):(<img src="/assets/img/badges/badge_6.svg" width="15" height="15" alt=""/>))
            }
            </a>
            <ul>
            <li><a href={()=>false} className="btn_listing"><img src={ele.blockchain==='Tron'?`/assets/images/tron.png`:(ele.blockchain==='Ethereum'?`/assets/images/eth.png`: (ele.blockchain==='Binance'?`/assets/images/bin.png`:''))} alt=""/></a></li>
            <li><a href={ele.website_link} target="_blank" rel="noopener noreferrer">View Website</a></li>    
             <li><a href={`/view-report/${link}`}>View Detail</a></li>
             </ul>
          </div>
          }     
                    ): this.state.dataCount===0? (
            <div className="strip_list wow fadeIn" >
              <h3>No Data Found</h3>
            </div> 
            ): 
            (
            <div className="strip_list wow fadeIn" >
              <h3>Loading ...</h3>
            </div> 
            ) 
          }

        {Object.keys(this.state.pagination).length > 0  ? 
          this.state.pagination.reports.last_page !==1 ?
          (
          <nav aria-label="" className="add_top_20">
            <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={this.state.pagination.reports.last_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={(e)=>this.handlePagination(e.selected+1)}
            containerClassName={'pagination pagination-sm'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousLinkClassName={'page-item'}
            nextLinkClassName={'page-item'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            forcePage={this.state.pageNumber}
            />
          </nav>
          ) : ('') : ('') }
          </div>
      </div>
    </div>
    </main>
    )
  }
}

export default withRouter(Listing);