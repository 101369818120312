import React from 'react';

class BuyEnergy extends React.Component{
  
 handleButton(){
  this.props.callback()
 }   
  render(){
    return(
        <div id="results">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4><strong>Tron Energy Exchange</strong></h4>
                    </div>
                    <div className="col-md-6">
                        <div className="" style={{textAlign:'right'}}>
                            <input className="btn_1" type="submit" value="Buy Energy" onClick={()=>this.handleButton()}/>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )  
  }
}

export default BuyEnergy