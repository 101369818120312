import React from 'react';
import axios from 'axios';
import {UrlString,TronConfig} from '../../config/apiRoutes';
import {donatePopup} from '../../config/TrxMessage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loader from '../SharedModule/Loader';

const MySwal = withReactContent(Swal)

class VoteRewards extends React.Component{
  constructor(props) {
   super(props)
   this.state = {
    loading: true,
    type : '',
    order : 'asc',
    votingList:[],
    timer : null,
    assumedFreezeValue: 10000
   }
  }

  async componentDidMount(){
     this.getVoteWitness();
     this.SetTimeUntilNextCycle();
  }

  async SetTimeUntilNextCycle(){
    let res = await axios('https://apilist.tronscan.org/api/vote/next-cycle');
     if(Object.keys(res).length > 0){

      this.ConvertToCounter(res.data.nextCycle)
      var ms = parseFloat(res.data.nextCycle);
      var self = this
      var x = setInterval(function () {
        self.ConvertToCounter(ms);
        ms = ms - 1000;
        if (ms <= 0) {
            clearInterval(x);
         }
        }, 1000);
     }
  }

ConvertToCounter(ms) {
    var daysms = ms % (24 * 60 * 60 * 1000);

    var hours = ('0' + Math.floor((daysms) / (60 * 60 * 1000))).slice(-2);
    var hoursms = ms % (60 * 60 * 1000);

    var minutes = ('0' + Math.floor((hoursms) / (60 * 1000))).slice(-2);
    var minutesms = ms % (60 * 1000);

    var sec = ('0' + Math.floor((minutesms) / (1000))).slice(-2);

    var timetext = hours + "h : " + minutes + "m : " + sec + "s";

    this.setState({
      timer : timetext
    })
    
 }

  async getVoteWitness(){
    let votingList = [];
    const list = await axios(TronConfig.voteApi);
     if(Object.keys(list).length > 0){
      if(list.data.data.length > 0){
        let res = await list.data.data;
        let i = 0;
        res.map(ele=>{
         if(ele.brokerage!==100 && i!==27 && ele.witnessType === 1){
           i++
           votingList.push(ele)
         }
        })
        this.setState({
         votingList : votingList,
        })
       }
     }
    }
  
    rewardCalculation(brokerage,lastCycleVotes,votesPercentage){
      var assumedFreezeValue = this.state.assumedFreezeValue;
      var voteSharing = (100-brokerage)/100
      var lastCycleVotes = lastCycleVotes
      var totalVoteSharePercentage = (votesPercentage/100)
      var lastReward = ((assumedFreezeValue*4608000/(lastCycleVotes/totalVoteSharePercentage))*voteSharing) + ((460800/27)*voteSharing)*(assumedFreezeValue/lastCycleVotes)
      return (lastReward).toFixed(2);
    }

    lastCycle(brokerage,lastCycleVotes,votesPercentage){
      var assumedFreezeValue = this.state.assumedFreezeValue;
      var voteSharing = (100-brokerage)/100
      var lastCycleVotes = lastCycleVotes
      var totalVoteSharePercentage = (votesPercentage/100)
      var lastReward = ((assumedFreezeValue*4608000/(lastCycleVotes/totalVoteSharePercentage))*voteSharing) + ((460800/27)*voteSharing)*(assumedFreezeValue/lastCycleVotes)
      return (lastReward/4).toFixed(2);
    }

    NumWithComma=(x)=>{
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    donateBtn(e){
      donatePopup(e)
    }

    changeAssumeFreezeAmount(e){
      e.preventDefault();
      MySwal.fire({
        title : 'Reward By',
        html : `Votes (TRX): <input type="text" class="form-control" id="voter_value" />`,showCancelButton: true,
        cancelButtonColor: 'grey',
        confirmButtonText: 'Show Reward',
        allowOutsideClick: false,
        showCancelButton: false,
        allowOutsideClick: true,
        preConfirm: () => (
         this.setState({
           assumedFreezeValue : document.getElementById("voter_value").value
         })
        )
      })
    }
   

    sort_by(type,order){
       if(this.state.votingList.length > 0){
        if(order==='asc'){
          if(type==='reward'){
            this.state.votingList.sort(function(a,b){
              return parseFloat(parseFloat(a['lastCycleVotes'])) - parseFloat(parseFloat(b['lastCycleVotes']));
             }) 
          }
           this.state.votingList.sort(function(a,b){
            return parseFloat(parseFloat(a[`${type}`])) - parseFloat(parseFloat(b[`${type}`]));
           })   
         
          }else if(order==='desc'){
          this.state.votingList.reverse();
         } 
         
         
         this.setState({
           order : (order==='asc'? 'desc' : 'asc'),
           type : type
         },()=>{
           console.log(this.state.order)
         })
       }
    }


  render(){
      return(
        <React.Fragment>
           <main>
           <div id="breadcrumb">
            <div className="container">
              <ul>
                <li><a href="/">Home</a></li>
                <li>Vote Rewards</li>
              </ul>
            </div>
          </div>

            <div className="bg_color_1">
                <div className="container margin_120_95">
                  <div className="main_title">
                      <h1>TRX VOTING REWARDS CALCULATOR</h1>
                      <p>Rewards change in</p>
                      <h2>{this.state.timer}</h2>
                  </div>
                  <div className="container margin_60_35">
                      <div className="row">
                        <div className="col-lg-12">
                           <div style={{marginBottom:'10px'}}>
                             <input type="button" value="Donate" className="btn_1" onClick={(e)=>this.donateBtn(e)}/>
                             <div className="btn_1" style={{float:'right'}}>Your Votes {this.NumWithComma(this.state.assumedFreezeValue)} (TRX) <a href="#" style={{color:'black'}} onClick={(e)=>this.changeAssumeFreezeAmount(e)}>Change</a></div>
                           </div>
                        </div>
                        <div className="col-lg-12">
                           <div style={{right:'12px',position:'absolute',zIndex:'10'}}> 
                             <div className="btn_1" onClick={()=>{this.sort_by('reward',this.state.order)}}><div>Last Cycle</div><div className="sort-filter_1"><i style={(this.state.type==='reward' && this.state.order==='desc') ? {color:'black'} : {}} className=" fa fa-caret-up"></i>  <i style={(this.state.type==='reward' && this.state.order==='asc') ? {color:'black'} : {}} className=" fa fa-caret-down"></i></div></div>
                             <div className="btn_1" style={{marginLeft:'5px'}} onClick={()=>{this.sort_by('realTimeRanking',this.state.order)}}><div>Current Ranking</div><div className="sort-filter"><i style={(this.state.type==='realTimeRanking' && this.state.order==='desc') ? {color:'black'} : {}} className=" fa fa-caret-up"></i>  <i style={(this.state.type==='realTimeRanking' && this.state.order==='asc') ? {color:'black'} : {}} className=" fa fa-caret-down"></i></div></div>
                             <div className="btn_1" style={{marginLeft:'5px'}} onClick={()=>{this.sort_by('annualizedRate',this.state.order)}}><div>APY</div><div className="sort-filter_3"><i style={(this.state.type==='annualizedRate' && this.state.order==='desc') ? {color:'black'} : {}} className=" fa fa-caret-up"></i>  <i style={(this.state.type==='annualizedRate' && this.state.order==='asc') ? {color:'black'} : {}} className=" fa fa-caret-down"></i></div></div>
                          </div>
                          <div style={{marginTop:'4em'}}> 
                          {this.state.votingList.length > 0 ? this.state.votingList.map((ele,key)=>
                              <div class="strip_list wow fadeIn" id="voting_list">
                              <figure>
                                  <a href="detail-page.html"><img src={'/assets/images/defi-channel.jpg'} alt=""/></a>
                              </figure>
                              <small>TRX Reward Sharing : {100 - ele.brokerage}%</small>
                              <h3 style={{whiteSpace:'nowrap',width:'250px',overflow:'hidden',textOverflow:'ellipsis'}}>{ele.name}</h3>
                              <p>Vote Rewards</p>
                              <h6 style={{float:'right',marginTop:'-64px'}}> Votes {this.NumWithComma(ele.lastCycleVotes)} <small>({ele.votesPercentage.toFixed(2)}%)</small></h6>
                              <ul>
                                  <li><a href="#0"  class="btn_listing">Last 4 Cycles(1 Day)</a>&nbsp;&nbsp;
                                    <strong>
                                     {this.rewardCalculation(ele.brokerage,ele.lastCycleVotes,ele.votesPercentage)}
                                    </strong>
                                  </li>
                                  <li><a href="#0"  class="btn_listing">Last Cycle</a>&nbsp;&nbsp;
                                     <strong>
                                     {this.lastCycle(ele.brokerage,ele.lastCycleVotes,ele.votesPercentage)}
                                     </strong> 
                                  </li>
                                  <li><a href="#0"  class="btn_listing">Current Cycle (Live votes)</a>&nbsp;&nbsp;
                                    <strong>
                                     {this.lastCycle(ele.brokerage,ele.lastCycleVotes,ele.votesPercentage)}
                                     </strong> 
                                   </li> 
                                   <li><a href="#0"  class="btn_listing">Current Ranking</a>&nbsp;&nbsp;
                                    <strong>
                                     {ele.realTimeRanking}
                                     </strong> 
                                   </li>
                                   <li><a href="#0"  class="btn_listing">APY</a>&nbsp;&nbsp;
                                    <strong>
                                     {parseFloat(ele.annualizedRate).toFixed(2)} %
                                     </strong> 
                                   </li>  
                                  {
                                    ele.hasPage ?
                                    (
                                      <li><a href={ele.url}  class="btn_listing">Team Info</a></li>
                                    ) : 
                                    (
                                      <li></li>
                                    )
                                  }
                              </ul>
                            </div> ) :(<Loader/>) }
                           </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </main>
        </React.Fragment>
      )
  }
}


export default VoteRewards