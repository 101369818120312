export const UrlString = {

  BASE_URL : 'https://admin.defiaudits.io/',
  //BASE_URL : 'https://test4.pktapps.com/',

   //BASE_URL : 'http://localhost:8000/',
}

export const ApiRoutes = {
  ENERGY_STORE : `${UrlString.BASE_URL}api/open/exchange/store`,
  PUT_FLAG : `${UrlString.BASE_URL}api/open/exchange/sellEnergyUserFlag`,
  GET_ACCOUNT_RESOURCE : `${UrlString.BASE_URL}api/open/exchange/get_account_resources`,
  GET_ACCOUNT : `${UrlString.BASE_URL}api/open/exchange/getAccount`,
  GET_ORDERS : `${UrlString.BASE_URL}api/open/exchange/getOrder`,
  GET_RECENT_ORDERS : `${UrlString.BASE_URL}api/open/exchange/CompletedOrders`,
  USER_ORDERS : `${UrlString.BASE_URL}api/open/exchange/UserOrders`,
  User_Completed_Orders : `${UrlString.BASE_URL}api/open/exchange/UserCompletedOrders`,
  CANCEL_ORDER : `${UrlString.BASE_URL}api/open/exchange/cancelOrder`,
  SETTINGS : `${UrlString.BASE_URL}api/open/exchange/settings`,
}

export const TronConfig={
  TRONGRID_API : "https://api.trongrid.io",
  NODE:'PRODUCTION',
  //NODE:'DEVELOPMENT',
  //TRONGRID_API : "https://api.shasta.trongrid.io",
  ContractAddress : "TUrEhQpDby6Qo2roexecu4v6VNFiiN35Q5",  /* Audit token address */
  FOUNDATION_ADDRESS : "TUrEhQpDby6Qo2roexecu4v6VNFiiN35Q5", /* Audit token address */
  //fullHost: 'https://api.shasta.trongrid.io',
  fullHost: 'https://api.trongrid.io',
  fee_limit : 300000000,
  callValue: 0,
  privateKey: 'b91ddbb8ea1af57e6d4f585cbe4f7dcdf7147beb020206030743301464dcbb81',
  EtheapiKey:'VTNE4YPDDDAESWBVBN5KCI17WPF17R9N1I',
  PolyapiKey : 'W7X2KTGHAKWV8QKHITQ968M4MDC8UGYTP7',
  ExchangeContractAddress:'TYGWGHYbHu74dpXQUqxjqAkVFhMFKWDDXi',  /* address for exchange energy */
  // voteApi : 'https://shastapi.tronscan.org/api/vote/witness',
  // votedAddress : 'https://shastapi.tronscan.org/api/account/votes?address=',
  // tronscanOrgApi : 'https://shastapi.tronscan.org',
  // transactionUrl : 'https://shasta.tronscan.org',
  transactionUrl : 'https://tronscan.org',
  tronscanOrgApi : 'https://apiasia.tronscan.io:5566',
  voteApi : 'https://apilist.tronscan.org/api/vote/witness',
  votedAddress : 'https://apilist.tronscan.org/api/account/votes?address=',
  referralAddress : 'THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn',
  TelegramBotToken : '1892678443:AAEoLZA-qkMLWQqaqSJFStKcEBWydMtKnX8'
}