import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Notify extends React.Component{
  
  componentDidMount(){
    this.notification() 
  }
  componentDidUpdate(){
   this.notification()  
  }
  
  notification(){
    if(this.props.attr.type==='success' && this.props.attr.visible){
      toast.success(this.props.attr.msg,{autoClose:5000});
    }else if(this.props.attr.type==='warning' && this.props.attr.visible){
      toast.warn(this.props.attr.msg,{autoClose:5000});
    }else{
      toast(this.props.attr.msg,{autoClose:5000});
    }
  }

  render(){
    return(
      <React.Fragment>
         <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          limit={1}
          pauseOnHover/>
      </React.Fragment>
    )
  } 
}

export default Notify