import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import Home from './Component/UserModule/Home';
import About from './Component/UserModule/About';
import Header from './Component/SharedModule/Header';
import Footer from './Component/SharedModule/Footer';
import ViewReport from './Component/UserModule/ViewReport';
import PendingReport from './Component/UserModule/PendingReport';
import Request from './Component/UserModule/Request';
import PriorityRequest from './Component/UserModule/PriorityRequest';
import {TronConfig} from './config/apiRoutes'
import Utils from './config/util'
import TronWeb from "tronweb"
import Notify from './Component/SharedModule/Notify';
import Auction from './Component/UserModule/Auction';
import Listing from './Component/UserModule/Listing';
import NotFound from './Component/UserModule/NotFound';
import ContactUs from './Component/UserModule/ContactUs';
import BuyToken from './Component/UserModule/BuyToken';
import Faq from './Component/UserModule/Faq';
import Verified from './Component/UserModule/Verified';
// import Exchange from './Component/UserModule/Exchange';
// import BuyEnergy from './Component/UserModule/BuyEnergy';
import Energy from './Component/UserModule/Energy';
import VoteRewards from './Component/UserModule/VoteRewards';

class App extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      tronWeb: {
        installed: false,
        loggedIn: false
      },
      subscribe:'',
      notify:{},
      address:''
    };
  }

  async componentDidMount() {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("balance");

    let self =this;
        window.addEventListener('message', function (e) {
     
              // if (e.data.message && e.data.message.action == "tabReply") {
              //     console.log("tabReply event", e.data.message)
              // }
      
              if (e.data.message && e.data.message.action === "setAccount") {
                  //console.log("setAccount event", e.data.message)
                  //console.log("current address:", e.data.message.data.address)
                  if(e.data.message.data.address !== false){
                    self.isSubscription();
                  }else if(e.data.message.data.address === false){
                    window.location.href=window.location.origin
                  }
              }
              
           })

    try{ 
     await new Promise(resolve => {
        const tronWebState = {
          installed: !!window.tronWeb,
          loggedIn: window.tronWeb && window.tronWeb.ready
        };
        if (tronWebState.installed) {
          this.setState({
            tronWeb: tronWebState
          });

          return resolve();
        }

        let tries = 0;
        const timer = setInterval(() => {
          if (2e4 <= tries) {
            window.tronWeb = new TronWeb(
              TronConfig.fullHost,
              TronConfig.privateKey
            );
            this.setState({
              tronWeb: tronWebState
            });
            clearInterval(timer);
            return resolve();
          }
          

          tronWebState.installed = !!window.tronWeb;
          tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
          
          if (!tronWebState.installed) {
            return tries++;
          }
        
          this.setState({
            tronWeb: tronWebState
          },()=>{
            localStorage.setItem('loggedIn',tronWebState.loggedIn);
          });

          return resolve();
        },100);
      });
       // Set default address (foundation address) used for contract calls
       // Directly overwrites the address object if TronLink disabled the
       // function call
       if(!this.state.tronWeb.loggedIn) {  
        window.tronWeb.defaultAddress = {
          hex: window.tronWeb.address.toHex(TronConfig.FOUNDATION_ADDRESS),
          base58: TronConfig.FOUNDATION_ADDRESS
        };
        
        window.tronWeb.on('addressChanged', () => {
          if(this.state.tronWeb.loggedIn)
              return;

          this.setState({
              tronWeb: {
                  installed: true,
                  loggedIn: true
              }
          },()=>{
            console.log('login');
          });
      });
    }

      await Utils.setTronWeb(window.tronWeb);
      this.isSubscription();
    }catch(e){
      console.log(e);
    } 
  }

  componentDidUpdate(prevProps,prevState){
    if((this.state.address!==prevState.address) && (prevState.address!=='') && (this.state.address!=='')){
      //window.location.href=window.location.origin
      window.location.reload();
    }
  }
  
   async isSubscription() {
    const trondata = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS)
    const walletAdd=trondata.tronWeb.defaultAddress.base58;
    //console.log(walletAdd);
    try{
      this.setState({
         ...this.state,
         address:walletAdd
        },()=>{
          console.log(this.state)
        })
    }catch(error){
      //console.error(error)  
    }
  }

  render(){
     return (
      <React.Fragment>
          <BrowserRouter>
            <Header/>
          <div> 
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/view-report/:id" component={ViewReport} />
                {/* <Route path="/audit-report" component={AuditReport} /> */}
                <Route path="/pendinglist" component={PendingReport} />
                <Route path="/request" component={Request} />
                <Route path="/priority-request" component={PriorityRequest} />
                <Route path="/auction" component={Auction} />
                <Route path="/buytoken" component={BuyToken}/>
                <Route path="/verify" component={Verified} />
                <Route path="/listing" component={Listing} />
                <Route path="/contact" component={ContactUs} />
                <Route path="/faqs" component={Faq} />
                {/* <Route path="/exchange" component={Exchange} /> */}
                {/* <Route path="/buyenergy" component={BuyEnergy} /> */}
                <Route path="/energy" component={Energy} />
                <Route path="/voterewards" component={VoteRewards} />
                <Route component={NotFound}/>
                <Route path="/404" component={NotFound}/>
            </Switch>
           </div>   
            <Footer/>
          </BrowserRouter>
          {this.state.notify.visible?(<Notify attr={this.state.notify}/>):('')}
      </React.Fragment>
    );
  }
}


export default App;
