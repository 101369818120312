import React from 'react';
import axios from 'axios';
import {UrlString} from '../../config/apiRoutes';
import Loader from '../SharedModule/Loader';
import Notify from '../SharedModule/Notify'


class ContactUs extends React.Component{
    constructor(props){
        super(props)
        this.state={
        formData:{
            name_contact:"",
            telegram_id:"",
            email_contact:"",
            inquiry:"",
            message_contact:"",
            verify_contact:""
        },
        notify:{},
        errors:{}, 
        success:false,
        loading:false,
        sumA:Math.floor(Math.random() * 10),
        sumB:Math.floor(Math.random() * 10)
        }
        this.handleOnSubmit = this.handleOnSubmit.bind(this)
    }
  
    handleChange=e=>{
        this.setState({
         notify:{}, 
         formData:{
           ...this.state.formData,
           [e.target.name]:e.target.value
         }
         },()=>{
          console.log(this.state);
        })
       }
       
       handleValidation(){
         if(this.state.formData.name_contact.length===0){
            this.setState({
             notify:{},
              errors:{
                name_contact:'Name is required'
             }
           })
           return false;
         };
        
         if(this.state.formData.telegram_id.length===0){
            this.setState({
              notify:{},
              errors:{
                telegram_id:'Telegram id is required'
              }
            })
            return false;
          };

          let email_pattern = /([^.@\s]+)(.[^.@\s]+)*@([^.@\s]+.)+([^.@\s]+)/g;
          if(this.state.formData.email_contact.length===0){
            
            this.setState({
              notify:{},
              errors:{
                email_contact:'Email is requied'
              }
            })
            return false;
          }

          if(email_pattern.test(this.state.formData.email_contact)===false){
            this.setState({
             notify:{},
              errors:{
                email_contact:'Enter Valid Email Address'
             }
           })
           return false;
         };

          if(this.state.formData.message_contact.length===0){
            this.setState({
              notify:{},
              errors:{
                message_contact:'Query is required'
              }
            })
            return false;
          };
         
     
         if(this.state.formData.verify_contact.length===0){
           this.setState({
            notify:{}, 
            errors:{
                verify_contact:'Please verify human verification'
             }
           })
           return false;
         };
         
         if(parseInt(this.state.formData.verify_contact)!==this.state.sumA+this.state.sumB){
           this.setState({
            notify:{}, 
            errors:{
               verify_contact:'Human verification failed'
             }
           })
           return false;
         };
     
         this.setState({
           notify:{}, 
           errors:{}
         })
       }
     
       handleOnSubmit(e){
         e.preventDefault();
         
         if(this.handleValidation()===false)return false; 
         this.setState({
           ...this.state,
           loading:true
         })
         const formData  = new FormData();
         formData.append('name_contact',this.state.formData.name_contact)
         formData.append('telegram_id',this.state.formData.telegram_id)
         formData.append('email_contact',this.state.formData.email_contact)
         formData.append('inquiry',this.state.formData.inquiry)
         formData.append('message_contact',this.state.formData.message_contact)
         console.log(formData)
         axios.post(UrlString.BASE_URL+'/api/open/ContactUs',formData)
         .then(res=>{
           if(res.status===200){
             console.log(res.data)
             if(res.data.status==='1'){
               this.setState({
                 success:true,
                 loading:false,
                 errors:{},
                 formData:{
                  name_contact:"",
                  telegram_id:"",
                  email_contact:"",
                  inquiry:"",
                  message_contact:"",
                  verify_contact:""
                 },
                 notify:{
                   visible:true,
                   type:'success',
                   msg:'Mail Successfully Sent'
                 }
               },()=>{
                 document.getElementById("contactform").reset();
               })
             }
           }
         })
         .catch(err=>{
            console.log(err)
          })
       }

  render(){
    return(
       <React.Fragment> 
       {!this.state.loading ?
        (
        <main>
		<div id="hero_register">
		   <div className="container margin_120_95"><h1>Contact Defi Audits</h1></div>
	   </div>
		<div className="container margin_60_35">
			<div className="row">
				<aside className="col-lg-3 col-md-4">
					<div id="contact_info">
						<h3>Contacts info</h3>
						<p>
							<br/><a href="https://t.me/defiaudit">Telegram Channel</a>
							<a href={()=>false}>info@defiaudits.io</a>
						</p>
						
						<ul>
							<li><strong>General Inquiries</strong>
								<a href="tel://003823932342">Telegram</a><br/><a href={()=>false}>info@defiaudits.io</a><br/>
								<small>Responds within 3 - 5 working days</small>
							</li>
							<li><strong>Premium Group Inquiries</strong>
								<a href="tel://003823932342">Telegram</a><br/><a href={()=>false}>premium@defiaudits.io</a><br/>
								<p><small>Responds within 3 - 5 working days</small></p>
							</li>
						</ul>
					</div>
				</aside>
				<div className=" col-lg-8 col-md-8 ml-auto">
					<div className="box_general">
						<h3>Contact us</h3>
						<p>
							Please be specific and select appropriate reason for contacting us.
						</p>
						<div>
							<div id="message-contact"></div>
							<form onSubmit={this.handleOnSubmit} id="contactform">
								<div className="row">
									<div className="col-md-6 col-sm-6">
                                      <div className={this.state.errors.name_contact ? "form-group has-error" : "form-group"}>
                                       	<input type="text" className="form-control" id="name_contact" name="name_contact" placeholder="Name" onChange={this.handleChange}/>
                                           {this.state.errors.name_contact ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.name_contact}</span>) :''}
										</div>
									</div>
									<div className="col-md-6 col-sm-6">
                                    <div className={this.state.errors.telegram_id ? "form-group has-error" : "form-group"}>
                                     	<input type="text" className="form-control" id="telegram_id" name="telegram_id" placeholder="@Telegram Id" onChange={this.handleChange}/>
                                         {this.state.errors.telegram_id ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.telegram_id}</span>) :''}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6 col-sm-6">
										<div className={this.state.errors.email_contact ? "form-group has-error" : "form-group"}>
											<input type="email" id="email_contact" name="email_contact" className="form-control" placeholder="Email" onChange={this.handleChange}/>
                                            {this.state.errors.email_contact ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.email_contact}</span>) :''}
										</div>
									</div>
									<div className="col-md-6 col-sm-6">
										<div className="form-group">
											<select className="form-control" name="inquiry" onChange={this.handleChange}>
												<option>General Inquiry</option>
												<option>Contract Owner - Requesting Audit Badge</option>
												<option>Premium Group Subscription Request</option>
												<option>Need Audit Tokens</option>
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
                      <div className={this.state.errors.message_contact ? "form-group has-error" : "form-group"}>
										 <textarea rows="5" id="message_contact" name="message_contact" className="form-control" style={{height:'100px'}} placeholder="Enter your query" onChange={this.handleChange}></textarea>
                       {this.state.errors.message_contact ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.message_contact}</span>) :''}
									</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
                                      <div className={this.state.errors.verify_contact ? "form-group has-error" : "form-group"}>
											<input type="text" id="verify_contact" name="verify_contact" className=" form-control" placeholder={`Human verify: ${this.state.sumA} + ${this.state.sumB} = ?`} onChange={this.handleChange}/>
                                            {this.state.errors.verify_contact ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.verify_contact}</span>) :''}
										</div>
									</div>
								</div>
								<input type="submit" value="Submit" className="btn_1 add_top_20" id="submit-contact"/>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
    </main>
    ) : (<Loader/>)} 
    {this.state.notify.visible?(<Notify attr={this.state.notify}/>):('')}
    </React.Fragment>
    )  
  }  
}

export default ContactUs