import React from 'react';
import EnergyHeader from '../SharedModule/EnergyHeader';
import EnergyBalance from './EnergyBalance';
import TronWeb from "tronweb";
import {TronConfig,ApiRoutes} from '../../config/apiRoutes';
import Utils from '../../config/util';
import Notify from '../SharedModule/Notify';
import Loader from '../SharedModule/Loader';

import {
  TrxResponseMessage,
  generateOrderId,
  getPayOut,
  sellenergyPopup,
  howitworksPopUp,
  HttpRequest,
  decodeBigNumber,
  TronfillOrder,
  copytoClipBoard,
  OrderNotification,
  newOrder
} from '../../config/TrxMessage';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Vote from './Vote';

const MySwal = withReactContent(Swal)

class Energy extends React.Component{
    constructor(props){
        super(props)
        this.state={
          tronWeb: {
            installed: false,
            loggedIn: false,
          },
          referral_address:'',
          notify:{},
          loading:false,
          errors:false,
          success:false,
          energy:1000000,
          energy_sell:'',
          orderAmount: 0,
          savingAmount:0,
          discount: 0,
          termsbuy : false,
          msg:"",
          type_order:'current_orders',
          receiver_address:"",
          receiver_address_1:"",
          user_address:"",
          allornone: false,
          priceinsun : 30,
          setting_priceinsun : 30,
          freezeDuration : 3,
          freezeAmount:0,
          freezetoAddress:'',
          payout:0,
          accountBalance:null,
          accountBandwithLimit:0,
          accountEnergy:0,
          EnergyByFreezingTrx:0,
          submitBtn:false,
          submitBtn1:false,
          terms:false,
          orderid:'',
          orderData:'',
          recentOrders:[],
          dataCount:'',
          pagination:{},
          dataCount2:'',
          pagination2:{},
          pageNumber:0,
          commission : [],
          minAmount : 30,
          /* end */
          sellModel:{
            visible:false,
            style:{
              display:"none"
            }
           },
           buyModel:{
            visible:false,
            style:{
              display:"none"
            }
           },
           voteModel:{
            visible:false,
            style:{
              display:"none"
            }
           }
         }
         this.handleOnSubmit = this.handleOnSubmit.bind(this)
       }

       async componentDidMount() {
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("balance");
        
        this.getOrderifNotLogin();
        
        await new Promise(resolve => {
          const tronWebState = {
            installed: !!window.tronWeb,
            loggedIn: window.tronWeb && window.tronWeb.ready
          };
          if(tronWebState.installed) {
            this.setState({
              tronWeb: tronWebState
            });
    
            return resolve();
          }
    
          let tries = 0;
    
          const timer = setInterval(() => {
            if (2e4 <= tries) {
              const TRONGRID_API = TronConfig.TRONGRID_API
              window.tronWeb = new TronWeb(
                TronConfig.fullHost,
                TronConfig.privateKey,
              );
              this.setState({
                tronWeb: tronWebState
              });
              clearInterval(timer);
              return resolve();
            }
            
    
            tronWebState.installed = !!window.tronWeb;
            tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
            
            if (!tronWebState.installed) {
              return tries++;
            }
           
            this.setState({
              tronWeb: tronWebState
            },()=>{
               localStorage.setItem('loggedIn',tronWebState.loggedIn);
            });
    
            return resolve();
          },100);
        });
           
           // Set default address (foundation address) used for contract calls
           // Directly overwrites the address object if TronLink disabled the
           // function call
           if(!this.state.tronWeb.loggedIn) { 
            
            window.tronWeb.defaultAddress = {
              hex: window.tronWeb.address.toHex(TronConfig.FOUNDATION_ADDRESS),
              base58: TronConfig.FOUNDATION_ADDRESS
            };
            
    
            window.tronWeb.on('addressChanged', () => {
              if(this.state.tronWeb.loggedIn)
                  return;
              this.setState({
                  tronWeb: {
                      installed: true,
                      loggedIn: true
                  }
              },()=>{
                console.log('login');
              });
          });
        }
        this.getrecentOrders()
        const x = await Utils.setTronWeb(window.tronWeb);
        this.fetchSearchParam()
        const status = await this.CheckMaintainence();
        if(status){
          this.MaintenanceAlert()
          this.setState({
            loading : status
          })
        }else{
          this.getOrderdetail();
          this.BuyEnergyCalc();
          this.fetchAccountBalance();
         }
        }

      async componentDidUpdate(prevProps,nextProps){
         if((this.state.receiver_address.length > 0) && (nextProps.receiver_address.length===0)){
           this.handleOrders(this.state.type_order)
         }
      }
      
      
      async fetchSearchParam(){
        const urlParams = new URLSearchParams(this.props.history.location.search);
        var myParam = null;
        myParam = urlParams.get('referral');
        if(myParam !==null){
          console.log(myParam)
        try{ 
          await window.tronWeb.contract().at(myParam);
          }catch(e){
            if(e.toString()==="Invalid contract address provided"){
              myParam = null
              this.setState({
               notify:{
                 visible:true,
                 type:'warning',
                 msg:'Refferral id is invalid'
               },
               submitBtn:true
            },()=>{
              setTimeout(()=>{ this.setState({notify:{}})},3000)
            })
           }
          }
          this.setState({
            referral_address:myParam
          })
         } 
       }
     
      async CheckMaintainence(){
        const trondata  = await window.tronWeb.contract().at(TronConfig.ExchangeContractAddress);
        const InMaintainence = await trondata.inMaintenance().call();  /* Call Maintainence is true or false */
        return InMaintainence;
      }

      MaintenanceAlert(){
         MySwal.fire({
                title: 'Maintenance',
                text : 'Please check back soon',
                icon: 'info',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false
        })
      }

      
      
      async getOrderifNotLogin(){
        const orders = await HttpRequest(ApiRoutes.GET_ORDERS);
         this.setState({
           orderData: orders.data.data,
           pagination:orders.data,
           dataCount:orders.data.data.total,
         })
        }

      async getrecentOrders(){
        const recentOrders = await HttpRequest(ApiRoutes.GET_RECENT_ORDERS);
        this.setState({
          pagination2:recentOrders.data,
          dataCount2:recentOrders.data.data.total,
          recentOrders : recentOrders.data.data
        })
      }  

      async getOrderdetail(){
        if(this.state.loading) return false;
        const trondata  = await window.tronWeb.contract().at(TronConfig.ExchangeContractAddress);
        const walletAdd= await trondata.tronWeb.defaultAddress.base58;
        let referral_address = '';

        console.log(this.state.referral_address)

        if(this.state.referral_address === null || this.state.referral_address === ""){
          referral_address = trondata.tronWeb.address.fromHex(TronConfig.referralAddress)
        }else{
          referral_address = this.state.referral_address 
        }
        console.log('flagggg1')
        const getCommission = await trondata.getPercentage().call();
        console.log(decodeBigNumber(getCommission.__MinAmountTrx))
        console.log(getCommission);
        //console.log( trondata.tronWeb.address.fromHex('4174149f2e4cd8760c5ab9f978175e55daece8a3ef'))
        const orders = await HttpRequest(ApiRoutes.GET_ORDERS);
         this.setState({
           orderData: orders.data.data,
           pagination:orders.data,
           dataCount:orders.data.data.total,
           receiver_address : walletAdd,
           receiver_address_1 : walletAdd,
           referral_address : referral_address,
           freezetoAddress : walletAdd,
           commission : getCommission,
           minAmount : decodeBigNumber(getCommission.__MinAmountTrx)/1000000
         })
           this.BuyEnergyCalc();
        }

      async fetchAccountBalance() {
        if(this.state.loading) return false;
        const trondata = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS);
        const freezeBal = await this.fetchforstBal(trondata.tronWeb.defaultAddress.base58);
        const token = await HttpRequest(ApiRoutes.GET_ACCOUNT,{env : TronConfig.NODE , address : trondata.tronWeb.defaultAddress.hex});
        const getEnergy =  await HttpRequest(ApiRoutes.GET_ACCOUNT_RESOURCE,{env : TronConfig.NODE , address : trondata.tronWeb.defaultAddress.hex});
         const settings =  await HttpRequest(ApiRoutes.SETTINGS);
        // console.log(settings.data.price_in_sun);
        const NetLimit = getEnergy.data.NetLimit ? getEnergy.data.NetLimit : 0;
        
        this.setState({
          accountBalance: (token.data.hasOwnProperty('balance')) ? token.data.balance/1000000 : 0,
          accountEnergyRemain :  getEnergy.data.EnergyLimit - (getEnergy.data.EnergyUsed ? getEnergy.data.EnergyUsed : 0), 
          accountEnergyLimit : getEnergy.data.EnergyLimit,
          accountBandwithLimit : getEnergy.data.freeNetLimit+ NetLimit,
          accountBandwithRemain : (getEnergy.data.freeNetLimit)-(getEnergy.data.freeNetUsed ? getEnergy.data.freeNetUsed : 0)+NetLimit,
          frostTrx : (freezeBal.hasOwnProperty('totalFrostBal')) ? freezeBal.totalFrostBal-(freezeBal.bandwithReceive+freezeBal.energyReceive) : 0,
          totalFrostTrx : (freezeBal.hasOwnProperty('totalFrostTrx')) ? freezeBal.totalFrostTrx : 0, 
          totalFrostBandwidth : (freezeBal.hasOwnProperty('totalFrostBandwidth')) ? freezeBal.totalFrostBandwidth : 0, 
          bandwithSent : (freezeBal.hasOwnProperty('bandwithSent')) ? freezeBal.bandwithSent : 0, 
          bandwithReceive : (freezeBal.hasOwnProperty('bandwithReceive')) ? freezeBal.bandwithReceive : 0, 
          energySent : (freezeBal.hasOwnProperty('energySent')) ? freezeBal.energySent : 0, 
          energyReceive : (freezeBal.hasOwnProperty('energyReceive')) ? freezeBal.energyReceive : 0, 
          //(1/getEnergy.data.TotalEnergyWeight)*getEnergy.data.TotalEnergyLimit
          //EnergyByFreezingTrx : (1/3285057145)*90000000000,
          EnergyByFreezingTrx : (1/getEnergy.data.TotalEnergyWeight)*getEnergy.data.TotalEnergyLimit,
          priceinsun : ('data' in settings) ? settings.data.price_in_sun : 30,
          setting_priceinsun : ('data' in settings) ? settings.data.price_in_sun : 30
        },()=>{
         // console.log((1/getEnergy.data.TotalEnergyWeight)*getEnergy.data.TotalEnergyLimit)
          console.log(this.state);
        });
      }

      fetchforstBal= async (address)=>{
        let newarr = [];
        let totalFrostBal = 0;
        let totalFrostTrx = 0;
        let totalFrostBandwidth = 0;
        let energyReceive = 0;
        let energySent = 0;
        let bandwithReceive = 0;
        let bandwithSent = 0;

        for(let i=1; i<=3; i++){
         let x = await axios(`${TronConfig.tronscanOrgApi}/api/account/resource?limit=500&start=0&address=${address}&type=${i}&resourceType=0`)
         if(Object.keys(x.data.data).length > 0){
          x.data.data.map(ele=>{
            totalFrostBal = totalFrostBal + (ele.frozenBalance/1000000);
            
            if(ele.resource==='BANDWIDTH'){
              totalFrostBandwidth = totalFrostBandwidth + (ele.frozenBalance/1000000);
            }

            if(ele.resource==='ENERGY'){
              totalFrostTrx = totalFrostTrx + (ele.frozenBalance/1000000);
            }
            
            if(i===2){ /* if user freeze for other user*/
              if(ele.resource==='ENERGY'){
                energySent = energySent + (ele.frozenBalance/1000000);
              }
              if(ele.resource==='BANDWIDTH'){
                bandwithSent = bandwithSent + (ele.frozenBalance/1000000);
              }
            }

            if(i===3){ /* if user freeze for other user*/
              if(ele.resource==='ENERGY'){
                energyReceive = energyReceive + (ele.frozenBalance/1000000);
              }
              if(ele.resource==='BANDWIDTH'){
                bandwithReceive = bandwithReceive + (ele.frozenBalance/1000000);
              }
            }
           //newarr.push(ele)
          })
         }
        }
        return {
          totalFrostBal,
          totalFrostTrx,
          totalFrostBandwidth,
          bandwithSent,
          energySent,
          energyReceive,
          bandwithReceive
        }
      }
  
        BuyEnergyCalc(){
        if(this.state.loading) return false;  
        if(this.state.priceinsun >= this.state.setting_priceinsun){ 
            let energy = this.state.energy ;
            let freezedays = 3;
            let Sunprize = this.state.priceinsun;
            let x =  window.tronWeb.fromSun(140 * energy * freezedays) ;
            let t = (1e4 * x)/1e4 ; 
            let u = window.tronWeb.fromSun(energy * Sunprize * freezedays) ;
            let orderAmount = (1e4 * u)/1e4
            orderAmount = (orderAmount < 30 && orderAmount !==0) ? 30 : orderAmount;
            let savingAmount = (1e4 *(t - orderAmount) / 1e4)
            let discount = Math.ceil(100 * (1 - orderAmount/ t))
            console.log(orderAmount,savingAmount,discount,Sunprize)
            
            this.setState({
                orderAmount : orderAmount,
                savingAmount : savingAmount,
                discount : discount
            })
          }
        }  
    
    buyModelOpen=e=>{
      this.setState({
        notify:{},
        errors:{},
        energy:1000000,
        //priceinsun : this.state.priceinsun,
        submitBtn:false,
        buyModel:{
          visible:true,
            style:{
              display:"block",
              backgroundColor:'#000000cf'
            }
        }
      },()=>{
        this.BuyEnergyCalc();
      })
    }

    buyModelClose=e=>{
      e.preventDefault();
      this.setState({
        notify:{},
        submitBtn:false,
        termsbuy: false,
        buyModel:{
          visible:false,
          style:{
            display:"none"
          }
        }
      })
    }

    voteModelOpen=()=>{
      this.setState({
        notify:{},
        errors:{},
        energy:1000000,
        priceinsun:this.state.minAmount,
        submitBtn:false,
        voteModel:{
          visible:true,
            style:{
              display:"block",
              backgroundColor:'#000000cf'
            }
        }
      },()=>{
        this.BuyEnergyCalc();
      })
    }

    voteModelClose=e=>{
      
      //e.preventDefault();
      this.setState({
        notify:{},
        submitBtn:false,
        voteModel:{
          visible:false,
          style:{
            display:"none"
          }
        }
      })
    }

    SellModelClose=e=>{
      this.setState({
        notify:{},
        submitBtn:false,
        errors:{},
        sellModel:{
          visible:false,
          style:{
            display:"none"
          }
        }
      })
    }

    async SellModelOpen(e,ele){
      e.preventDefault();
      let payout = (ele.amount).toFixed(0);
       payout = getPayOut(decodeBigNumber(this.state.commission['__MinAmountTrx']), decodeBigNumber(this.state.commission['__adminCharges']), decodeBigNumber(this.state.commission['__referralCharges']) ,decodeBigNumber(this.state.commission['__payableAmountPercentage']),payout)
       console.log(payout,this.state.EnergyByFreezingTrx) 
       this.setState({
            orderid : ele.order_id,
            energy_sell : ele.energy,
            freezeAmount : this.state.EnergyByFreezingTrx > 0  ? (ele.energy/this.state.EnergyByFreezingTrx).toFixed(0) : 0,
            freezetoAddress : ele.freeze_to_address,
            referralby : ele.referralby,
            payout : payout,
            sellModel:{
              visible:true,
              style:{
                display:"block",
                backgroundColor:'#000000cf'
              }
            }
          })
       }

    async handleValidation(type){
      console.log('innnn')
      if(this.state.receiver_address.length === 0 && type==='buyenergy'){
        this.setState({
          errors:{
            receiver_address:'Enter Trx address'
          }
        })
        return false;
      }

      if(this.state.receiver_address_1.length === 0 && type==='sellenergy'){
        this.setState({
          errors:{
            receiver_address_1:'Enter Trx address'
          }
        })
        return false;
      }

      if(this.state.receiver_address.length > 0 && type==='buyenergy'){
        try{ 
        await window.tronWeb.contract().at(this.state.receiver_address);
        }catch(e){
          if(e.toString()==="Invalid contract address provided"){
            this.setState({
            errors:{
              receiver_address:'Invalid TRX address'
            }
          })
          return false;
          }
        } 
      }

      if(this.state.receiver_address_1.length > 0 && type==='sellenergy'){
        try{ 
        await window.tronWeb.contract().at(this.state.receiver_address_1);
        }catch(e){
          if(e.toString()==="Invalid contract address provided"){
            this.setState({
            errors:{
              receiver_address_1:'Invalid TRX address'
            }
          })
          return false;
          }
        } 
      }

      if(this.state.energy < 333334 && type==='buyenergy'){
        this.setState({
          errors:{
            energy:'The lowest energy amount at the price you chose is 333334 energy'
          }
        })
        return false;
      };

      // if(this.state.energy_sell < 444445 && type==='sellenergy'){
      //   this.setState({
      //     errors:{
      //       energy_sell:'The lowest energy amount at the price you chose is 444445 energy'
      //     }
      //   })
      //   return false;
      // };
      console.log(this.state.accountBalance,this.state.orderAmount)
      
      if(parseInt(this.state.accountBalance) < parseInt(this.state.orderAmount) && type=='buyenergy'){
        this.setState({
          errors:{
            energy:'Not enough balance in your account.'
          }
        })
        return false;
        }
        //console.log(this.state.accountBalance,this.state.freezeAmount)
        if(this.state.accountBalance < this.state.freezeAmount && type==='sellenergy'){
            this.setState({
              errors:{
                energy_sell:`You dont have ${this.state.freezeAmount} trx balance to freeze`
              }
            })
            return false;
        } 
      
      if(this.state.priceinsun < this.state.setting_priceinsun && type==='buyenergy'){
        return false;
      }

      if(this.state.terms===false && type==='sellenergy'){
        this.setState({
          errors:{
            terms:'Please accept agree to freeze'
          }
        })
        return false;
      }

      if(this.state.termsbuy===false && type==='buyenergy'){
        this.setState({
          errors:{
            termsbuy:'Please accept agree to buy'
          }
        })
        return false;
      }
      
      this.setState({
        errors:{}
      })
    }

    adjustPrize(e,condition){
      e.preventDefault();
      console.log('adjust')
      if(condition==='increment'){
       let price = parseInt(this.state.priceinsun)+1
       this.setState({
         priceinsun : price <= 140 ? price : this.state.priceinsun
       },()=>{  this.BuyEnergyCalc() })
      }else if(condition==='decrement'){
       let price = this.state.priceinsun-1
       this.setState({
         priceinsun : price >= this.state.setting_priceinsun ? price : this.state.priceinsun 
       },()=>{ this.BuyEnergyCalc() })
      }
     }
      
      handleChange=e=>{
        let value=e.target.value;
        //if(e.target.value <0 )return false
         this.setState({
          [e.target.name] : e.target.value
        })
      }
    
    /* call on change of buy energy form */

      handleOnChange=(e)=>{
        e.preventDefault();
        let value = e.target.value;
  
        if(e.target.name==='priceinsun'){
          if(e.target.value > 140){
            return false;
          }else if(e.target.value < this.state.setting_priceinsun){
           value = this.state.setting_priceinsun;
          }
        }
          this.setState({
            [e.target.name] : value
            },()=>{
              this.BuyEnergyCalc();
            })
      }
  
    /* call on change of sell energy form */

      handleOnChange1=(e)=>{
        e.preventDefault();
        let value = e.target.value;
        this.setState({
          [e.target.name] : value
        })
      }

     /*call on buy Energy form submit*/  
      async handleOnSubmit(e){ 
        e.preventDefault();
        let valid = await this.handleValidation('buyenergy');
        if(valid===false)return false; 
        this.setState({ submitBtn : true });

      try{
        const orderId = generateOrderId(8)+'-'+generateOrderId(4)+'-'+generateOrderId(4);
        const trondata  = await window.tronWeb.contract().at(TronConfig.ExchangeContractAddress);
        const {energy,freezetoAddress,freezeDuration,priceinsun,orderAmount,referral_address} = this.state
        let _orderAmt = (orderAmount)*1000000;
        _orderAmt = Number((_orderAmt).toPrecision(8))

        let ObjReq = {
          feeLimit:300000000,
          callValue : _orderAmt,
          orderid : orderId,
          amount : _orderAmt,
          energy : energy,
          price_in_sun : priceinsun,
          filledByAddress : freezetoAddress,
          freeze_days : freezeDuration,
          status : false,
        }
        console.log(orderId);
        const transaction = await trondata.requestOrder(orderId,energy,_orderAmt,freezetoAddress,freezeDuration,priceinsun,false).send(ObjReq)
        const status = await TrxResponseMessage(transaction)
          if(status.txStatus==='SUCCESS'){
            ObjReq['txn_id'] = transaction;
            ObjReq['byaddress'] = this.state.receiver_address;
            ObjReq['allornone'] = this.state.allornone;
            ObjReq['isEnergy'] = true;
            ObjReq['freeze_to_address'] = freezetoAddress;
            ObjReq['referralby'] = referral_address;
            ObjReq['transaction_status'] = true;

            let res =  await HttpRequest(ApiRoutes.ENERGY_STORE,ObjReq); 
            if(res.data.status==='1'){
            
            let payt = getPayOut(decodeBigNumber(this.state.commission['__MinAmountTrx']), decodeBigNumber(this.state.commission['__adminCharges']),decodeBigNumber(this.state.commission['__referralCharges']),decodeBigNumber(this.state.commission['__payableAmountPercentage']) ,ObjReq.amount);
            let free = (parseInt(ObjReq.energy)/this.state.EnergyByFreezingTrx).toFixed(0);
            console.log(payt,free);
            ObjReq['payout'] = payt;
            ObjReq['freezing'] = free;
              
            OrderNotification(ObjReq)   

            newOrder(ObjReq)
            
            this.setState({
            energy:1000000,
            submitBtn : false,
            termsbuy : false,  
            notify:{
                visible:true,
                type:'success',
                msg : 'Your buy order has been places successfully.'
              },
            buyModel:{
              visible:false,
              style:{
                display:"none"
              }
            }  
            },()=>{
              this.getOrderdetail(); 
              setTimeout(()=>{
                this.fetchAccountBalance(); 
                this.setState({ notify:{} })
              },6000)
            })
           }
          }else if(status.txStatus==='OUT_OF_ENERGY'){
            ObjReq['txn_id'] = transaction;
            ObjReq['byaddress'] = this.state.receiver_address;
            ObjReq['allornone'] = this.state.allornone;
            ObjReq['isEnergy'] = true;
            ObjReq['referralby'] = referral_address;
            ObjReq['transaction_status'] = false;
            
            let res =  await HttpRequest(ApiRoutes.ENERGY_STORE,ObjReq); 
            if(res.data.status==='1'){
                
            this.setState({
              energy:1000000,
              submitBtn : false,  
              buyModel:{
                visible:false,
                style:{
                  display:"none"
                }
              }  
            },()=>{
              this.getOrderdetail(); 
              setTimeout(()=>{
                this.setState({ notify:{} })
              },6000)
            })
              MySwal.fire({
                title : '<small>You are requested to borrow some energy to make this transaction</small>',
                icon : 'error'
              })
            }  
          }else{
            ObjReq['txn_id'] = transaction;
            ObjReq['byaddress'] = this.state.receiver_address;
            ObjReq['allornone'] = this.state.allornone;
            ObjReq['isEnergy'] = true;
            ObjReq['referralby'] = referral_address;
            ObjReq['transaction_status'] = false;
            let res =  await HttpRequest(ApiRoutes.ENERGY_STORE,ObjReq); 
            if(res.data.status==='1'){
              this.setState({
                energy:1000000,
                submitBtn : false,
                termsbuy : false,  
                buyModel:{
                  visible:false,
                  style:{
                    display:"none"
                  }
                }  
              })
            }  
          }
       }catch(e){
         console.error(e)
         this.setState({
          energy:1000000,
          submitBtn : false,
          termsbuy : false,  
          buyModel:{
            visible:false,
            style:{
              display:"none"
            }
          }  
          })
       }
     }

     async cancelOrder(e,id){
       e.preventDefault();
       
       MySwal.fire({
        title: 'Please wait while your order is being cancelled ...',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
       })
       MySwal.showLoading();
      try{
       const trondata  = await window.tronWeb.contract().at(TronConfig.ExchangeContractAddress);
       const transaction = await trondata.CancelOrder(id).send({
        orderid:id
       });
       
       console.log(transaction);

       const status = await TrxResponseMessage(transaction);
       console.log(status);
        if(status.txStatus==='SUCCESS'){
        let ObjReq = {
          order_id : id
        } 

        let res =  await HttpRequest(ApiRoutes.CANCEL_ORDER,ObjReq); 
        if(res.data.status==='1'){
        MySwal.close()  
        this.setState({
        energy:1000000,
        submitBtn : false,  
        notify:{
            visible:true,
            type:'success',
            msg : 'Your order has been successfully cancelled'
          },  
        },()=>{
           this.handleOrders('open_orders');
           setTimeout(()=>{
            this.setState({ notify:{} })
          },6000)
        })
       }
      }else if(status.txStatus==='REVERT'){
        MySwal.close()
        this.setState({
          notify:{
              visible:true,
              type:'warning',
              msg : 'There is some issue please check later'
            },  
          },()=>{
             setTimeout(()=>{
              this.setState({ notify:{} })
            },6000)
          })   
        console.error(e)  
      }
     }catch(e){
      MySwal.close()   
      console.error(e)    
     }
   }

      handleFillform = async () => {
      let valid = await this.handleValidation('sellenergy');
      if(valid===false)return false; 
       this.setState({
        requestBtn : true,
        sellModel:{
          visible:false,
          style:{
            display:"none"
          }
        }
       },()=>{
        this.handleFillOrder();
       })
     }
     
     /* call on Sell Energy form submit*/
     handleFillOrder = async ()=>{
       let calling = '';
       console.log(this.state)
       const {orderid,payout,receiver_address,receiver_address_1,freezeAmount,freezeDuration,freezetoAddress,referralby} = this.state
       let ObjReq = {
          orderid : orderid,
          pto : receiver_address,
          fillamount : payout,
          status : true,
          isEnergy : true,
          freeze_to_address : freezetoAddress,
          complete_success : false
       }
       //console.log(referralby)
        MySwal.fire({
          title: 'Please accept the confirmation request to fill order...',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen : async () =>{
          try{ 
            MySwal.showLoading ()
            calling = 'freezeBalance';
            let res = await HttpRequest(ApiRoutes.PUT_FLAG,ObjReq);
            
            if(res.data.status==='0'){
              MySwal.close();
              this.setState({
                amount:'',
                requestBtn : false, 
                terms:false, 
                notify:{
                  visible:true,
                  type:'warning',
                  msg : res.data.msg
                }
              },()=>{
                setTimeout(()=>{
                  this.setState({ notify:{} })
                },5000)
              })
              return false;
            }
             console.log(referralby,receiver_address)
            if(referralby === null || receiver_address ===null){ /* if any error occur */
              MySwal.close();
              this.setState({
                requestBtn : false,
                terms:false, 
               })
               return false;
            }  
            const transaction = await window.tronWeb.transactionBuilder.freezeBalance(window.tronWeb.toSun(freezeAmount),freezeDuration,'ENERGY',receiver_address,freezetoAddress);
            var signedTx = await window.tronWeb.trx.sign(transaction)
            var broastTx = await window.tronWeb.trx.sendRawTransaction(signedTx)
            if(broastTx.hasOwnProperty('result')){
              if(broastTx.result===true){
                const trondata  = await window.tronWeb.contract().at(TronConfig.ExchangeContractAddress);
                var status_c = true;
                var isEnergy = true;
                MySwal.fire({
                  title: 'Please wait while your order is being filled ...',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                })
                MySwal.showLoading();
                calling = 'fillOrder';
                // const transaction = await trondata.fillOrder(orderid,payout,receiver_address,status_c,referralby).send({
                //   orderid:orderid,
                //   fillamount:payout,
                //   pto_address:receiver_address,
                //   status:status_c,
                //   referralAddress : referralby
                // });
                console.log(orderid,payout,receiver_address,status_c,referralby)
                const transaction = await TronfillOrder(orderid,payout,receiver_address,receiver_address_1,status_c,referralby)
                
                let status = await TrxResponseMessage(transaction);
                
                console.log(status);
                
                if(status.txStatus==='SUCCESS'){
                  let ObjReq = {
                    txn_id : transaction,
                    orderid : orderid,
                    pto : receiver_address,
                    order_status : 'complete',
                    complete_success : true
                  }

                  console.log(ObjReq);
                  await HttpRequest(ApiRoutes.PUT_FLAG,ObjReq);
                  
                  this.fetchAccountBalance();
                  
                  MySwal.fire({
                    'title':'Order Filled Successfully',
                    'icon' : 'success',
                      customClass: {
                        confirmButton: 'btn',
                      },
                    'confirmButtonText' : 'Vote Now',
                    'html' : `<div>
                                <p style="margin-bottom: 0px;font-size:15px;">${this.state.payout} Trx sent to your address</p>
                                <a style="font-size:9px" href="${TronConfig.transactionUrl}/#/transaction/${transaction}" target="_blank">${transaction}</a>
                                <p style="margin-bottom: 0px;font-size:15px;">${freezeAmount} Trx frozen for 3 days</p> 
                              </div>`
                  }).then(res=>{
                    if (res.isConfirmed) {
                      this.voteModelOpen()
                    }
                  })

                  this.setState({
                   amount:'',
                   requestBtn : false,
                   terms:false, 
                  })
                   this.getOrderdetail(); 
                   this.getrecentOrders();
                }
              }
            }
             }catch(e){
                console.error(e)
                let msg = (e==='Confirmation declined by user') ? e : 'There is some issue please check later'
                
                if(e==='Confirmation declined by user' && calling==='freezeBalance'){
                  let ObjReq = {
                    orderid : orderid,
                    pto : receiver_address,
                    complete_success : false
                  }
                  await HttpRequest(ApiRoutes.PUT_FLAG,ObjReq);
                }

                MySwal.close();
                 
                if(calling==='fillOrder'){
                  MySwal.fire({
                    title : '<span style="font-size: 20px;text-align: left;margin-bottom: -15px;">You have cancelled this operation. In case of any issues contact us at <a href="https://t.me/defi_audits_energy_support">@defi_audits_energy_support</a></span>'
                  })
                }
                
               
                this.setState({
                  amount:'',
                  requestBtn : false, 
                  terms:false, 
                  sellModel:{
                  visible:false,
                  style:{
                    display:"none"
                  }
                },
                  notify:{
                    visible:true,
                    type:'warning',
                    msg : msg
                  }
                },()=>{
                  setTimeout(()=>{
                    this.setState({ notify:{} })
                  },5000)
                })
             }
          }   
        })   
      } 

    /* Sell Energy Model */
    SellEnergyModel(){
        return(
            <div className="modal-dialog">
              <div className="col-md-10">
                <div className="modal-content">
                  <div className="modal-header">
                 <h4 className="modal-title">Fill Order</h4>
                  <button type="button" className="close" data-dismiss="modal" onClick={()=>this.SellModelClose()}>&times;</button>
                </div>
                <div className="modal-body">
                  {/* <input
                    type="number"
                    placeholder="Enter amount"
                    name="energy_sell"
                    value={this.state.energy_sell}
                    className="form-control"
                    style={{ width: "100%" }}
                    onChange={(e)=>this.handleOnChange1(e)}
                  /> */}
                  {/* {this.state.errors.energy_sell ? (<span style={{color:'red',marginLeft:'6px',fontSize:'13px',display:'flex'}}>{this.state.errors.energy_sell}</span>) :''} */}
                </div>
                <div className="modal-body">
                  <input
                    type="text"
                    placeholder="Receive payout at address"
                    name="receiver_address_1"
                    value={this.state.receiver_address_1}
                    className="form-control"
                    style={{ width: "100%" }}
                    onChange={(e)=>this.handleOnChange1(e)}
                  />
                  {this.state.errors.receiver_address_1 ? (<span style={{color:'red',marginLeft:'6px',fontSize:'13px',display:'flex'}}>{this.state.errors.receiver_address_1}</span>) :''}
                </div>
                
                <div className="modal-body">
                  <div style={{backgroundColor:'#00000017',borderRadius : '5px',padding:'10px'}}>
                            <div className="row">
                                <div className="input-group mb-3">
                                <div className="col-md-12">
                                    <label>Balance : {parseInt(this.state.accountBalance).toFixed(2)}</label>
                                    </div>
                                </div>
                            </div>
  
                            <div className="row">
                                <div className="input-group mb-3">
                                <div className="col-md-12">
                                    <label>Freeze : {this.state.freezeAmount} TRX</label>
                                    </div>
                                </div>
                            </div>
  
                            <div className="row">
                                <div className="input-group mb-3">
                                <div className="col-md-12">
                                    <label>Payout : <span style={{color:'#e74e84'}}>{this.state.payout} TRX</span></label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-group mb-3">
                                <div className="col-md-12">
                                    <label>Energy : <span style={{color:'#e74e84'}}>{this.state.energy_sell}</span></label>
                                    </div>
                                </div>
                                {this.state.errors.energy_sell ? (<span style={{color:'red',marginLeft:'6px',fontSize:'13px',display:'flex',padding:'0 10px'}}>{this.state.errors.energy_sell}</span>) :''}
                            </div>
                      </div>
                 </div>
                 <div className="modal-body">
                    <input type="checkbox" name="terms" checked={this.state.terms} onChange={(e)=>{ this.setState({terms : e.target.checked })}} />&nbsp;<span>I agree to freeze  TRX for 3 days</span>
                    {this.state.errors.terms ? (<span style={{color:'red',marginLeft:'6px',fontSize:'13px',display:'flex'}}>{this.state.errors.terms}</span>) :''}
                 </div>
                <div className="modal-footer" style={{display:'block'}}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{float:'left'}} onClick={this.handleFillform}
                  disabled={this.state.submitBtn}
                  >
                  {this.state.submitBtn1?'Please wait ...':'Fill'}
                </button>
                </div>
                <span style={{padding:'10px',fontSize:'11px'}}>
                     You TRX will be frozen for a period of 3 days. During this period you will not be able to trade or sell them.
                  </span>
              </div>
              </div>
            </div>
        )
      }
      
      /* Buy Energy Model */
      BuyEnergyModel(){
        return(
            <div className="modal-dialog">
              <div className="col-md-10">
               <div className="modal-content">
              <div className="modal-header">
                 <h4 className="modal-title">Buy Energy </h4>
                  <button type="button" className="close" data-dismiss="modal" onClick={(e)=>this.buyModelClose(e)} disabled={this.state.submitBtn} >&times;</button>
                </div>
              <form role="form" encType="multipart/form-data" id="requestForm" onSubmit={this.handleOnSubmit}>  
                <div className="modal-body" style={{padding:'0.6em'}}>
                <label>Receiver Address</label>
                <input 
                 type="text" 
                 className="form-control" 
                 name="receiver_address" 
                 value={this.state.freezetoAddress} 
                 onChange={(e)=>this.setState({freezetoAddress: e.target.value})}
                />
                {this.state.errors.receiver_address ? (<span style={{color:'red',marginLeft:'6px',fontSize:'13px',display:'flex'}}>{this.state.errors.receiver_address}</span>) :''}
                </div>
                <div className="modal-body" style={{padding:'0.6em'}}>
                 <input 
                   type="number" 
                   className="form-control" 
                   name="energy" 
                   value={this.state.energy} 
                   onChange={(e)=>this.handleOnChange(e)}
                 />
                 {this.state.errors.energy ? (<span style={{color:'red',marginLeft:'6px',fontSize:'13px',display:'flex'}}>{this.state.errors.energy}</span>) :''}
                </div>

                <div className="modal-body" style={{padding:'0.6em'}}>
                 <input 
                  type="checkbox" 
                  name="allornone" 
                  checked={this.state.allornone} 
                  onChange={(e)=> this.setState({allornone : this.state.allornone ? false : true })}
                  />&nbsp; 
                 <label>All or None</label>
                </div>

                <div className="modal-body" style={{padding:'0.6em'}}>
                  <label>Price / day :</label>&nbsp;
                   <button style={{padding:'9px 15px',margin:'0px 5px',backgroundColor:'#007bff'}} onClick={(e)=>this.adjustPrize(e,'decrement')}>-</button>
                    <input 
                      type="number" 
                      name="priceinsun" 
                      value={this.state.priceinsun} 
                      className="form-control " 
                      style={{maxWidth:'18%',display:'unset'}}  
                      onChange={(e)=>this.handleOnChange(e)} 
                     />
                   <button style={{padding:'9px 15px',margin:'0px 5px',backgroundColor:'#007bff'}} onClick={(e)=>this.adjustPrize(e,'increment')}>+</button>
                </div>

                <div className="modal-body" style={{padding:'0.6em'}}>
                   <label>Freeze duration : 3 days</label>
               </div>
                
                <div className="modal-body" style={{padding:'0.6em'}}>
                  <div style={{backgroundColor:'#00000017',borderRadius : '5px',padding:'10px'}}>
                  <div className="row">
                      <div className="input-group">
                      <div className="col-md-12">
                      <label style={{marginBottom:'-5px'}}>Order amount :
                          <h5 style={{float:'right'}}>{(this.state.orderAmount).toFixed(2)} TRX</h5>
                        </label>
                          </div>
                      </div>
                  </div>
  
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-12">
                        <label>Savings : <span style={{color:'#e74e84'}}>{(this.state.savingAmount).toFixed(1)} TRX</span></label>
                      </div>
                    </div>
                 </div>
  
                 <div className="row">
                   <div className="input-group">
                      <div className="col-md-12">
                         <label>Discount : <span style={{color:'#e74e84'}}>{this.state.discount}%</span></label>
                      </div>
                    </div>
                 </div>

                 <div className="row">
                   <div className="input-group">
                      <div className="col-md-12">
                         <label>TXN Fee: <span style={{color:'#e74e84'}}>0 TRX</span></label>
                      </div>
                    </div>
                 </div>
                      </div>
                 </div>
                 <div className="modal-body" style={{padding:'0.6em',display:'flex'}}>
                    <input type="checkbox" name="terms" checked={this.state.termsbuy} onChange={(e)=>{ this.setState({termsbuy : e.target.checked })}} />&nbsp;<span style={{fontSize:'12px'}}>I have read the declaimer before and agree to the <a href="/faqs#terms" target="_blank" >terms</a> and conditions here</span>
                 </div>
                 {this.state.errors.termsbuy ? (<span style={{color:'red',marginLeft:'20px',fontSize:'13px',display:'flex'}}>{this.state.errors.termsbuy}</span>) :''}
                 {/* {this.state.priceinsun > this.state.setting_priceinsun ? (
                   <div className="row">
                    <span style={{padding:'0 30px 0 30px',fontSize:'11px'}}>
                      The minimum price of energy is temporaily raised to {this.state.setting_priceinsun} sun in order to address the sudden decrease in the energy rate in the tron blockchain .The raise is temporary and it will go back to {this.state.setting_priceinsun} sun once the energy rate stablilizes.
                     </span>
                   </div>
                 ) : ('')} */}  
                <div className="modal-footer" style={{display:'block'}}>
                  <input 
                  type="submit" 
                  value={this.state.submitBtn?"Please wait...":"Place Order"} 
                  className="btn btn-primary" 
                  disabled={this.state.submitBtn}
                  />

                  <input 
                  type="submit" 
                  value="Cancel" 
                  className="btn btn-secondary" 
                  onClick={(e)=>this.buyModelClose(e)}
                  disabled={this.state.submitBtn}
                  />
                  <div style={{fontSize:'10px'}}>
                     Zero exchange fee!! The amount of Energy you receive over the next 3 days will depend on the total amount of TRX frozen for Energy across the entire network. Please rememeber this while pricing your order. A fee of 20 TRX will be deducted for order cancellations to pay for the network transaction fee.
                 </div>
                </div>
               </form> 
              </div>
              </div>
            </div>
        )
      }
     
      /* function for pagination */ 
      async handlePagination(page){
        let url = '';
        let address='';
        if(this.state.type_order==='current_orders'){
         url = ApiRoutes.GET_ORDERS+'?page='+page;
        }else if(this.state.type_order==='recent_orders'){
         url = ApiRoutes.GET_RECENT_ORDERS+'?page='+page;
        }else if(this.state.type_order==='open_orders'){
          url = ApiRoutes.USER_ORDERS+'?page='+page;
          address={address:this.state.receiver_address}
        }else if(this.state.type_order==='my_completed_orders'){
          url = ApiRoutes.User_Completed_Orders+'?page='+page;
          address={address:this.state.receiver_address}
        }
        const orders = await HttpRequest(url,address);
        this.setState({
          orderData: orders.data.data,
          pagination:orders.data,
          dataCount:orders.data.data.total,
          pageNumber : page-1
        })
      }

      async handlePagination2(page){
        let  url = ApiRoutes.GET_RECENT_ORDERS+'?page='+page;
        const orders = await HttpRequest(url);
        this.setState({
          recentOrders: orders.data.data,
          pagination2:orders.data,
          dataCount2:orders.data.data.total,
        })
      }

    async handleOrders(type){
     let orders = '';
    if(type==='current_orders'){
       orders = await HttpRequest(ApiRoutes.GET_ORDERS);
    }
    // else if(type==='recent_orders'){
    //    orders = await HttpRequest(ApiRoutes.GET_RECENT_ORDERS);
    // }
    else if(type==='open_orders'){
       orders = await HttpRequest(ApiRoutes.USER_ORDERS,{address:this.state.receiver_address});
    }else if(type==='my_completed_orders'){
      orders = await HttpRequest(ApiRoutes.User_Completed_Orders,{address:this.state.receiver_address});
    }
    

      this.setState({
        type_order : type,
        orderData: orders.data.data,
        pagination:orders.data,
        dataCount:orders.data.data.total,
        pageNumber:0
      })
    }  

    render(){
        const balance = {
            receiver_address : this.state.receiver_address,
            accountBalance : this.state.accountBalance,
            frostTrx : this.state.frostTrx,
            accountBandwith : this.state.accountBalance,
            accountEnergy : this.state.accountEnergy,
            totalFrostTrx : this.state.totalFrostTrx,
            totalFrostBandwidth : this.state.totalFrostBandwidth,
            energySent: this.state.energySent,
            energyReceive : this.state.energyReceive,
            bandwithSent : this.state.bandwithSent,
            bandwithReceive : this.state.bandwithReceive,
            accountBandwithLimit : this.state.accountBandwithLimit,
            accountBandwithRemain : this.state.accountBandwithRemain,
            accountEnergyLimit : this.state.accountEnergyLimit,
            accountEnergyRemain : (this.state.accountEnergyRemain < 0 ? 0 : this.state.accountEnergyRemain)
        }
        return(
        <React.Fragment>
        <div className={this.state.sellModel.visible?"modal fade show":"modal fade"} id="myModal" style={this.state.sellModel.style}>
          {this.SellEnergyModel()}
        </div>

      <div className={this.state.buyModel.visible?"modal fade show":"modal fade"} id="myModal" style={this.state.buyModel.style}>
       {this.BuyEnergyModel()}
      </div>

      <div className={this.state.voteModel.visible?"modal fade show":"modal fade"} id="myModal" style={this.state.voteModel.style}>      
       <Vote 
          userAddress={this.state.receiver_address}
          totalVotes={this.state.frostTrx} 
          status={this.state.voteModel.visible} 
          callbackValue={(e)=>this.voteModelClose(e)}/>
      </div>
       
      {!this.state.loading ?
        (    
          <main> 
             <EnergyHeader callback={()=>{ this.buyModelOpen() }} />
             <div class="container" style={{paddingTop:'20px'}}>
                <EnergyBalance data={balance} />
             </div>
             
            {this.state.accountBalance !==null ? (
              <div className="filters_listing">
                <div className="container">
                    <ul className="clearfix">
                        <li style={{float:'right'}}>
                            <div className="btn_1" onClick={(e)=>{ copytoClipBoard(e,this.state.receiver_address) }}><span style={{marginLeft:'60px'}}><i className="fa fa-link"></i>&nbsp;&nbsp;Your referral link &nbsp;<i class="fa fa-files-o" aria-hidden="true"></i></span><br></br>{this.state.receiver_address}</div>
                        </li>
                    </ul>
                </div>
            </div>
            ):('')} 
              
             <div className="filters_listing">
                <div className="container">
                    <ul className="clearfix">
                        <li>
                            <div>
                                <input className="btn_1" type="submit" value="Buy Energy" onClick={()=>this.buyModelOpen()}/>
                                <a href="detail-page.html" style={{border:'2px solid #e74e84',
                                  color:'#e74e8',
                                  cursor:'pointer',
                                  padding:'5px 20px',
                                  borderRadius:'30px',
                                  marginLeft:'8px'
                                  }}
                                  onClick={(e)=>sellenergyPopup(e)}
                                >Sell Energy
                                </a>
                            </div>
                        </li>
                        
                        <li>
                           <a href="#" style={{paddingTop:'10px'}} onClick={(e)=>howitworksPopUp(e)}>How this works?</a>&nbsp;
                           {this.state.accountBalance !== null ? 
                            <a href="#" onClick={()=>{this.voteModelOpen()}}>Vote</a> 
                            : ('')} 
                            {/* <a href="#" onClick={(e)=>{}}>Success</a>  */}
                        </li>
                    </ul>
                </div>
            </div> 

            <div className="main_title order-header">
              <h1>Current Orders</h1>
           </div>

           <div className="container margin_60_35">
            <div className="row">
                <div className="col-lg-12">
                    <div className="switch-field">
                        <input type="radio" id="all" name="type_patient" value="current_orders"  defaultChecked={this.state.type_order==='current_orders'?true:false} />
                        <label for="all" onClick={(e)=>this.handleOrders('current_orders')} >Current Orders</label>
                         <input type="radio" id="clinics" name="type_patient" value="open_orders" defaultChecked={this.state.type_order==='open_orders'?true:false}/> 
                        <label for="clinics" onClick={(e)=>this.handleOrders('open_orders')}>My Open Orders</label>
                        <input type="radio" id="clinicss" name="type_patient" value="my_completed_orders" defaultChecked={this.state.type_order==='my_completed_orders'?true:false}/>
                        <label for="clinicss" onClick={(e)=>this.handleOrders('my_completed_orders')}>My Completed Orders</label>
                    </div>
                {this.state.orderData.length > 0 ?  
                  this.state.orderData.map((ele,key)=>
                    <div className="akstrip_list" key={key}>
                        <div className="row">
                            <div className="col-lg-3" id={ele.order_id}>
                             <div> 
                                <small>Payout</small>
                                  <h3>
                                    { ('__MinAmountTrx' in this.state.commission) ?
                                         getPayOut(decodeBigNumber(this.state.commission['__MinAmountTrx']), decodeBigNumber(this.state.commission['__adminCharges']),decodeBigNumber(this.state.commission['__referralCharges']),decodeBigNumber(this.state.commission['__payableAmountPercentage']) ,ele.amount)
                                     : ''
                                     } TRX
                                  </h3>
                                <p style={{marginBottom:'5px'}}>Min Payout: 
                                <b> { ('__MinAmountTrx' in this.state.commission) ?
                                         getPayOut(decodeBigNumber(this.state.commission['__MinAmountTrx']), decodeBigNumber(this.state.commission['__adminCharges']), decodeBigNumber(this.state.commission['__referralCharges']) , decodeBigNumber(this.state.commission['__payableAmountPercentage']), ele.amount)
                                     : ''
                                     } TRX</b></p>
                                <p style={{float:'left'}}>Energy: <b>{ele.energy}</b></p>
                             </div> 
                            </div>
                            <div className="col-lg-3">
                              {this.state.accountBalance ?
                                 <React.Fragment>
                                    <small>Freeze</small>
                                   <h3>{(ele.energy/this.state.EnergyByFreezingTrx).toFixed(0)} TRX</h3>
                                 </React.Fragment>
                                :(<React.Fragment>
                                   {<div style={{marginTop:'7px'}}></div>
                                   /*<h3>&nbsp;</h3> */}
                                </React.Fragment>) }
                                 <p style={{float:'left',marginBottom:'5px'}}>
                                     Price Per day: 
                                     <b>{ele.price_in_sun} &nbsp;SUN </b>
                                 </p> 
                                <p style={{float:'left'}}>Energy: <b>{(ele.energy)}</b></p>
                            </div> 
                             <div className="col-lg-3">
                                <small>Freeze Duration</small>
                                <h3>3 days</h3>
                                <p style={{float:'left'}}>Date: {moment(ele.created_at).format('DD-MM-YYYY')}</p>
                              </div>
                             {(this.state.accountBalance !==null && this.state.type_order !=='recent_orders' && this.state.type_order !=='open_orders' && this.state.type_order !=='my_completed_orders' && ele.by_address  !== (this.state.receiver_address)) ?  
                              <div className="col-lg-3 order-tab">
                                  <input className="btn_1" type="submit" value="Sell Energy" onClick={(e)=>this.SellModelOpen(e,ele)}/>
                              </div> : (
                                this.state.type_order==='recent_orders' || this.state.type_order==='open_orders' || this.state.type_order==='my_completed_orders' || this.state.type_order==='my_completed_orders'?
                                 <div className="col-lg-3 order-tab">
                                     {this.state.type_order==='my_completed_orders'?(
                                      this.state.receiver_address===ele.by_address ? 
                                      (<React.Fragment>
                                        <h3 style={{marginRight:'15px'}}>Buy</h3>
                                      </React.Fragment>) : (this.state.receiver_address===ele.pto ? 
                                         <h3 style={{marginRight:'15px'}}>Sell</h3>
                                       : '') 
                                    ):('')}

                                    {this.state.type_order=='open_orders'?(
                                      <React.Fragment>
                                         <div className="btn_1" onClick={(e)=>this.cancelOrder(e,ele.order_id)}>Cancel</div>&nbsp;
                                      </React.Fragment>
                                    ):('')}
                                     <a className="btn_1" href={`${TronConfig.transactionUrl}/#/transaction/${ele.txn_id}`} target="_blank">TXN Hash</a><br></br>
                                 </div>
                                 : ('') 
                              ) }
                        </div>
                    </div>
                    ):
                    (
                      <div className="akstrip_list wow fadeIn">
                        <div className="row">
                          <div className="col-lg-12">
                            <h3>No Orders ...</h3>
                          </div>
                        </div>
                      </div>
                    )
                   } 

                   {/* Pagination Tab 1*/}
                   {Object.keys(this.state.pagination).length > 0  ? 
                      this.state.pagination.last_page !==1 ?
                      (
                      <nav aria-label="" className="add_top_20">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakLabel={'...'}
                          pageCount={this.state.pagination.last_page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={(e)=>this.handlePagination(e.selected+1)}
                          containerClassName={'pagination pagination-sm'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                          breakClassName={'page-item'}
                          breakLinkClassName={'page-link'}
                          previousLinkClassName={'page-item'}
                          nextLinkClassName={'page-item'}
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          forcePage={this.state.pageNumber}
                        />
                      </nav>
                      ) : ('') : ('') } 
                 </div>

                 <div className="col-lg-12">
                   <div className="switch-field">
                     <input type="radio" id="doctorss" name="type_patient" value="recent_orders"/>
                     <label for="doctorss" style={{backgroundColor:'#1E6DB2',boxShadow: 'none',color:'#fff'}}>Recent Orders</label>
                    </div> 
                 {this.state.recentOrders.length > 0 ?  
                  this.state.recentOrders.map((ele,key)=>
                    <div className="akstrip_list" key={key}>
                        <div className="row">
                            <div className="col-lg-3" id={ele.order_id}>
                             <div> 
                                <small>Payout</small>
                                <h3><h3>
                                    { ('__MinAmountTrx' in this.state.commission) ?
                                          getPayOut(decodeBigNumber(this.state.commission['__MinAmountTrx']), decodeBigNumber(this.state.commission['__adminCharges']), decodeBigNumber(this.state.commission['__referralCharges']) ,decodeBigNumber(this.state.commission['__payableAmountPercentage']), ele.amount)
                                     : ''
                                     } TRX
                                  </h3></h3>
                                <p style={{marginBottom:'5px'}}>Min Payout: <b>
                                    { ('__MinAmountTrx' in this.state.commission) ?
                                          getPayOut(decodeBigNumber(this.state.commission['__MinAmountTrx']), decodeBigNumber(this.state.commission['__adminCharges']), decodeBigNumber(this.state.commission['__referralCharges']) ,decodeBigNumber(this.state.commission['__payableAmountPercentage']), ele.amount)
                                     : ''
                                     } TRX </b></p>
                                <p style={{float:'left'}}>Energy: <b>{ele.energy}</b></p>
                             </div> 
                            </div>
                            <div className="col-lg-3">
                              {this.state.accountBalance ?
                                 <React.Fragment>
                                    <small>Freeze</small>
                                   <h3>{(ele.energy/this.state.EnergyByFreezingTrx).toFixed(0)} TRX</h3>
                                 </React.Fragment>
                                :(<React.Fragment>
                                   {<div style={{marginTop:'7px'}}></div>
                                   /*<h3>&nbsp;</h3> */}
                                </React.Fragment>) }
                                 <p style={{float:'left',marginBottom:'5px'}}>
                                     Price Per day: 
                                     <b>{ele.price_in_sun} &nbsp;SUN </b>
                                 </p> 
                                <p style={{float:'left'}}>Energy: <b>{(ele.energy)}</b></p>
                            </div> 
                             <div className="col-lg-3">
                                <small>Freeze Duration</small>
                                <h3>3 days</h3>
                                <p style={{float:'left'}}>Date: {moment(ele.created_at).format('DD-MM-YYYY')}</p>
                              </div>
                                 <div className="col-lg-3 order-tab">
                                     <a className="btn_1" href={`${TronConfig.transactionUrl}/#/transaction/${ele.txn_id}`} target="_blank">TXN Hash</a><br></br>
                                 </div>
                           </div>
                    </div>
                    ):
                    (
                      <div className="akstrip_list wow fadeIn">
                        <div className="row">
                          <div className="col-lg-12">
                            <h3>No Orders ...</h3>
                          </div>
                        </div>
                      </div>
                    )
                   }
                      {/* Pagination Tab 2*/}
                      {Object.keys(this.state.pagination2).length > 0  ? 
                      this.state.pagination2.last_page !==1 ?
                      (
                      <nav aria-label="" className="add_top_20">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakLabel={'...'}
                          pageCount={this.state.pagination2.last_page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={(e)=>this.handlePagination2(e.selected+1)}
                          containerClassName={'pagination pagination-sm'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                          breakClassName={'page-item'}
                          breakLinkClassName={'page-link'}
                          previousLinkClassName={'page-item'}
                          nextLinkClassName={'page-item'}
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          forcePage={this.state.pageNumber2}
                        />
                      </nav>
                      ) : ('') : ('') }  
                </div>
            </div>
          </div>
        </main>            
       ) : (<React.Fragment> <Loader/>  </React.Fragment>)}
       {this.state.notify.visible?(<Notify attr={this.state.notify}/>):('')}
       </React.Fragment>
      )  
    }
}

export default Energy