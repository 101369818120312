import React from 'react';


class Faq extends React.Component{
  render(){
      return(
          <React.Fragment>
            <main>  
			<div id="breadcrumb">
				<div className="container">
					<ul>
						<li><a href="/">Home</a></li>
						<li>FAQ</li>
					</ul>
				</div>
			</div>
           <div className="container margin_60">
			<div className="row">
				
				<aside className="col-lg-3" id="sidebar">
                    <div className="box_style_cat" id="faq_box">
                        <ul id="cat_nav">
                            <li><a href="#payment" className="active"><i className="icon_document_alt"></i>Payments</a></li>
                            <li><a href="#tips"><i className="icon_document_alt"></i>Usage of Audit Report</a></li>
                            <li><a href="#terms"><i className="icon_document_alt"></i>Terms &amp; conditons</a></li>
                        </ul>
                    </div>
				</aside>
				
				<div className="col-lg-9" id="faq">
					
					<h4 className="nomargin_top">Payments</h4>
					<div role="tablist" className="add_bottom_45 accordion" id="payment">
						<div className="card">
							<div className="card-header" role="tab">
								<h5 className="mb-0">
									<a data-toggle="collapse" href="#collapseOne_payment" aria-expanded="true"><i className="indicator icon_minus_alt2"></i>What is Audit token?</a>
								</h5>
							</div>

							<div id="collapseOne_payment" className="collapse show" role="tabpanel" data-parent="#payment">
								<div className="card-body">
									<p style={{color:'#221f1f'}}>Audit token helps you to request audit for the contract. You can request priority audits by submitting priority audit request which will need you to spend audit tokens.</p>
									<p style={{color:'#221f1f'}}>Priority audit just means we would take the audit on priority from the queue of audits. The Audit tokens are just charges to have it prioritized its not charges for audit fees</p>
								</div>
							</div>
						</div>
						
						<div className="card">
							<div className="card-header" role="tab">
								<h5 className="mb-0">
									<a className="collapsed" data-toggle="collapse" href="#collapseTwo_payment" aria-expanded="false">
										<i className="indicator icon_plus_alt2"></i>How can I get Audit Token?
									</a>
								</h5>
							</div>
							<div id="collapseTwo_payment" className="collapse" role="tabpanel" data-parent="#payment">
								<div className="card-body">
									<p style={{color:'#221f1f'}}>Aim for audit token is to prevent fake audit request. This is for the community and by the community. Audit tokens would be distributed to community via telegram channel. If you are not part of community you would be needing to purchase audit tokens. You can purchase the same here</p>
								</div>
							</div>
						</div>
						
						<div className="card">
							<div className="card-header" role="tab">
								<h5 className="mb-0">
									<a className="collapsed" data-toggle="collapse" href="#collapseThree_payment" aria-expanded="false">
										<i className="indicator icon_plus_alt2"></i>Refunds
									</a>
								</h5>
							</div>
							<div id="collapseThree_payment" className="collapse" role="tabpanel" data-parent="#payment">
								<div className="card-body">
									<p style={{color:'#221f1f'}}>Refunds in form of Audit token would be provided to user if in any case we decide to cancel the request of audit. In other cases if the audit request is processed we would not be providing any refunds.</p>
								</div>
							</div>
						</div>
						
					</div>
					
					<h4 className="nomargin_top">Usage of Audit Report</h4>
					<div role="tablist" className="add_bottom_45 accordion" id="tips">
						<div className="card">
							<div className="card-header" role="tab">
								<h5 className="mb-0">
									<a data-toggle="collapse" href="#collapseOne_tips" aria-expanded="true"><i className="indicator icon_plus_alt2"></i>General Terms</a>
								</h5>
							</div>

							<div id="collapseOne_tips" className="collapse" role="tabpanel" data-parent="#tips">
								<div className="card-body">
									<p style={{color:'#221f1f'}}>This audit reports are to be used only for the people looking forward to the smart contract. This should not be used in anyway for marketing by smart contract owners unless & until they exclusively get approval to do so.</p>
									<p style={{color:'#221f1f'}}>This audit reports are used for the community where community request the audit for such contracts. We do not intend this to be used for any marketig purposes unless and until its been approved by Admins.</p>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header" role="tab">
								<h5 className="mb-0">
									<a data-toggle="collapse" href="#collapseTwo_tips" aria-expanded="true"><i className="indicator icon_plus_alt2"></i>Verified Seal</a>
								</h5>
							</div>

							<div id="collapseTwo_tips" className="collapse" role="tabpanel" data-parent="#tips">
								<div className="card-body">
									<p style={{color:'#221f1f'}}>The verified seal would only be issued to the contract owners who meet certain criteria and they would be needing to apply for the same by submiting the request here.</p>
									
								</div>
							</div>
						</div>
					</div>
					
					<h4 className="nomargin_top">Terms &amp; conditons</h4>
					<div role="tablist" className="add_bottom_45 accordion" id="terms">
						<div className="card">
							<div className="card-header" role="tab">
								<h5 className="mb-0">
									<a data-toggle="collapse" href="#collapseOne_terms" aria-expanded="true"><i className="indicator icon_plus_alt2"></i>Priority Audit Terms</a>
								</h5>
							</div>

							<div id="collapseOne_terms" className="collapse" role="tabpanel" data-parent="#terms">
								<div className="card-body">
									<p style={{color:'#221f1f'}}>We do not take any responsibility or liabilities for any of the contract. We would simply look out just for exit code in our audit reports. If in any case the contract functions fails or doesnt work we would not be help responsible for the same as we do not audit the contract for any faults or errors. Our main intension of audit is to look out for audit errors</p>
									<p style={{color:'#221f1f'}}>Defi Audits Team has full rights to cancel audit request at any given time. And in case of cancellation of the request Audit tokens would be refunded if that is priority audit</p>
									<p style={{color:'#221f1f'}}>The audit makes no statements or warranties about the suitability or sustainability of the business model or regulatory regime for the business model. Do take into consideration that you are doing all financial actions & transactions at your own risk, especially if you are dealing with high-risk projects / Dapps.</p>
									<p style={{color:'#221f1f'}}>We are not a certified financial advisor, We are not a certified audit firm. We are just helping the community. We would not be responsible for any loss or profit. Invest only what you can risk to lose. We might have missed anything in code audits however we try our best as human to audit with full precision. </p>
									<p style={{color:'#221f1f'}}>Priority audit just means we would take the audit on priority from the queue of audits. The Audit tokens are just charges to have it prioritized its not charges for audit fees</p>
								</div>
							</div>
						</div>
						
						<div className="card">
							<div className="card-header" role="tab">
								<h5 className="mb-0">
									<a className="collapsed" data-toggle="collapse" href="#collapseTwo_terms" aria-expanded="false">
										<i className="indicator icon_plus_alt2"></i>General Terms
									</a>
								</h5>
							</div>
							<div id="collapseTwo_terms" className="collapse" role="tabpanel" data-parent="#terms">
								<div className="card-body">
									<p style={{color:'#221f1f'}}>FOR ROI Based DAPPS | Ponzi & Pyramid  DAPPS: You can only get paid dividend (We have not reviewed dividend function), not the actual amount invested that to in incremental fashion</p>
									<p style={{color:'#221f1f'}}>FOR ROI Based DAPPS | Ponzi & Pyramid  DAPPS: If there are no TRX in the contract which will happen someday you would be get paid whatever is available in the contract. ROI based projects are mostly Ponzi schemes they follow pyramid structure where the last one to invest loses the money. We would not recommend investing in it. We can help just to see if there are exit scam in the code or not.</p>
									<p style={{color:'#221f1f'}}>We can not do anything if the website suddenly changes the function to be deposit when you try to withdraw educate yourself to see what you accept in tron wallet. </p>
									<p style={{color:'#221f1f'}}>We can not predict if the website will stay 100% up to as we are not the owner of the contract. We can help you to interact with the contract and get you to withdraw your withdrawable funds based on smart contract if there is balance available in the smart contract. </p>
									<p style={{color:'#221f1f'}}>We can not predict pumps and dumps We can only see what's written in the smart contract. We also can not predict if someone can manipulate the system and get rid of TRX balance of the contract.</p>
									<p style={{color:'#221f1f'}}>We are not a certified financial advisor, We are not a certified audit firm. We are just helping the community. We would not be responsible for any loss or profit. Invest only what you can risk to lose. We might have missed anything in code audits however we try our best as human to audit with full precision. </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </main>  
          </React.Fragment>
      )
  }    
}

export default Faq