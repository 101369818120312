import React from 'react';
import axios from 'axios';
import {UrlString} from '../../config/apiRoutes';
import moment from 'moment';

class Auction extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
          data:'',
        }
    }

    componentDidMount(){
     axios.post(UrlString.BASE_URL+'/api/open/auctionBal')
     .then(res=>{
      if(res.data.status==='0'){
        this.setState({
          ...this.state,
          data:res.data.data
        })
      }
     })
     .catch(err=>{
      console.error(err);
     })  
    }

    priceWithComma=(x)=>{
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    countdown=()=>{
      var tommorrow = moment().add(1,'days').format('DD')
      var tommorrowM = moment().add(1,'days').format('MMM')
      var tommorrowY = moment().add(1,'days').format('YYYY')

      var countDownDate = new Date(`${tommorrowM} ${tommorrow}, ${tommorrowY} 00:00:00 GMT`).getTime();
      // Update the count down every 1 second 
       setInterval(function() {
        // Get today's date and time
        var now = new Date().getTime();
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // Time calculations for hours, minutes and seconds
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // Output the result in an element with id="demo"
        document.getElementById("countdown").innerHTML = "Closing in "+hours + "h : "
        + minutes + "m : " + seconds + "s";
      }, 1000);
    }

    render(){
      return(
        <React.Fragment>
        {/* <section className="">
        <div className="row">
          <div className="col text-center">
              <div className="card">
                <div className="card-body">
                    <div className="container">
                      <div className="row">
                          <div className="col-md-6 offset-md-3">
                            <div id="content">
                                <span className="refresh" onClick={()=>{window.location.reload()}}>
                                <i className="fa fa-refresh fa-lg" aria-hidden="true"></i>
                                </span>
                                <h1 style={{color:'white'}}>DEFI AUDITS</h1>
                               <p id="timer"><span id="countdown" className="timer-content">Closing in {this.countdown()}</span></p>
                                <p className="subtitle" style={{marginBottom:'0px'}}>Daily Auction Totals <span>LIVE</span></p>
                                <table className="trontable">
                                  <tbody>
                                      {this.state.data.length > 0 ?
                                       this.state.data.map((ele,key)=>
                                         <tr key={key}>
                                           <td>#{key+1}</td>
                                           <td className="header"><a href={ele.name==='CSE'?'https://csetron.github.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='T2X'?'https://t2xtoken.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn': (ele.name==='DSP'?'https://www.dsptoken.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='MOONS'?'https://defi.moonstaking.net/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='NUI'?'https://nuitoken.github.io/ui/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='GXY'?'http://www.gxystake.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='LCK'?'https://stake.luckve.com/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':''))))))} target="_blank">{ele.name}</a></td>
                                           <td className="value">{this.priceWithComma(parseInt(ele.balance/1000000))} TRX</td>
                                           <td>
                                           <a href={ele.name==='CSE'?'https://csetron.github.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='T2X'?'https://t2xtoken.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn': (ele.name==='DSP'?'https://www.dsptoken.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='MOONS'?'https://defi.moonstaking.net/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='NUI'?'https://nuitoken.github.io/ui/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='GXY'?'http://www.gxystake.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='LCK'?'https://stake.luckve.com/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':''))))))} target="_blank"><i className="fa fa-link" aria-hidden="true"></i></a> 
                                          </td>
                                         </tr> 
                                       )
                                      :(<tr><td>Please wait...</td></tr>)}
                                  </tbody>
                                </table>
                            </div>
                            <div id="footer">
                                <p><strong>Help us grow?</strong> Donate TRX - THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn</p>
                                <p className="footer-banner">
                                    <strong>Want to list something here?</strong> Contact me on
                                    <a href="https://t.me/defiaudit" target="_blank" rel="noopener noreferrer">&nbsp;Telegram</a>
                                </p>
                                <p style={{fontSize:'13px',marginBotton:'50px'}}>* All links above are referral links.</p>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
         </section> */}
         <main>
          <div id="hero_register">
            <div class="container">
              <div class="row justify-content-center text-center">
                <div class="col-xl-8 col-lg-8">
                  <div style={{background:'#f5f8fa',padding:'25px 40px 40px',margin:'auto',position:'relative',borderRadius:'15px',minHeight:'500px',marginTop:'100px',marginBottom:'100px'}}>
                    <h2>Auction stats</h2>
                    <div className="col text-center">
                      
                                      <div id="content">
                                          <span className="refresh" onClick={()=>{window.location.reload()}}>
                                          <i className="fa fa-refresh fa-lg" aria-hidden="true"></i>
                                          </span>
                                        <p id="timer"><span id="countdown" className="timer-content" style={{color:'#333'}}>Closing in {this.countdown()}</span></p>
                                          <p className="subtitle" style={{marginBottom:'0px',color:'#333'}}>Daily Auction Totals <span>LIVE</span></p>
                                          <table className="trontable">
                                            <tbody>
                                                {this.state.data.length > 0 ?
                                                this.state.data.map((ele,key)=>
                                                  <tr key={key}>
                                                    <td style={{color:'#333'}}>#{key+1}</td>
                                                    <td className="header"><a style={{color:'#2f88d5'}} href={ele.name==='CSE'?'https://csetron.github.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='T2X'?'https://t2xtoken.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn': (ele.name==='DSP'?'https://www.dsptoken.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='MOONS'?'https://defi.moonstaking.net/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='NUI'?'https://nuitoken.github.io/ui/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='GXY'?'http://www.gxystake.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='LCK'?'https://stake.luckve.com/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':"!#"))))))} alt="" target="_blank"  rel="noopener noreferrer">{ele.name}</a></td>
                                                    <td className="value" style={{color:'#333'}}>{this.priceWithComma(parseInt(ele.balance/1000000))} TRX</td>
                                                    <td>
                                                    <a style={{color:'#2f88d5'}} href={ele.name==='CSE'?'https://csetron.github.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='T2X'?'https://t2xtoken.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn': (ele.name==='DSP'?'https://www.dsptoken.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='MOONS'?'https://defi.moonstaking.net/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='NUI'?'https://nuitoken.github.io/ui/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='GXY'?'http://www.gxystake.io/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':(ele.name==='LCK'?'https://stake.luckve.com/?ref=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn':"!#"))))))} target="_blank" alt="" rel="noopener noreferrer"><i className="fa fa-link" aria-hidden="true"></i>  </a> 
                                                    </td>
                                                  </tr> 
                                                )
                                                :(<tr><td>Please wait...</td></tr>)}
                                            </tbody>
                                          </table>
                                      </div>
                                      <div id="footer">
                                          <p><strong>Help us grow?</strong> Donate TRX - THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn</p>
                                          <p className="footer-banner">
                                              <strong>Want to list something here?</strong> Contact me on
                                              <a href="https://t.me/defiaudit" target="_blank" rel="noopener noreferrer">&nbsp;Telegram</a>
                                          </p>
                                          <p style={{fontSize:'13px',marginBotton:'50px'}}>* All links above are referral links.</p>
                                      </div>
                        
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
	     </main>
      </React.Fragment>      
      )
    }
}

export default Auction