import axios from 'axios';
import React from 'react';
import TronWeb from "tronweb";
import {TronConfig,UrlString} from '../../config/apiRoutes';
import Utils from '../../config/util';
import Notify from '../SharedModule/Notify';
import Loader from '../SharedModule/Loader';
import {withRouter} from 'react-router-dom';
import {TrxResponseMessage} from '../../config/TrxMessage';

class BuyToken extends React.Component{
    constructor(props){
        super(props)
        this.state={
          tronWeb: {
            installed: false,
            loggedIn: false,
          },
          referral_address:'THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn',
          notify:{},
          errors:false,
          success:false,
          amount:"",
          msg:"",
          amountsend:0,
          trxamount:'',
          trxValue:10,
          accountBalance:0,
          submitBtn:false,
         }
         this.handleOnSubmit = this.handleOnSubmit.bind(this)
       }
    
       async componentDidMount() {
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("balance");
        await new Promise(resolve => {
          const tronWebState = {
            installed: !!window.tronWeb,
            loggedIn: window.tronWeb && window.tronWeb.ready
          };
          if (tronWebState.installed) {
            this.setState({
              tronWeb: tronWebState
            });
    
            return resolve();
          }
    
          let tries = 0;
    
          const timer = setInterval(() => {
            if (2e4 <= tries) {
              const TRONGRID_API = TronConfig.TRONGRID_API
              window.tronWeb = new TronWeb(
                TronConfig.fullHost,
                TronConfig.privateKey
              );
              this.setState({
                tronWeb: tronWebState
              });
              clearInterval(timer);
              return resolve();
            }
            
    
            tronWebState.installed = !!window.tronWeb;
            tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
            
            if (!tronWebState.installed) {
              return tries++;
            }
           
            this.setState({
              tronWeb: tronWebState
            },()=>{
               localStorage.setItem('loggedIn',tronWebState.loggedIn);
            });
    
            return resolve();
          },100);
        });
         
           // Set default address (foundation address) used for contract calls
           // Directly overwrites the address object if TronLink disabled the
           // function call
           if(!this.state.tronWeb.loggedIn) { 
            
            window.tronWeb.defaultAddress = {
              hex: window.tronWeb.address.toHex(TronConfig.FOUNDATION_ADDRESS),
              base58: TronConfig.FOUNDATION_ADDRESS
            };
            
    
            window.tronWeb.on('addressChanged', () => {
              if(this.state.tronWeb.loggedIn)
                  return;
                  this.fetchAccountBalance(); 
              this.setState({
                  tronWeb: {
                      installed: true,
                      loggedIn: true
                  }
              },()=>{
                console.log('login');
              });
          });
        }
        await Utils.setTronWeb(window.tronWeb);
        this.fetchSearchParam();
      }

      async fetchSearchParam(){
        const urlParams = new URLSearchParams(this.props.history.location.search);
        var myParam = null;
        myParam = urlParams.get('referral');
        if(myParam !==null){
          console.log(myParam)
        try{ 
          await window.tronWeb.contract().at(myParam);
          }catch(e){
            if(e.toString()==="Invalid contract address provided"){
              this.setState({
               notify:{
                 visible:true,
                 type:'warning',
                 msg:'Refferral id is invalid'
               },
               submitBtn:true
            },()=>{
              setTimeout(()=>{ this.setState({notify:{}})},3000)
            })
           }
          }
          this.setState({
            referral_address:myParam
          })
         } 
       }
    
      async fetchAccountBalance() {
      const balanceInSun = await window.tronWeb.trx.getBalance(); //number
     const balanceInTRX = await window.tronWeb.fromSun(balanceInSun); //string
      
      this.setState({
        accountBalance: balanceInTRX
      });
    }
    
      handleChange=e=>{
        let value=e.target.value;
        if(e.target.value <0 || e.target.value>50000)return false
        let calculate= value/this.state.trxValue;
        console.log(calculate)
        let valuesend=value*1000000;
        console.log(valuesend)
        this.setState({
          amount:e.target.value,
          amountsend:valuesend,
          trxamount:calculate,
          errorMessage:'',
          msg:'',
        })
       }

       handleChangeAd=e=>{
        if(e.target.value <0 || e.target.value>5000)return false 
        let value=e.target.value;
        let calculate= value*this.state.trxValue;
        let valuesend=calculate*1000000;
        console.log(valuesend)
        this.setState({
          amount:calculate,
          amountsend:valuesend,
          trxamount:value,
          errorMessage:'',
          msg:'',
        })
       }
    
      async handleOnSubmit(e){ 
        e.preventDefault();
        var myInput = document.getElementById('amountNo');
        console.log(myInput)
        if(!this.state.tronWeb.loggedIn){
        console.log('in')
          this.setState({
            ...this.state,
            notify:{
              visible:true,
              type:'warning',
              msg : 'You are not logged In'
            }
          },()=>{
            setTimeout(()=>{
             this.setState({ notify:{} })
            },5000)
          });
          return false;
        }
        if (this.state.amount === "" || this.state.amount.length === 0) {
          let errmessaged = "Please enter Amount";
          this.setState({ errorMessage: errmessaged });
           return false;
        }
        if(this.state.amountsend){
          this.setState({
            submitBtn:true
          })  
          this.depositeApi();
        }
      }
    
      async depositeApi(){
        const trondata  = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS);
        console.log(trondata);
        try{
          const transaction = await trondata.buyAuditTokensWithTRX(this.state.referral_address).send({
            feeLimit:300000000,
            referral_address: this.state.referral_address,
            callValue:this.state.amountsend
          });
          
         /* */
         const status = await TrxResponseMessage(transaction)
         console.log(status);
        console.log(status)
          if(status.txStatus==='SUCCESS'){
            this.fetchAccountBalance(); 
            this.setState({
              success:true,
              amount:"",
              amountsend:0,
              trxamount:0,
              submitBtn:false,
              notify:{
                visible:true,
                type:'success',
                msg : 'Token Successfully purchased'
              }
            },()=>{
              setTimeout(()=>{
                this.setState({notify:{}})
              },5000)
            })
          }else if(status.txStatus==='REVERT' || status.txStatus==='OUT_OF_ENERGY'){
            let msg = '';
            if(status.revertMessage==='insufficient balance'){
              msg = `You dont have enough Audit tokens to request the audits. You can get more audit tokens by contact admin on`
            
            }else if(status.txStatus==='OUT_OF_ENERGY' && status.revertMessage===""){
              msg = 'You are requested to borrow some energy to make this transaction'  
            
            }else{
              msg = "There is some issue please try again later"
            }

            this.setState({
              msg:msg,
              submitBtn:false,
            })
          }
        /* */
        }catch(e){
         if(e.hasOwnProperty('message')){
          let msg = '';
          if(e.message==="contract validate error : Validate InternalTransfer error, balance is not sufficient."){
            msg = 'You dont have enough Audit tokens to request the audits. You can get more audit tokens by contact admin on';
          }else{
            msg = 'There is some issue please again later';
          }
          this.setState({
            submitBtn:false,
            msg:msg
          },()=>{
            setTimeout(()=>{
              this.setState({
                notify:{},
                submitBtn:false
              })
            },5000)
          })  
         }else{
          console.log('2'); 
          this.setState({
           submitBtn:false,
           notify:{
             visible:true,
             type:'warning',
             msg:e
           }
          },()=>{
            setTimeout(()=>{
              this.setState({
                notify:{},
                submitBtn:false
              })
            },5000)
          }) 
         }
         console.error(e)
        } 
      }

  render(){
    return(
     <React.Fragment>
       {!this.state.loading ?
       (
        <main>
        <div id="hero_register">
          <div className="container margin_120_95">     
            <div className="row">
              <div className="col-lg-6 offset-md-3">
                <h1 style={{textAlign:'center',marginBottom:'40px'}}>Buy Token Audit</h1>
                <div className="box_form">
                  {this.state.msg.length===106?(<div className="alert alert-warning">{this.state.msg} <a href="/about">{window.location.origin}/about</a></div>):('')}
                  <div id="message-register"></div>
                  <form role="form" encType="multipart/form-data" id="requestForm" onSubmit={this.handleOnSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                         <div><label style={{float:'right',fontSize:'10px'}}>Balance: {this.state.accountBalance}</label></div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'white',fontSize:'12px'}}>From</span>
                            </div>
                            <input type="number" required="" id="amountNo" value={this.state.amount} name="name" min="10" max="999999999999" className="form-control" placeholder="0.0" onChange={this.handleChange} style={{borderColor:'#ced4da',borderRight:'1px solid #ffffff',borderLeft:'1px solid #ffffff',minHeight:'50px'}}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'white',borderLeft:'1px solid #ffffff'}}><img src="/assets/images/tron.png" style={{width:'23px'}} alt=""/>&nbsp;<small>TRX</small></span>
                            </div>
                             {this.state.errors.name ? (<span style={{color:'red',marginLeft:'6px'}}>{this.state.errors.name}</span>) :''}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                        <div style={{marginBottom:'25px'}}>
                        <label style={{float:'left',fontSize:'10px'}}>Price</label>
                          <label style={{float:'right',fontSize:'10px'}}>{this.state.trxValue} TRX per AD</label>
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'white',fontSize:'12px',borderRight:'1px solid #ffffff'}}>To</span>
                            </div>
                            <input type="number"  name="name" className="form-control" placeholder="0.0" value={this.state.trxamount} style={{borderColor:'#ced4da',borderRight:'1px solid #ffffff',borderLeft:'1px solid #ffffff',minHeight:'50px'}} onChange={this.handleChangeAd}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'white',borderLeft:'1px solid #ffffff'}}><img src="/assets/img/Defi_Audit_coin.png" alt="" style={{width:'25px'}}/>&nbsp;<small>AD</small></span>
                            </div>
                            </div>
                        </div>
                    </div>
                      <div className="row">
                        <div className="col-md-12">
                          <input type="submit" value="Swap" className="btn btn-primary" style={{width:'100%',padding:'12px'}} disabled={this.state.submitBtn}/>
                        </div> 
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </main>
       ) : (<Loader/>)}
       {this.state.notify.visible?(<Notify attr={this.state.notify}/>):('')}
     </React.Fragment>
    )
  }
}

export default withRouter(BuyToken)