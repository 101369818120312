import React from 'react';
import {withRouter} from 'react-router-dom';


class Header extends React.Component{
  
  render(){

    return(
   <React.Fragment>
    <div className="layer"></div>
    
    {/* <div id="preloader">
      <div data-loader="circle-side"></div>
    </div> */}


	<header className="header_sticky">
		<div className="container">
			<div className="row">
				<div className="col-lg-3 col-6">
					<div id="logo_home">
						<h1><a href="/" title="DefiAudits">Defi Audits</a></h1>
					</div>
				</div>
				<nav className="col-lg-9 col-6">
					<a className="cmn-toggle-switch cmn-toggle-switch__htx open_close" href={()=>false}><span>Menu mobile</span></a>
					<div className="main-menu">
						<ul>
							<li>
								<a href="/" >Home</a>
                            </li>
							<li className="submenu">
							  <a href="#0" className="show-submenu">Energy Exchange<i className="icon-down-open-mini"></i></a>
                              <ul>
							    <li><a href="/energy">Buy/Sell</a></li>	
								<li><a href="/voterewards">Vote Rewards</a></li>
							  </ul>
							</li>
							<li className="submenu">
								<a href="#0" className="show-submenu">Audits<i className="icon-down-open-mini"></i></a>
								<ul>
								<li><a href="/listing">View All audits</a></li>	
								  <li><a href="/request">Request An Audit</a></li>
								  <li><a href="/priority-request">Request Priority Audit</a></li> 
								  <li><a href="/pendinglist">Pending Audits</a></li> 
								  <li><a href="/buytoken?referral=THgjanNfXZBNdbujXRx6Svg7ktjcitGnhn">Buy Token</a></li>	
									{/* <li><Link to="/auction">Auction Stats</Link></li>
                  <li><a href="/subscription">subscription</a></li>
                  <li><Link to="/buytoken">Buy Token</Link></li> */}
               	</ul>
							</li>
							<li><a href="/faqs">FAQ</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contact">Contact Us</a></li>
              {/* {this.state.subscribe===0 ? (<li><a href="/edit-profile">Edit Profile</a></li>):('')} */}
          	</ul>
					</div>
				</nav>
			</div>
		</div>
		
	</header>
  </React.Fragment>
    )
  }
}

export default withRouter(Header)