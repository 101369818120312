import React from 'react';
import axios from 'axios';
import {TronConfig} from '../../config/apiRoutes';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

class Vote extends React.Component{
    
    constructor(props) {
        super(props);
        //this.handleClose = this.handleClose.bind(this);
        this.state = {
          load: true,
          votingList:[],
          voted:0,
          setVote:{},
          availableVote:0
        };
      }
    
    async componentDidUpdate(prevProps,nextProps){
      if(this.props.status && this.state.load){
        //console.log(this.props)
        //console.log(prevProps,nextProps,this.state.show);
        this.setState({
          load : false
        })
        const list = await axios(TronConfig.voteApi);
        const availableVotes = await axios(TronConfig.votedAddress+this.props.userAddress)
        const votedAdd = {};
        let votingList = [];
        let voted = 0;
      
       if(availableVotes.status===200){
        let votedList = await availableVotes.data.votes
         for(let x in votedList){
              voted = voted + availableVotes.data.votes.[x];
              votedAdd[x] = availableVotes.data.votes.[x]
             }
        }
        //console.log(votedAdd)
        if(list.data.data.length > 0){
         let res = await list.data.data;
         let i = 0;
         res.map(ele=>{
          if(ele.brokerage!==100 && i!==27 && ele.witnessType === 1){
            i++
            votingList.push(ele)
          }
         })
         this.setState({
          votingList : votingList,
          voted : voted,
          setVote : votedAdd,
          availableVote : this.props.totalVotes-voted
         },()=>{
           //console.log(this.state)
         })
        }
        
     }
    }  
    
    handleOnClose(){
     this.props.callbackValue()
      this.setState({
        load: true,
        votingList:[],
        voted:0,
        setVote:{}
      })
    }

    rewardCalculation(brokerage,lastCycleVotes,votesPercentage){
      var assumedFreezeValue = 10000
      var voteSharing = (100-brokerage)/100
      var lastCycleVotes = lastCycleVotes
      var totalVoteSharePercentage = (votesPercentage/100)
      var lastReward = ((assumedFreezeValue*4608000/(lastCycleVotes/totalVoteSharePercentage))*voteSharing) + ((460800/27)*voteSharing)*(assumedFreezeValue/lastCycleVotes)
      //console.log(voteSharing, lastCycleVotes, (lastReward).toFixed(2))
      return (lastReward).toFixed(2);
    }

    priceWithComma=(x)=>{
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    handleVote=(e,address)=>{
      //console.log(this.state.availableVote)
     let votes = e.target.value
      if(votes.length===0){
        delete this.state.setVote[address]
        //console.log('1')
        this.setState({
          setVote:{
            ...this.state.setVote
          }
        },()=>{ this.voteCounter(address,votes) })
      }else{
        this.setState({
          setVote:{
          ...this.state.setVote,
          [address] : parseInt(votes)
         },
       },()=>{ 
        //console.log(this.state.setVote)
        this.voteCounter(address,votes)  
        })
     }
    }
    
    voteCounter=(address,votes)=>{
      let voteCount = 0;
          for(let x in this.state.setVote){
            voteCount = voteCount + this.state.setVote[x];
          }
          if((this.props.totalVotes - voteCount) < 0){
            delete this.state.setVote[address]
            this.setState({
              setVote:{
                ...this.state.setVote
              }
            })  
          }else{
            this.setState({
              availableVote : this.props.totalVotes - voteCount
            })
          }
    }

    submitVote= async(e)=>{
      e.preventDefault();
      //console.log(this.state.setVote)
      if(Object.keys(this.state.setVote).length > 0){
        for(const x in this.state.setVote){
          if(this.state.setVote[x]===0){
            delete this.state.setVote[x] ;
          }
        }
        console.log(this.state.setVote)

         const transaction = await window.tronWeb.transactionBuilder.vote(this.state.setVote,this.props.userAddress);
         var signedTx = await window.tronWeb.trx.sign(transaction)
         var broastTx = await window.tronWeb.trx.sendRawTransaction(signedTx)

         if(broastTx.result===true){
          this.handleOnClose();
           MySwal.fire({
             title : '',
             icon : 'success',
             text : 'Your votes are successfully submitted'
           })
         }
      }else{
        MySwal.fire({
          title:'',
          icon : 'error',
          text : 'Vote number must more than 0...'
        })
      }
    }

    setAll=(e,address)=>{
      if(this.state.availableVote===0) return false;
      this.setState({
        setVote:{
         ...this.state.setVote,
         [address] : this.state.availableVote
        },
      },()=>{
        this.voteCounter(address,this.state.availableVote)  
        console.log(this.state.setVote)
      })
    }

    render(){
        return(
            <div className="modal-dialog">
              <div className="col-md-10">
                <div className="modal-content">
                 <div className="modal-header">
                   <h4 className="modal-title">Votes</h4>
                   <button type="button" className="close" data-dismiss="modal" onClick={()=>{this.handleOnClose()}}>&times;</button>
                 </div>
                  <div className="modal-body">
                    <div className="akstrip_list" style={{marginBottom:'5px'}}>
                      <div className="row">
                        <div className="col-md-6">
                          <small>Total Votes : {this.props.totalVotes}</small><br></br>
                          <small style={{marginBottom:'5px'}}> Available Votes : {this.state.availableVote}</small>
                        </div>
                        <div className="col-md-6">
                          <a style={{marginLeft:'14px'}} className="btn btn-warning xs" onClick={(e)=>this.submitVote(e)}>Submit Votes</a>
                          {/* <small><a href="#" style={{float:'right'}}>Clear Votes</a></small> */}
                        </div>
                      </div>
                    </div>
                    <div className="akstrip_list" style={{marginBottom:'5px',background:'#1E6DB2',color:'white',padding:'6px 29px 6px'}}>
                      <div className="row">
                          <div>
                             <small>You have {this.props.totalVotes} TRX frozen. This gives you {this.props.totalVotes} votes. <br></br> Showing daily rewards for voting with all {this.props.totalVotes} votes.</small>
                          </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="votes_list">
                    {this.state.votingList.length > 0 ? this.state.votingList.map((ele,key) =>   
                    (
                      <React.Fragment>
                        <div className="akstrip_list" style={{marginBottom:'5px',border:'1px solid #ededed',padding:'10px 10px 0px 10px'}} key={key}>
                          <div className="row">
                            <div className="col-md-6">
                              <p style={{marginBottom:'0px',overflow:'hidden'}}>{key+1}. {ele.name}</p>
                              <small style={{marginBottom:'5px',fontSize:'7px'}}>{ele.address}</small>
                                <p>Reward <span>{this.rewardCalculation(ele.brokerage,ele.lastCycleVotes,ele.votesPercentage)} TRX</span></p>
                            </div>
                            <div className="col-md-6">
                              <small>{this.priceWithComma(ele.realTimeVotes)}</small>
                              <div>
                                  <input type="number" className="form-control"  min="0" value={ this.state.setVote.[ele.address] ? this.state.setVote.[ele.address] : '' } onChange={(e)=>this.handleVote(e,ele.address)}/>
                                  <a href="#" style={{fontSize:'10px',float:'right'}} onClick={(e)=>this.setAll(e,ele.address)}>All in</a>
                              </div>
                            </div>
                          </div>
                        </div>
                    </React.Fragment>
                    )
                   ) : ('') }
                  </div>
                     
                </div>
              </div>
            </div>
          </div>  
        )
    }
}

export default Vote