import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {UrlString} from '../../config/apiRoutes';


class Verified extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data:{},
      load:false,
      status:'',
    }
  }
  
  componentDidMount(){
    let id = this.props.location.search
    id = id.split('=');
    if(id.length===2 && id[1].length>5){
    axios.post(UrlString.BASE_URL+'/api/open/VerifyCheck',{'report_id':id[1]})
    .then(res=>{
        if(res.status===200){
            console.log(res)
            this.setState({
            data : res.data.reportDetails,
            load:true
            },()=>{
            let contract_id = this.state.data.contract_id;
            axios.get('https://apilist.tronscan.org/api/contract?contract='+contract_id)
            .then(res=>{
                if(res.status===200){
                this.setState({
                    ...this.state,
                trxBalance:res.data.data[0].balance/1000000,
                transactionCount:res.data.data[0].trxCount
                })
                }
              })
            })
        }
    }).catch(err=>{
        if(typeof err.response !== 'undefined'){ 
        if(err.response.hasOwnProperty('status')){
            this.setState({
            ...this.state,
            status:err.response.status
            })
            }
        }
      })
    }else{
        this.props.history.push('/')
    }
  }

  notFoundException=()=>{
    this.props.history.push('/404')
  }

  ratings(rate){
    var x = Number(rate)% 1===0 ? true : false
    let ele = [];
    let remStar = Math.round(5-rate);
    remStar = (x===false)?remStar-1:remStar
  
    const loop=()=>{
      for(var i=1;i<=rate;i++){
        ele.push(<i key={Math.random()} className="icon_star voted"></i>)
      }
      if(!x){
        ele.push(<i key={Math.random()} className="icon_star-half_alt voted"></i>)
      }
      
      for(var j=1;j<=remStar;j++){
        ele.push(<i key={Math.random()} className="icon_star"></i>)
      }
         return ele
    }
  
  
    return (
      <React.Fragment>
         <span className="rating">
           {loop()}
        </span>
      </React.Fragment>
    )
  } 

  render(){
    const screenshots = Object.keys(this.state.data).length > 0 ? this.state.data.screenshots.split(','):''
    return(
     <React.Fragment>
    <main>
    	<div className="container margin_60">
        {Object.keys(this.state.data).length > 0 ?  
        this.state.data.is_scam===0 ? 
       (<div id="section_2">
        <div className="box_general_3">
            <div className="reviews-container">
                <div className="row">
                    <div className="col-lg-3">
                        <div id="review_summary" style={{backgroundColor:'white'}}>
                            <img src="/assets/img/Badge.jpg" width="150px;" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-10 col-9">
                                <div><h2>Verified & Audited</h2></div>
                                <p>
                                    This contract has been verified and audited by DefiAudits team. The audit summary is listed below.<br/><br/> Verification badge is valid and we confirm that this contract id: <b><a href={`https://tronscan.org/#/contract/${this.state.data.contract_id}`}>{this.state.data.contract_id}</a></b> is AUDITED by DEFI AUDIT team. 
                                </p>
                                </div>
                            <div className="col-lg-2 col-3"><small><strong>{this.state.data.rating}</strong></small></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>):(
        <div id="section_2">
        <div className="box_general_3" style={{backgroundColor:'#fad8d8'}}>
            <div className="reviews-container">
                <div className="row">
                    <div className="col-lg-3">
                        <div id="review_summary" style={{backgroundColor:'#fad8d8'}}>
                            <h2><i className="icon_error-triangle_alt" style={{color:'red',fontSize:'4.5rem'}}></i></h2>
                        </div>
                    </div>
                    <div className="col-lg-9">
                       <div className="row">
                            <div className="col-lg-10 col-9">
                                <div><h2>NOT VERIFIED OR MARKED AS SCAM</h2></div>
                                <p>
                                    This contract has <b>NOT been verified and audited by DefiAudits team</b>. Kindly use caution and make sure you do DYOR before investing.
                                </p>
                                
                                
                            </div>
                            <div className="col-lg-2 col-3"><small><strong>NOT VERIFIED</strong></small></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>):('')}
    {Object.keys(this.state.data).length===0 && this.state.load===true?
    (      <div id="section_2">
                        <div className="box_general_3" style={{backgroundColor:'#fad8d8'}}>
                            <div className="reviews-container">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div id="review_summary" style={{backgroundColor:'#fad8d8'}}>
                                            <h2><i className="icon_error-triangle_alt" style={{color:'red',fontSize:'4.5rem'}}></i></h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                       <div className="row">
                                            <div className="col-lg-10 col-9">
                                                <div><h2>NOT VERIFIED OR MARKED AS SCAM</h2></div>
                                                <p>
                                                    This contract has <b>NOT been verified and audited by DefiAudits team</b>. Kindly use caution and make sure you do DYOR before investing.
                                                </p>
                                                
                                                
                                            </div>
                                            <div className="col-lg-2 col-3"><small><strong>NOT VERIFIED</strong></small></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
     ):('')}

	<div className="row">
                
      {Object.keys(this.state.data).length > 0 ? 
        <React.Fragment>
        <aside className="col-xl-3 col-lg-4" id="sidebar">
					<div className="box_profile">
						<figure>
							<img src={this.state.data.logo.length > 0 ? `${UrlString.BASE_URL}${this.state.data.logo}`:'/assets/images/defi-channel.jpg'} referrerPolicy="no-referrer" className="img-fluid" alt=""/>
						</figure>
            <small>{this.state.data.blockchain} App</small>
						<h6>{this.state.data.name}</h6>
            {this.ratings(this.state.data.rating)}
            <a href={()=>false} data-toggle="tooltip" data-placement="top" data-original-title="Badge Level" className="badge_list_1">
            {this.state.data.is_scam===1 ? 
              (<img src="/assets/img/badges/badge_5.svg" width="15" height="15" alt=""/>): (<img src="/assets/img/badges/badge_6.svg" width="15" height="15" alt=""/>)
            }
            </a>
						
            <ul className="statistic">
							<li>{this.state.trxBalance} TRX</li>
						</ul>
						<ul className="contacts">
							<li style={{wordBreak:'break-all'}}><h6>Contract Address</h6><a href={`https://tronscan.org/#/contract/${this.state.data.contract_id}`}>{this.state.data.contract_id}</a></li>
            <li><h6>Website</h6><a href={this.state.data.website_link} target="_blank" rel="noopener noreferrer">{this.state.data.name}</a></li>
            <li><h6>Transaction Count</h6><p>{this.state.transactionCount}</p></li>
					 
          	</ul>
						<div className="text-center"><a href={this.state.data.website_link} className="btn_1 outline" target="_blank" rel="noopener noreferrer"><i className="icon-globe"></i> Visit Website</a></div>
					</div>
				</aside>
	
				
				<div className="col-xl-9 col-lg-8">

					<div className="tabs_styled_2">
						<ul className="nav nav-tabs" role="tablist">
							<li className="nav-item">
								<a className="nav-link active" id="book-tab" data-toggle="tab" href="#book" role="tab" aria-controls="book">Audit Summary</a>
							</li>
						</ul>
	

						<div className="tab-content">
							
							<div className="tab-pane fade show active" id="book" role="tabpanel" aria-labelledby="book-tab">
              {this.state.data.is_scam===1 ? 
               (<div className="message">
								<p>This project has been reported as Exit Scam. Please be careful before investing</p>
							</div>) : ('')
              }
								<div className="indent_title_in">
									<i className="pe-7s-news-paper"></i>
									<h3>Audit Report</h3>
									<p>A brief summary of audit report. </p>
								</div>
								
							  <div dangerouslySetInnerHTML={{ __html: this.state.data.audit_report }} />

                {this.state.data.screenshots.length> 0 ?
                (<React.Fragment>
                <div className="indent_title_in">
									<i className="pe-7s-news-paper"></i>
									<h3>Source Code</h3>
									<p>This is images of source code audited by us. </p>
								</div>
                 {screenshots.map((ele,key)=>
                  <React.Fragment>
                  <div className="row">
                    <div className="col-12"> <img src={`${UrlString.BASE_URL}/${ele}`} referrerPolicy="no-referrer" style={{maxWidth:'-webkit-fill-available'}} alt=""/> </div>
                  </div>
                  <hr/>
                  </React.Fragment>
                )}
								</React.Fragment>):(<hr/>)} 
								<div className="indent_title_in">
									<i className="pe-7s-info"></i>
									<h3>Disclaimers</h3>
									<p>Please read this carefully. We are not financial advisor and we are not professional audit firm.</p>
								</div>

								<div className="wrapper_indent">
                  <div dangerouslySetInnerHTML={{ __html: this.state.data.signature? this.state.data.signature:''  }} />
								</div>
                <hr/>
							</div>
            </div>
           </div> 
          </div></React.Fragment>:('')}
         </div>
        </div>   
					</main>
     </React.Fragment>
    )
  }
}

export default withRouter(Verified)