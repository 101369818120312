import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {TronConfig,UrlString} from '../../config/apiRoutes';
import Utils from '../../config/util';
import TronWeb from "tronweb";
import {TrxResponseMessage} from '../../config/TrxMessage';
import Notify from '../SharedModule/Notify';

class PendingReport extends React.Component{
  constructor(props){
    super(props)
    this.state = {
     data:{},
     msg: 'No Data',
     pagination:{},
     search:'',
     params:'',
     sort:'',
     category:'all',
     blockchain:'all',
     loading:false,
     defi_report_id:'',
     contract_id:'',
     amount_unit256:"",
     is_zero:null,
     userAddress:'',
     address:null,
     notify:{},
     requestBtn:false,
     requestAuditModel:{
      visible:false,
      style:{
        display:"none"
      }
     }
    }
  }
  
  async componentDidMount(){
       this.getAllPendingList();
       localStorage.removeItem("loggedIn");
    localStorage.removeItem("balance");
    await new Promise(resolve => {
      const tronWebState = {
        installed: !!window.tronWeb,
        loggedIn: window.tronWeb && window.tronWeb.ready
      };
      if (tronWebState.installed) {
        this.setState({
          tronWeb: tronWebState
        });

        return resolve();
      }

      let tries = 0;

      const timer = setInterval(() => {
        if (2e4 <= tries) {
          window.tronWeb = new TronWeb(
            TronConfig.fullHost,
            TronConfig.privateKey
          );
          this.setState({
            tronWeb: tronWebState
          });
          clearInterval(timer);
          return resolve();
        }
        

        tronWebState.installed = !!window.tronWeb;
        tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
        
        if (!tronWebState.installed) {
          return tries++;
        }
       
        this.setState({
          tronWeb: tronWebState
        },()=>{
           localStorage.setItem('loggedIn',tronWebState.loggedIn);
        });

        return resolve();
      },100);
    });
     
       // Set default address (foundation address) used for contract calls
       // Directly overwrites the address object if TronLink disabled the
       // function call
       if(!this.state.tronWeb.loggedIn) {  
        window.tronWeb.defaultAddress = {
          hex: window.tronWeb.address.toHex(TronConfig.FOUNDATION_ADDRESS),
          base58: TronConfig.FOUNDATION_ADDRESS
        };
        
        window.tronWeb.on('addressChanged', () => {
          if(this.state.tronWeb.loggedIn)
              return;

          this.setState({
              tronWeb: {
                  installed: true,
                  loggedIn: true
              }
          },()=>{
            console.log('login');
          });
      });
    }

    await Utils.setTronWeb(window.tronWeb);
    this.fetchBalance();
  }

    async getAllPendingList(){
      try{
        axios.post(UrlString.BASE_URL+'/api/open/getAllPendingReports')
        .then(res=>{
          if(res.status===200){
            this.setState({
              data: res.data.reports.data,
              pagination:res.data,
              dataCount: res.data.reports.data.length
            },()=>{
              //console.log(this.state.dataCount)
            })
          }
        })
        this.setState({
          notify:{},
          requestBtn:false
        })
      }catch(e){
        console.error(e)
      }
    }

    async fetchBalance(){
      const trondata = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS)
      //console.log(trondata)
      try{
       const walletAdd = trondata.tronWeb.defaultAddress.base58;
     
       const balance = await window.tronWeb.trx.getBalance();
       
       const balanceInTRX = window.tronWeb.fromSun(balance); 
       //console.log(balanceInTRX);
       this.setState({
         ...this.state,
         balance:balanceInTRX,
         userAddress:walletAdd,
        // msg: balanceInTRX <=100 ? 'You dont have enough trx balance':'' 
       },()=>{
        // console.log(this.state.balance)
       })
      }catch(e){
        console.error(e);
      }
    }
    
   handleChange=e=>{
      this.setState({
        notify:{},
        amount_unit256:e.target.value
     }) 
   }

   handlePagination(e,pageNum){
     e.preventDefault();
     this.setState({
     ...this.state,
      //loading:true
     },()=>{
      axios.post(UrlString.BASE_URL+'/api/open/getAllPendingReports?page='+pageNum)
      .then(res=>{
        if(res.status===200){
          this.setState({
            data: res.data.reports.data,
            pagination:res.data,
            //loading:false
          })
        }
      })
     })
   }
  
   handleAddBtn=(e,id,contract_id,is_zero=null,address=null)=>{
    e.preventDefault();
    this.setState({
      defi_report_id:id,
      notify:{},
      contract_id:contract_id,
      address:address,
      is_zero:is_zero,
      requestAuditModel:{
        visible:true,
        style:{
          display:"block",
          backgroundColor:'#000000cf'
        }
      }
    })
   }

   requestAuditModelClose=e=>{
    this.setState({
      notify:{},
      submitBtn:false,
      requestAuditModel:{
        visible:false,
        style:{
          display:"none"
        }
      }
    })
  }

   handleAmountUnit=async e=>{
    e.preventDefault(); 
    
    if(this.state.userAddress.length===0){
       this.setState({
         notify:{
           visible:true,
           type:'warning',
           msg:'Please connect your wallet'
         },
         requestAuditModel:{
           visible:false,
           style:{
             display:'none'
           }
         }
       },()=>{
         setTimeout(()=>{
          this.setState({
            notify:{}
          })
         },3000)
       })
       return false
    }

    if(this.state.amount_unit256 < 10)return false
    this.setState({
      requestBtn:true
    })
    if(this.state.is_zero && this.state.address !==null){
      console.log('requestAuditApi')
      this.requestAuditApi();
    }else{
      console.log('addPriority')
      this.addPriority();
    } 
  }

  async requestAuditApi(){
    console.log('in');
    let amount_unit256 = this.state.amount_unit256*1000000 ;
    let contractAddress_address = this.state.address ;
    const trondata  = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS);
    //const address = trondata.tronWeb.defaultAddress.base58;
    console.log(trondata);
    try{
      const transaction = await trondata.requestAudit(amount_unit256,contractAddress_address).send({
         feeLimit:300000000,
         callValue:amount_unit256.toString(),
        _contractAddress:contractAddress_address
      });
      
      if(transaction){
        console.log(transaction)
        const status = await TrxResponseMessage(transaction)
        console.log(status)
          if(status.txStatus==='SUCCESS'){
            const trondata = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS)
            const x = await trondata.getContractIdByAddress(contractAddress_address).call()
            const id = window.tronWeb.toBigNumber(x._hex)
            console.log(id.c[0],x)
            this.setState({
              requestBtn:false,
              is_zero:null,
              address:null,
              requestAuditModel:{
                visible:false,
                style:{
                  display:"none"
                }
              }
            },()=>{
              if(transaction){
                this.formSubmit2(id);
              }
            })
          }else if(status.txStatus==='REVERT' || status.txStatus==='OUT_OF_ENERGY'){
            let msg = '';
            if(status.revertMessage==='insufficient balance'){
              msg = `You dont have enough Audit tokens to request the audits. You can get more audit tokens by contact admin on`
            
            }else if(status.revertMessage==="r audit"){
              msg = 'Contract is already submitted for audit'
           
            }else if(status.txStatus==='OUT_OF_ENERGY' && status.revertMessage===""){
              msg = 'You are requested to borrow some energy to make this transaction'  
            
            }else{
              msg = "There is some issue please try again later"
            }

            this.setState({
              notify:{
                visible:true,
                type:'warning',
                msg:msg
              },
              requestBtn:false,
              is_zero:null,
              address:null,
              requestAuditModel:{
                visible:false,
                style:{
                  display:"none"
                }
              },
            },()=>{
              console.log(this.state)
            })
          }
        
      }

    }catch(e){
      console.error(e);
      let err = e.toString()
      let invalid = /(i|I)nvalid address/g
      let status = invalid.test(err)
      console.log(status);
      if(status){
        this.setState({
          ...this.state,
          loading:false,
          is_zero:null,
          address:null,
          notify:{
            visible:true,
            type:'warning',
            msg : 'Contract id is invalid'
          },
          requestBtn:false,
          requestAuditModel:{
            visible:false,
            style:{
              display:"none"
            }
          }
        })
      }else{
        this.setState({
          ...this.state,
          loading:false,
          is_zero:null,
          address:null,
          notify:{
            visible:true,
            type:'warning',
            msg : e
          },
          requestBtn:false,
          requestAuditModel:{
            visible:false,
            style:{
              display:"none"
            }
          }
        })
      }
    } 
  }

 async addPriority(){
    const trondata = await window.tronWeb.contract().at(TronConfig.FOUNDATION_ADDRESS)
    const amount = parseInt(this.state.amount_unit256);
    const contractId = this.state.contract_id;
    try{
      const txId = await trondata.addPriority(amount,contractId).send({
        feeLimit:300000000,
        amount:30,
        contractId:contractId
      });
      const status = await TrxResponseMessage(txId)
      if(status.txStatus==='SUCCESS'){
         this.formSubmit();
      }else if(status.txStatus==='REVERT' || status.txStatus==='OUT_OF_ENERGY'){
        let msg = '';
        if(status.revertMessage==='insufficient balance'){
          msg = `You dont have enough Audit tokens to request the audits. You can get more audit tokens by contact admin on`
        
        }else if(status.revertMessage==="r audit"){
          msg = 'Contract is already submitted for audit'
       
        }else if(status.txStatus==='OUT_OF_ENERGY' && status.revertMessage===""){
          msg = 'You are requested to borrow some energy to make this transaction'  
        
        }else{
          msg = "There is some issue please try again later"
        }

        this.setState({
          submitBtn:false,
          requestBtn:false,
            notify:{
              visible:true,
              type:'warning',
              msg : msg
            }
        })
      }

    }catch(e){
      console.log(e)
      if(e==='Confirmation declined by user'){
        this.setState({
          requestBtn:false,
          notify:{
            visible:true,
            type:'warning',
            msg : e
          },
          requestAuditModel:{
            visible:false,
            style:{
              display:"none"
            }
          }
        })
      }
    }
  }

  formSubmit(){
    const formdata =  new FormData();
    formdata.append('defi_report_id',this.state.defi_report_id);
    formdata.append('audit_tokens',this.state.amount_unit256);
    formdata.append('requested_by',this.state.userAddress);

    axios.post(UrlString.BASE_URL+'/api/open/priorityRequestToken',formdata)
    .then(res=>{
      if(res.status===200){
        //console.log(res.data)
        if(res.data.status==='1'){
          this.setState({
            requestBtn:false,
            notify:{
              visible:true,
              type:'success',
              msg : 'Audit add successfully'
            },
            requestAuditModel:{
              visible:false,
              style:{
                display:'none'
              }
            } 
          },()=>{
            setTimeout(()=>this.getAllPendingList(),1000)
          })
        }
      }
    })
    .catch(err=>{
     console.log(err)
    })
  }

  formSubmit2(contract_key){
    console.log(contract_key)
    const formdata =  new FormData()
    formdata.append('defi_report_id',this.state.defi_report_id);
    formdata.append('audit_tokens',this.state.amount_unit256);
    formdata.append('requested_by',this.state.userAddress);
    formdata.append('contract_key',contract_key)
    axios.post(UrlString.BASE_URL+'/api/open/AddAuditToZero',formdata)
    .then(res=>{
      if(res.status===200){
        //console.log(res.data)
        if(res.data.status==='1'){
          this.setState({
            requestBtn:false,
            notify:{
              visible:true,
              type:'success',
              msg : 'Audit add successfully'
            },
            requestAuditModel:{
              visible:false,
              style:{
                display:'none'
              }
            } 
          },()=>{
            setTimeout(()=>this.getAllPendingList(),1000)
          })
        }
      }
    })
    .catch(err=>{
     console.log(err)
    })
  
  }
   
   RequestAudit(){
    return(
        <div className="modal-dialog" style={{marginTop:'100px'}}>
          <div className="modal-content">
          <div className="modal-header">
             <h4 className="modal-title">Add Priority</h4>
              <button type="button" className="close" data-dismiss="modal" onClick={this.requestAuditModelClose}>&times;</button>
            </div>
            <div className="modal-body">
            <input
              type="number"
              min="10"
              placeholder="Enter amount"
              name="amount_unit256"
              className="form-control"
              style={{ width: "100%" }}
              onChange={this.handleChange}
            />
             <span style={{color:'red',fontSize:'10px',margin:'10px'}}>*Amount should be greater than 10</span>
            </div>
            <div className="modal-footer" style={{display:'block'}}>
            <button
              className="btn btn-primary"
              type="submit"
              style={{float:'left'}} onClick={this.handleAmountUnit}
              disabled={this.state.requestBtn}
            >
              {this.state.requestBtn?'Please wait ...':'Submit'}
            </button>
            </div>
          </div>
        </div>
    )
  }

  searchBar(){
    return (
      <React.Fragment>
         <div className="row">
            <div className="col-md-6">
            {Object.keys(this.state.pagination).length > 0  ?  
              (<h4><strong>Showing {this.state.pagination.reports.from}</strong> of {this.state.pagination.reports.to} results</h4>)
              :
              (<h4><strong>Showing 0</strong> of 0 results</h4>)
            }	  
            </div>
            <div className="col-md-6">
              {/* <div className="search_bar_list">
               <form onSubmit={this.handleSearchSubmit}>
                <input type="text" className="form-control" ref={this.myRef} placeholder="Ex. Contract Name, Contract Id..."/>
                <input type="submit" value="Search"/>
              </form> 
              </div> */}
            </div>
          </div>
      </React.Fragment>
    )
  }

  render(){

    let paginate = (total_page) => {
      var res = [];
      for(let i=1;i<=total_page;i++){
      res.push(<li className={this.state.pagination.reports.current_page===i?"page-item active":"page-item"} key={i}><a className="page-link" href="/#" onClick={(e)=>this.handlePagination(e,i)}>{i}</a></li>)
      }   
      return res;
    }
   
    let first_page=()=>{
      if(Object.keys(this.state.pagination).length > 0){
        let a = this.state.pagination.reports.prev_page_url.split('?')
        let b = a.length > 0 ? a[1].split('='):''
        return b[1];
      }else{
        return null;
      } 
    }
    
    let next_page=()=>{
      if(Object.keys(this.state.pagination).length > 0 && this.state.pagination.reports.next_page_url !==null){
        let a = this.state.pagination.reports.next_page_url.split('?')
        let b = a.length > 0 ? a[1].split('='):''
        return b[1];
      }else{
        return null;
      } 
    }

    return(
    <React.Fragment>   
    {this.state.dataCount !==0 ?  
    (<main>
      <div id="results">
        <div className="container">
          {this.searchBar()}
        </div>
      </div>

      <div className={this.state.requestAuditModel.visible?"modal fade show":"modal fade"} id="myModal" style={this.state.requestAuditModel.style}>
        {this.RequestAudit()}
     </div> 
		<div className="container margin_60_35">
			<div className="row">
				<div className="col-lg-12">

				    {this.state.data.length > 0 ? this.state.data.map((ele,key)=> 
					{
					return <div className="strip_list wow fadeIn" key={key}>
						<figure className="res_img">
							<a href={()=>false}><img src={ele.logo.length > 0 ? `${UrlString.BASE_URL}${ele.logo}`:'/assets/images/defi-channel.jpg'} referrerPolicy="no-referrer" alt=""/></a>
						</figure>
						<div className="res_img_act">
						  <a href={()=>false}><img src={ele.logo.length > 0 ? `${UrlString.BASE_URL}${ele.logo}`:'/assets/images/defi-channel.jpg'} referrerPolicy="no-referrer" alt=""/></a>
						</div>
					<small>{ele.category}</small>
				  <h3><a href={ele.website_link}>{ele.name}</a></h3>
          <h6>Status :{(ele.is_accepted==="1")?<span style={{color:'#1E6DB2'}}>Accpeted</span>:<span style={{color:'#e97d68'}}>Pending</span>}</h6>
          <h6 style={{float:'right',marginTop:'-28px'}}>{ele.increment_tokens>0?ele.increment_tokens:0} tokens <img style={{width:'20px'}}src="/assets/img/Defi_Audit_coin.png" alt=""/></h6>
          	<ul className="res_list">
					  <li><a href={()=>false} className="btn_listing"><img src={ele.blockchain==='Tron'?`/assets/images/tron.png`:(ele.blockchain==='Ethereum'?`/assets/images/eth.png`: (ele.blockchain==='Binance'?`/assets/images/bin.png`:''))} alt=""/></a></li>
						{/* <li>Audit Token : {ele.increment_tokens>0?ele.increment_tokens:0}</li> */}
            {(ele.is_accepted==="1")? <li><a href={()=>false} onClick={(e)=>this.handleAddBtn(e,ele.id,ele.contract_key)}>Increase Priority</a></li> : (<li style={{visible:'none'}}></li>)}
            {(ele.increment_tokens===null)? <li><a href={()=>false} onClick={(e)=>this.handleAddBtn(e,ele.id,ele.contract_key,true,ele.contract_id)}>Make Priority Audit</a></li> : ('')}
             </ul>
					</div>
					}     
                    ):
						(
						<div className="strip_list wow fadeIn" >
							<h3>Loading ...</h3>
						</div> 
						) 
					}

                 {Object.keys(this.state.pagination).length > 0  ? 
                    this.state.pagination.reports.last_page !==1 ?
                    (
					<nav aria-label="" className="add_top_20">
						<ul className="pagination pagination-sm">
							 {/* first page */}  
							 {this.state.pagination.reports.prev_page_url !==null ?   
                          ( <li className="page-item"><a className="page-link" href={()=>false}  onClick={(e)=>this.handlePagination(e,first_page())}>Previous</a></li>):('')
                          } 
                            {/* dynamic page num */} 
                            {paginate(this.state.pagination.reports.last_page)}

                            {/* next page */}
                          {this.state.pagination.reports.next_page_url !==null ? 
                            (<li className="page-item"><a className="page-link" href="/#" onClick={(e)=>this.handlePagination(e,next_page())}>Next</a></li>) : ('')
                          }
						</ul>
					</nav>
					) : ('') : ('') }
              	</div>
			</div>
		</div>
    </main>):(
      <div id="error_page"><div class="container"><div class="row justify-content-center text-center"><div class="col-xl-9 col-lg-9"><h2>No Pending Audit<i class="icon_error-triangle_alt"></i></h2><p>We're sorry, but currently there are no pending audits.</p></div></div></div></div>
    )}
     {this.state.notify.visible?(<Notify attr={this.state.notify}/>):('')}
    </React.Fragment>
    )
  }
}

export default withRouter(PendingReport)